import { useState } from "react";
import { useMutation } from "react-query";
import { createOrderTable } from "../../../services/inventory";
import { useSelector } from "react-redux";
import { useGetUserList } from "../../../hooks/api/queries/useSettings";

export const useAddOrderTable = ({ closeModal, handleFetchOrderTables }) => {
  const [formData, setFormData] = useState({
    name: "",
    store: "",
    seat_capacity: "",
  });
  const orderTableMutation = useMutation(["addOrderTable"], createOrderTable);
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const [isUsers, setUsers] = useState([]);

  const handleAddOrderTable = () => {
    const { name, store, seat_capacity } = formData;
    orderTableMutation.mutate(
      { name, store: store, seat_capacity, users: isUsers },
      {
        onSuccess: () => {
          handleFetchOrderTables();
          closeModal();
        },
      },
    );
  };

  const {
    isLoading: isFetchingUsers,
    data: users,
    refetch: fetchUsersList,
  } = useGetUserList();

  return {
    formData,
    isAddingOrderTable: orderTableMutation.isLoading,
    storeList,
    setFormData,
    handleAddOrderTable,
    users,
    isFetchingUsers,
    fetchUsersList,
    isUsers,
    setUsers,
  };
};
