import { useContext, useEffect, useState } from "react";
import { checkoutWithCredit } from "../../../../services/sales-point";
import { ToastContext } from "../../../../hooks/context";
import { useSelector } from "react-redux";
import { useQueryClient } from "react-query";
import { cleanObject } from "../../../../helpers";
import { useGetCustomerDetails } from "../../../../hooks/api/queries";
import { useCloseOtpAuthorizationPrompt } from "../../../../hooks/useCloseOtpAuthorizationPrompt";

const CreditPaymentLogic = ({ closePaymentSession, orderDetails }) => {
  const { business_phone_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const [customerDetails, setCustomerDetails] = useState({
    customerPhoneCode: business_phone_code,
  });
  const [isAddCustomer, setIsAddCustomer] = useState(false);
  const [isCreditLimit, setIsCreditLimit] = useState(false);
  const [repaymentDate, setRepaymentDate] = useState("");
  const [customer, setCustomer] = useState(null);
  const { closeOtpAuthorizationPrompt } = useCloseOtpAuthorizationPrompt();
  const [isConfirmingPayment, setIsConfirmingPayment] = useState(false);
  const triggerToast = useContext(ToastContext);
  const queryClient = useQueryClient();
  const { data: fetchedCustomer, refetch } = useGetCustomerDetails(
    customerDetails?.customerId,
  );

  useEffect(() => {
    if (orderDetails?.customer)
      setCustomerDetails({
        customerId: orderDetails?.customer?.id,
        customerPhoneCode: orderDetails?.customer?.phone_code,
        customerName: orderDetails?.customer?.full_name,
        customerPhoneNumber: orderDetails?.customer?.phone,
        customerEmail: orderDetails?.customer?.email,
      });
  }, [orderDetails]);

  useEffect(() => {
    if (customerDetails?.customerId) {
      refetch();
    }
  }, [customerDetails?.customerId]);

  useEffect(() => {
    if (fetchedCustomer) {
      setCustomer(fetchedCustomer);
    }
  }, [fetchedCustomer]);

  const payViaCredit = () => {
    if (!customerDetails.customerPhoneNumber)
      return triggerToast("Customer phone number is required");

    setIsConfirmingPayment(true);
    checkoutWithCredit(
      cleanObject({
        order_id: orderDetails?.id,
        customer_name: customerDetails?.customerName,
        customer_phone_number: customerDetails?.customerPhoneNumber,
        customer_phone_code:
          customerDetails?.customerPhoneCode || business_phone_code,
        customer_email: customerDetails?.customerEmail,
        repayment_date: repaymentDate,
      }),
    )
      .then(() => {
        triggerToast("Payment successful", "success");
        queryClient.invalidateQueries(["orderDetails", orderDetails?.id]);
        closePaymentSession();
        closeOtpAuthorizationPrompt();
      })
      .catch(() => {})
      .finally(() => setIsConfirmingPayment(false));
  };

  return {
    isConfirmingPayment,
    customer,
    repaymentDate,
    customerDetails,
    isAddCustomer,
    isCreditLimit,
    setIsCreditLimit,
    setCustomerDetails,
    setIsAddCustomer,
    setRepaymentDate,
    payViaCredit,
  };
};

export default CreditPaymentLogic;
