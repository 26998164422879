import Icon from "../../../assets/icons";
import globalStyles from "../../../assets/styles/base/globalPage.module.scss";
import {
  DocumentationIcon,
  PhoneIcon,
  WhatAppIcon,
} from "../../../assets/svgIcons";
import styles from "./SupportModal.module.scss";

const SupportOption = ({ closeModal }) => {
  return (
    <div>
      <div className="d-flex justify-content-between ml-2 mr-2 align-items-center">
        <h5>Support</h5>
        <h4 onClick={closeModal} style={{ cursor: "pointer" }}>
          x
        </h4>
      </div>
      <hr />
      <div className={`${globalStyles.pageColumnGaps} p-2`}>
        <div
          className={`${styles.hoverEffect} align-items-center`}
          style={{
            cursor: "pointer",
            textDecoration: "none",
            textShadow: "none",
          }}
          href="mailto:Customerservice@enlumidata.com"
        >
          <img src={Icon.email} alt="" height={30} width={30} />
          <strong className="small-text-size m-1">
            Email Us (customerservice@enlumidata.com)
          </strong>
        </div>
        <div
          className={`${styles.hoverEffect} align-items-center`}
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation();
            window.open("tel: 02013309157");
          }}
        >
          <PhoneIcon width="30" height="30" />
          <strong className="small-text-size m-1">Call Us (02013309157)</strong>
        </div>
        <div
          className={`${styles.hoverEffect} align-items-center`}
          onClick={(e) => {
            e.stopPropagation();
            window.open("https://wa.me/2349167667098");
          }}
          style={{ cursor: "pointer" }}
        >
          <WhatAppIcon />
          <strong className="small-text-size m-1">
            Whatsapp (+2349167667098)
          </strong>
        </div>
        <div
          className={`${styles.hoverEffect} align-items-center`}
          onClick={(e) => {
            e.stopPropagation();
            window.open(
              "https://enlumidatainc.zohodesk.com/portal/en/kb/enlumi-data-inc/lumi-business",
            );
          }}
          style={{ cursor: "pointer" }}
        >
          <DocumentationIcon />
          <strong className="small-text-size m-1">Knowledge Base</strong>
        </div>
      </div>
    </div>
  );
};

export default SupportOption;
