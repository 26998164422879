import httpClient from "../axiosBase/axiosHandler";

export const getCartItems = (storeId) => {
  return httpClient.get(`/order/cart-items/?store_id=${storeId}`);
};

export const addToCart = (data) => {
  return httpClient.post("/order/add-to-cart/", data);
};

export const addToCartBulk = (data) => {
  return httpClient.post("/order/add-bulk-items-to-cart/", data);
};

export const removeFromCart = (id, storeId) => {
  return httpClient.delete(
    `/order/${id}/remove-cart-item/?store_id=${storeId}`,
  );
};

export const createOrder = (data) => {
  return httpClient.post(`/order/?store_id=${data.store_id}`, data);
};

export const validateGiftCard = (data) => {
  return httpClient.post("/giftcard/validate-giftcard/", data);
};

export const redeemGiftCard = (data) => {
  return httpClient.post("/giftcard/redeem/", data);
};

export const getCustomerWallet = (customerId) => {
  return httpClient.get(`/loyalty/customer-wallet/${customerId}/`);
};

export const createBillOrder = (data) => {
  return httpClient.post("/order/create-bill-order/", data, {
    params: { store_id: data?.store_id },
  });
};

export const checkoutWithCash = (data) => {
  return httpClient.post("/order/pay-with-cash/", data);
};

export const checkoutwithCard = (data) => {
  console.log(data);
  return httpClient.post("/order/pay-with-card/", data);
};

export const checkoutByTransfer = (data) => {
  return httpClient.post("/order/pay-with-transfer/", data);
};

export const checkoutWithCredit = (data) => {
  return httpClient.post("/order/pay-with-credit/", data);
};

export const checkoutWithSplit = (data) => {
  return httpClient.post("/order/pay-with-split/", data);
};

export const checkoutWaybill = (data) => {
  return httpClient.post("/order/pay-with-waybill/", data);
};

export const generateWaybill = (order_id, data) => {
  return httpClient.post(`/waybills/${order_id}/generate-waybill/`, data);
};

export const validateWaybillItems = (order_id, data) => {
  return httpClient.post(`/waybills/${order_id}/validate-waybill-items/`, data);
};

export const getGeneratedWaybills = (order_id) => {
  return httpClient.get(`/waybills/${order_id}/view-waybills/`);
};

export const getTransactionViaTransfer = () => {
  return httpClient.get(
    "/transactions/?all_business=true&status=settled&payment_method=lumi_naira_wallet|lumi_rewards_wallet|bank_transfer|in-app_transfer|offline_transfer&per_page=20",
  );
};

export const getTransactionViaCard = () => {
  return httpClient.get(
    "/transactions/pulled-pos-transaction-list/?per_page=20",
  );
};

export const postStoreCustomerDetails = (data) => {
  return httpClient.post("/order/confirm_customer/", data);
};

export const printOrderReceipt = (id, isUpdatedBill = false) => {
  const urlPath = isUpdatedBill
    ? `/order/${id}/generate-bill-receipt/`
    : `/order/${id}/generate-receipt/`;
  return httpClient.post(urlPath);
};

export const applyDiscount = (data) => {
  return httpClient.post("/order/apply-discount/", data);
};

export const sendOrderReceiptToPrint = (data) => {
  return httpClient.post("/order/send_to_terminal/", data);
};

export const downloadSalesEndOfDay = (data) => {
  return httpClient.post("/end-of-day/export/", data);
};

export const endOfDayDetails = ({ id }) => {
  return httpClient.get(`/end-of-day/${id}/`);
};

export const salesEndOfDayView = ({ ...params }) => {
  return httpClient.get("/end-of-day/view/", { params: { ...params } });
};

export const warehouseCheckout = (data) => {
  return httpClient.post("/order/pay-with-warehouse-checkout/", data);
};

export const removeBillProduct = ({ id, auth_pin = "" }) => {
  return httpClient.delete(
    `/order/${id}/remove-bill-item/?auth_pin=${auth_pin}`,
  );
};

export const updateBillProduct = ({ id, ...data }) => {
  return httpClient.post(`/order/${id}/bulk-update-or-add-to-bill/`, {
    ...data,
  });
};

export const addBillProduct = ({ id, data }) => {
  return httpClient.post(`/order/${id}/add-to-bill-item/`, data);
};

export const getPendingSessions = (queryParams) => {
  return httpClient.get("/checkout-sessions/", { params: { ...queryParams } });
};

export const createPendCheckoutSession = (data) => {
  return httpClient.post("/checkout-sessions/create_pending_checkout/", data);
};

export const createAwaitingPaymentSession = (data) => {
  return httpClient.post("/checkout-sessions/create_awaiting_payment/", data);
};

export const continuePendingSession = ({ id }) => {
  return httpClient.patch(`/checkout-sessions/${id}/continue/`, {});
};

export const cancelPendingSession = ({ id }) => {
  return httpClient.delete(`/checkout-sessions/${id}/cancel/`, {});
};

export const sendReceipt = (payload) => {
  const response = httpClient.post("/order/send_receipt/", payload);
  return response;
};

export const updateOrderDate = ({ id, data }) => {
  const response = httpClient.post(`/order/${id}/update-order-date/`, data);
  return response;
};

export const updateOrderCustomer = ({ data }) => {
  const response = httpClient.post("/order/update_order_customer/", data);
  return response;
};

export const updateOrderStatus = ({ id, data }) => {
  const response = httpClient.patch(`/order/${id}/update-order-status/`, data);
  return response;
};

export const assignRiderToOrder = (data) => {
  const response = httpClient.post("/order/assign_rider/", data);
  return response;
};
