/* eslint-disable jsx-a11y/anchor-is-valid */
import CreditPaymentLogic from "./useCreditPaymentLogic";
import { useEffect } from "react";
import primaryComponents from "../../../primaryComponents";
import { formatCurrency } from "../../../../helpers";
import AddCustomerSection from "../AddCustomerSection";
import Modal from "../../Modal/Modal";
import UpdateCreditLogModal from "../../UpdateCreditLogModal";
import { useStoreCurrency } from "../../../../hooks/useStoreCurrency";

const CreditPayment = ({
  closePaymentSession,
  openTransactionsession,
  orderDetails,
}) => {
  const { store_currency } = useStoreCurrency();

  const {
    isConfirmingPayment,
    repaymentDate,
    customerDetails,
    isCreditLimit,
    customer,
    setIsCreditLimit,
    setCustomerDetails,
    setRepaymentDate,
    payViaCredit,
  } = CreditPaymentLogic({
    closePaymentSession,
    openTransactionsession,
    orderDetails,
  });

  const handleConfirmClick = () => {
    const totalAmount = Number(orderDetails?.total_amount || 0);
    const storeCreditLimit = Number(orderDetails?.store?.credit_limit || 0);
    const customerCreditLimit = Number(customer?.credit_limit || 0);
    const outstandingBalance = Number(customer?.outstanding_balance || 0);

    const effectiveCreditLimit = customerCreditLimit || storeCreditLimit;

    if (outstandingBalance === 0) {
      if (totalAmount > effectiveCreditLimit) {
        setIsCreditLimit(true);
        return;
      }
    } else {
      const remainingCreditLimit = effectiveCreditLimit - outstandingBalance;

      if (totalAmount > remainingCreditLimit) {
        setIsCreditLimit(true);
        return;
      }
    }

    payViaCredit();
  };

  useEffect(() => {
    console.log(
      "Modal Visibility:",
      Number(orderDetails?.total_amount) > Number(customer?.credit_limit),
    );
  }, [isCreditLimit, orderDetails, customer]);

  return (
    <>
      <div className="cash-payment mt-5 mb-4">
        <primaryComponents.InputField
          label="Please confirm credit amount"
          isDisabled={true}
          value={formatCurrency({
            value: orderDetails.total_amount,
            currencyCode: store_currency,
          })}
          classNames="dark--text font-weight-bold"
        />

        <primaryComponents.DateInput
          type="date"
          label="Repayment date"
          isRequired={false}
          name="repayment_date"
          value={repaymentDate}
          onChange={({ target: { value } }) => {
            setRepaymentDate(value);
          }}
        />

        <AddCustomerSection
          setCustomerDetails={setCustomerDetails}
          customerDetails={customerDetails}
          customer={customer}
        />
      </div>

      <div className="card-payment__bottom">
        <primaryComponents.Button
          classNames="btn btn--primary cover"
          isLoading={isConfirmingPayment}
          loadingText="Confirming..."
          isDisabled={isConfirmingPayment}
          onClick={handleConfirmClick}
        >
          Confirm
        </primaryComponents.Button>
      </div>

      <Modal
        isActive={isCreditLimit}
        closeModal={() => {
          setIsCreditLimit(false);
        }}
        width={500}
      >
        <UpdateCreditLogModal
          customer={customer}
          closeModal={() => {
            setIsCreditLimit(false);
          }}
          type="store"
        />
      </Modal>
    </>
  );
};
export default CreditPayment;
