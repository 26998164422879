import "./Overview.scss";
import Icon from "../../../../assets/icons";
import primaryComponents from "../../../../components/primaryComponents";
import secondaryComponents from "../../../../components/secondaryComponents";
import { formatCurrency } from "../../../../helpers";
import { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link, useNavigate } from "react-router-dom";
import Images from "../../../../assets/images";
import { motion } from "framer-motion";
import { pathConstants } from "../../../../routes/pathContants";
import useOverview from "./useOverview";
import { useSelector } from "react-redux";

const Overview = () => {
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  const {
    total_revenue,
    total_transaction_count,
    isRefreshingBalance,
    //total_revenue_trend,
    //total_transaction_count_trend,
    five_most_recent_transactions,
    revenue_summary,
    business,
    has_wallet,
    available_balance,
    bvnConsentUrl,
    setBvnConsentUrl,
    refreshBalance,
  } = useOverview();

  const [copiedAccount, setCopiedAccount] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (copiedAccount === false) return;
    setTimeout(() => {
      setCopiedAccount(false);
    }, 5000);
  }, [copiedAccount]);

  return (
    <>
      <secondaryComponents.RequestLoaderTrigger>
        <secondaryComponents.MetaSetter
          title="Lumi Merchant | Dashboard - Overview"
          description="View summary of your transactions"
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.3 } }}
            className="overview"
          >
            <div className="overview__cards">
              <div className="overview__cards__card available__balance">
                <div className="overview__cards__card__details">
                  <span className="overview__cards__card__title">
                    Available Balance
                  </span>
                  <h5 className="font-weight-semibold mt-1">
                    {formatCurrency({
                      value: available_balance,
                      currencyCode: currency_code,
                    })}
                  </h5>

                  <primaryComponents.Button
                    classNames={"btn btn--outline withdraw__btn"}
                    onClick={() => refreshBalance()}
                    isDisabled={isRefreshingBalance}
                    isLoading={isRefreshingBalance}
                    loadingText="Refreshing..."
                  >
                    Refresh Balance
                  </primaryComponents.Button>
                </div>
              </div>
              <div
                style={{ backgroundColor: "#F2EDFE" }}
                className="overview__cards__card"
              >
                {has_wallet ? (
                  <div className="overview__cards__card__details">
                    <span className="overview__cards__card__title">
                      {business?.bank_name}
                    </span>
                    <br />
                    <span className="overview__cards__card__title dark--text">
                      {business?.account_name &&
                        business?.account_name?.toUpperCase()}
                    </span>
                    <br />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "17.5px",
                        marginTop: "4px",
                      }}
                    >
                      <span
                        style={{ fontSize: "20px" }}
                        className="overview__cards__card__title font-weight-semibold dark--text"
                      >
                        {business?.account_number}
                      </span>
                    </div>
                    <CopyToClipboard
                      onCopy={() => setCopiedAccount(true)}
                      text={business?.account_number}
                    >
                      <primaryComponents.Button
                        classNames={"btn btn--outline btn__copy mt-3"}
                      >
                        <img
                          width="16px"
                          height="16px"
                          className="mr-2"
                          src={Icon.copy}
                          alt=""
                        />
                        <span>
                          {copiedAccount ? "Copied" : "Copy Account Number"}
                        </span>
                      </primaryComponents.Button>
                    </CopyToClipboard>
                  </div>
                ) : (
                  <div className="overview__cards__card__details">
                    <p className="dark--text font-weight-semibold mb-2">
                      Create wallet
                    </p>
                    <span style={{ fontSize: "14px" }}>
                      Kindly verify your business and a wallet will be
                      automatically generated
                    </span>
                    <primaryComponents.Button
                      onClick={() => navigate(pathConstants.KYB_VERIFICATION)}
                      classNames={"btn btn--outline btn__verify mt-3"}
                    >
                      Verify business
                    </primaryComponents.Button>
                  </div>
                )}
              </div>
              <div className="overview__cards__card">
                <div className="overview__cards__card__details">
                  <span className="overview__cards__card__title">
                    Total Transactions
                  </span>
                  <h4 className="font-weight-semibold mt-1">
                    {total_transaction_count}
                  </h4>
                  {/* <span className="mt-3 d-block overview__cards__card__subtitle">
                    {total_transaction_count_trend} from yesterday
                  </span> */}
                </div>
              </div>
              <div className="overview__cards__card">
                <div className="overview__cards__card__details">
                  <span className="overview__cards__card__title">
                    Total Revenue
                  </span>
                  <h5 className="font-weight-semibold mt-1">
                    {formatCurrency({
                      value: total_revenue,
                      currencyCode: currency_code,
                    })}
                  </h5>
                  {/* <span className="mt-3 d-block overview__cards__card__subtitle">
                    {total_revenue_trend} from yesterday
                  </span> */}
                </div>
              </div>
            </div>
            <div className="overview__summary">
              <div className="overview__summary__card">
                <div className="overview__summary__card__header">
                  <h4 className="dark--text font-weight-semibold overview__summary__card-title">
                    Revenue Summary
                  </h4>
                </div>
                <div className="overview__summary__card__body">
                  <secondaryComponents.RevenueChart
                    revenue_summary={revenue_summary}
                  />
                </div>
              </div>
              <div className="overview__summary__card">
                <div className="overview__summary__card__header">
                  <h4 className="dark--text font-weight-semibold overview__summary__card-title">
                    Recent Transactions
                  </h4>
                  <Link
                    to={pathConstants.PAYMENT_TRANSACTIONS_ALL}
                    className="purple--text overview__summary__card-link"
                  >
                    View more
                  </Link>
                </div>
                <div className="recent__transactions__list">
                  {five_most_recent_transactions.length > 0 ? (
                    <secondaryComponents.RecentTransactionTable
                      mostRecentTransaction={five_most_recent_transactions}
                    />
                  ) : (
                    <div className="mt-5 text-center">
                      <img
                        width={"150px"}
                        src={Images.transaction_empty_state}
                        alt="No transactions"
                      />
                      <p className="font-weight-semibold mt-3 dark--text">
                        No recent transactions
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </motion.div>
        </secondaryComponents.MetaSetter>
      </secondaryComponents.RequestLoaderTrigger>

      <secondaryComponents.Modal
        isActive={bvnConsentUrl !== null && bvnConsentUrl !== ""}
        width={700}
        closeModal={() => {
          setBvnConsentUrl(null);
        }}
      >
        <secondaryComponents.BvnConsent
          closeModal={() => {
            setBvnConsentUrl(null);
          }}
          url={bvnConsentUrl}
        />
      </secondaryComponents.Modal>
    </>
  );
};

export default Overview;
