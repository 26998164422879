import { createSlice } from "@reduxjs/toolkit";

// Define the initial state using that type
const initialState = {
  toastError: {
    message: null,
  },
  subscriptionAuthorizationError: {
    message: null,
  },
  otpAuthorizationError: {
    message: null,
    action: null,
    object_id: null,
    object_type: null,
    otp_code: null,
  },
  verifiedOTPCode: null,
};

export const apiErrorSlice = createSlice({
  name: "apiError",
  initialState,
  reducers: {
    setApiErrorMessage: (state, action) => {
      state.toastError.message = action?.payload?.message;
    },
    setSubscriptionAuthorizationError: (state, action) => {
      state.subscriptionAuthorizationError.message = action?.payload?.message;
    },
    setOtpAuthorizationError: (state, action) => {
      state.otpAuthorizationError = action?.payload;
    },
    setAuthorizationErrorOtpCode: (state, action) => {
      state.otpAuthorizationError.otp_code = action?.payload;
    },
    setVerifiedOTPCode: (state, action) => {
      state.verifiedOTPCode = action?.payload;
    },
  },
});

export const {
  setApiErrorMessage,
  setSubscriptionAuthorizationError,
  setOtpAuthorizationError,
  setAuthorizationErrorOtpCode,
  setVerifiedOTPCode,
} = apiErrorSlice.actions;
