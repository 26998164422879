export const WhatAppIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1167_598)">
        <path
          d="M-1.33691 33.1078L1.08873 24.2515C-0.409803 21.6535 -1.19693 18.7063 -1.19331 15.707C-1.18936 6.2833 6.47956 -1.3833 15.9035 -1.3833C20.4767 -1.38098 24.7691 0.398696 27.9974 3.62925C31.2256 6.8598 33.0018 11.1539 33.0001 15.7207C32.996 25.1438 25.3259 32.8118 15.9033 32.8118H15.8959C13.0348 32.8106 10.2234 32.0929 7.72619 30.7312L-1.33691 33.1078Z"
          fill="white"
        />
        <path
          d="M15.9089 1.50395C8.07059 1.50395 1.69616 7.87605 1.69337 15.7083C1.68951 18.3828 2.44266 21.0038 3.86574 23.2683L4.20383 23.8057L2.76806 29.0474L8.14634 27.6372L8.66567 27.9448C10.8469 29.2393 13.3478 29.9239 15.8982 29.9253H15.9036C23.7358 29.9253 30.1103 23.5525 30.1133 15.7197C30.1192 13.8528 29.7547 12.0033 29.041 10.2782C28.3272 8.5531 27.2784 6.98673 25.9551 5.66976C24.6393 4.34558 23.0738 3.29557 21.3493 2.58051C19.6249 1.86544 17.7757 1.49953 15.9089 1.50395Z"
          fill="url(#paint0_linear_1167_598)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.6312 8.56243C11.311 7.85117 10.9741 7.83676 10.67 7.82444L9.8511 7.81445C9.56622 7.81445 9.10336 7.92134 8.71206 8.34889C8.32076 8.77644 7.2168 9.80976 7.2168 11.9115C7.2168 14.0132 8.74761 16.0441 8.96092 16.3294C9.17423 16.6148 11.9161 21.0652 16.2583 22.7775C19.8667 24.2005 20.6009 23.9175 21.3845 23.8464C22.168 23.7753 23.9119 22.8131 24.2676 21.8155C24.6234 20.818 24.6236 19.9634 24.517 19.7847C24.4103 19.606 24.1254 19.4998 23.6979 19.286C23.2703 19.0722 21.1705 18.0389 20.7789 17.8962C20.3874 17.7536 20.1027 17.6827 19.8176 18.1103C19.5325 18.5378 18.7148 19.4996 18.4655 19.7847C18.2162 20.0698 17.9673 20.1056 17.5398 19.892C17.1122 19.6785 15.7366 19.2272 14.1045 17.7719C12.8347 16.6396 11.9775 15.2413 11.7279 14.8139C11.4783 14.3866 11.7014 14.1552 11.9157 13.9423C12.1074 13.7509 12.3427 13.4435 12.5567 13.1941C12.7707 12.9448 12.8412 12.7666 12.9834 12.4819C13.1256 12.1973 13.0547 11.9473 12.9478 11.7337C12.8409 11.5202 12.0109 9.40731 11.6312 8.56243Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1167_598"
          x1="15.614"
          y1="3.20949"
          x2="15.7581"
          y2="27.4973"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#57D163" />
          <stop offset="1" stopColor="#23B33A" />
        </linearGradient>
        <clipPath id="clip0_1167_598">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
