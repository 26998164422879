import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object({
  authUser: yup.string().required("Auth User is required"),
});

const Validation = ({ authUsers }) => {
  return useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      authUser: authUsers?.full_name || "",
    },
  });
};

export default Validation;
