import "./assets/styles/index.scss";
import { Outlet } from "react-router-dom";
import "./Fontawesome";
import secondaryComponents from "./components/secondaryComponents";
import commonComponent from "./components/common";
import Meta from "./Meta";
import { useEffect } from "react";
// layout imports
import layouts from "./layouts/index";
import { QueryClient, QueryClientProvider } from "react-query";
import Routes from "./routes/Routes";
const queryClient = new QueryClient();

const App = () => {
  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (process.env.REACT_APP_ENV === "production")
      window.console.log = () => {};
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <layouts.MainLayout>
          <secondaryComponents.ApiErrorHandler>
            <secondaryComponents.SubscribeUpgradeNotification>
              <secondaryComponents.ToastMessage>
                <commonComponent.ManagerOtpPermission>
                  <Meta />
                  <Outlet />
                </commonComponent.ManagerOtpPermission>
              </secondaryComponents.ToastMessage>
            </secondaryComponents.SubscribeUpgradeNotification>
          </secondaryComponents.ApiErrorHandler>
        </layouts.MainLayout>
      </Routes>
    </QueryClientProvider>
  );
};
export default App;
