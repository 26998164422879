import { useEffect, useMemo, useState } from "react";
import { useTable } from "react-table";
import {
  formatCurrency,
  formatNumberWithCommas,
  truncateString,
} from "../../../helpers";
import primaryComponents from "../../primaryComponents";
import styles from "./ReconcileStockEditView.module.scss";

const useReconcileStockEditView = ({
  store_currency,
  products,
  setReconciliedData,
  discrepancyReasons,
  isReviewMode,
  reconciliedData,
}) => {
  const [productsList, setProductsList] = useState(products);

  useEffect(() => {
    const newProductList = [...productsList];
    for (let index = newProductList?.length; index < products.length; index++) {
      const data = reconciliedData?.[products[index]?.item_id]
        ? reconciliedData?.[products[index]?.item_id]
        : products[index];
      newProductList.push(data);
    }
    setProductsList(newProductList);
  }, [products?.length]);

  const updateChanges = (position, newData) => {
    setReconciliedData((prevState) => ({
      ...prevState,
      [position]: newData,
    }));
  };

  const removeReconciledKey = (position) => {
    setReconciliedData((prevState) => {
      const copy = { ...prevState };
      delete copy[position];
      return copy;
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Product Name",
        accessor: "product_name",
        filterable: true,
        Cell: ({ cell: { value } }) =>
          truncateString(value, 500) ? value : "N/A",
      },
      {
        Header: "Variant",
        accessor: "variant_name",
        Cell: ({ cell: { value } }) =>
          truncateString(value, 200) ? value : "N/A",
      },
      {
        Header: "System count",
        accessor: "system_count",
        Cell: ({ row, cell: { value } }) => (
          <>
            {formatNumberWithCommas(value)} {row?.original?.product_unit_label}
          </>
        ),
      },
      {
        Header: "Physical Count",
        accessor: "physical_count",
        Cell: ({ row, cell: { value } }) => (
          <span className="d-flex align-items-center">
            <primaryComponents.InputField
              classNames={`white ${styles.inputField}`}
              placeholder="Enter value"
              floatError={false}
              type="number"
              min="0"
              value={value !== null && value !== undefined ? value : ""}
              name={`physicalCount${row?.index}`}
              isDisabled={isReviewMode}
              onChange={(e) => {
                const newProductList = [...instance?.data];
                newProductList[row?.index].physical_count =
                  e?.target?.value === "" || Math.sign(e?.target?.value) === -1
                    ? null
                    : e?.target?.value;
                setProductsList(newProductList);
                e?.target?.value !== ""
                  ? updateChanges(
                      newProductList[row?.index].item_id,
                      newProductList?.[row?.index],
                    )
                  : removeReconciledKey(newProductList[row?.index].item_id);
              }}
            />
            {row?.original?.product_unit_label ? (
              <span className="ml-2">{row?.original?.product_unit_label}</span>
            ) : null}
          </span>
        ),
      },
      {
        Header: "Discrepancies",
        accessor: "discrepancy_count",
        Cell: ({ row }) => (
          <span>
            {row?.original?.system_count === row?.original?.physical_count ||
            row?.original?.physical_count === null ? (
              <>
                <span className="success--squared">0</span>{" "}
                {row?.original?.product_unit_label || ""}
              </>
            ) : (
              <>
                <span
                  className={`${
                    row?.original?.physical_count -
                      row?.original?.system_count <
                    0
                      ? "failed--text"
                      : "success--squared"
                  }`}
                >
                  {formatNumberWithCommas(
                    row?.original?.physical_count - row?.original?.system_count,
                  )}
                </span>{" "}
                {row?.original?.product_unit_label || ""}
              </>
            )}
          </span>
        ),
      },
      {
        Header: "Estimated Impact",
        accessor: "discrepancy_impact",
        Cell: ({ row }) =>
          row?.original?.system_count === row?.original?.physical_count ||
          row?.original?.physical_count === null ||
          row?.original?.physical_count === "" ? (
            <span className="success--squared">
              {formatCurrency({ value: 0, currencyCode: store_currency })}
            </span>
          ) : (
            <span
              className={`${
                row?.original?.physical_count - row?.original?.system_count < 0
                  ? "failed--text"
                  : "success--squared"
              }`}
            >
              {formatCurrency({
                value:
                  (row?.original?.physical_count -
                    row?.original?.system_count) *
                  row?.original?.cost_price,
                currencyCode: store_currency,
              })}
            </span>
          ),
      },
      {
        Header: "Discrepancy Reason",
        accessor: "discrepancy_reason_label",
        Cell: ({ row, cell: { value } }) => (
          <primaryComponents.SelectField
            classNames={`white ${styles.inputField}`}
            floatError={false}
            options={discrepancyReasons}
            isSearchable={false}
            value={
              reconciliedData?.[instance?.data?.[row?.index]?.item_id]
                ?.discrepancy_reason_label ||
              value ||
              "Select"
            }
            nameKey="title"
            idKey="id"
            isDisabled={
              row?.original?.physical_count === null ||
              row?.original?.physical_count === "" ||
              isReviewMode
            }
            onChange={(value) => {
              const newProductList = [...instance?.data];
              newProductList[row?.index].discrepancy_reason_id = value;
              setProductsList(newProductList);
              updateChanges(
                newProductList[row?.index].item_id,
                newProductList?.[row?.index],
              );
            }}
          />
        ),
      },
      {
        Header: "Last Upadted By",
        accessor: "last_updated_by_label",
        Cell: ({ cell: { value } }) =>
          value ? truncateString(value, 50) : "N/A",
      },
    ],
    [store_currency],
  );

  const instance = useTable({ columns, data: productsList });

  return {
    instance,
  };
};
export default useReconcileStockEditView;
