import { useContext, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { cleanObject, getRandomIntInclusive } from "../../../../helpers";
import { RequestLoader, ToastContext } from "../../../../hooks/context";
import {
  removeBillProduct,
  updateBillProduct,
} from "../../../../services/sales-point";
import useOrderflows from "../Orderflows/useOrderflows";
import { DEFAULT_PRICING_ID } from "../../../../helpers/constants";
import { useCloseOtpAuthorizationPrompt } from "../../../../hooks/useCloseOtpAuthorizationPrompt";

const arrangeProductItem = (orderItems) => {
  const productItems = [];
  orderItems?.forEach((item) => {
    productItems.push({
      id: `${item?.product?.id || item?.service?.id}${item?.variant?.id || ""}`,
      cart_item_id: item?.id,
      is_from_cart: true,
      cart_item_name: item?.cart_item_name,
      quantity: item?.quantity,
      tags: item?.tags,
      unit: item?.product?.unit,
      total_price: item?.total_price,
      unit_price: item?.unit_price,
      discount_amount: item?.discount_amount,
    });
  });

  return productItems;
};

const useBillUpdate = ({ orderId, setBillWasUpdated, resetView }) => {
  const queryClient = useQueryClient();
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const { closeOtpAuthorizationPrompt } = useCloseOtpAuthorizationPrompt();
  const { orderDetails, isLoading } = useOrderflows({
    orderId,
  });
  const [productItems, setProductItems] = useState([]);
  const [otp, setOtp] = useState("");
  const [isAction, setIsAction] = useState("");
  const [otherFields, setOtherFields] = useState({
    delivery_fee_amount: orderDetails?.delivery_fee_amount || "",
    discount_amount: orderDetails?.discount_amount || "",
  });
  const triggerToast = useContext(ToastContext);

  useEffect(() => {
    setProductItems(arrangeProductItem(orderDetails?.order_items));
    queryClient.invalidateQueries(["orderDetails"]);
  }, [orderDetails?.order_items]);

  const addProductItem = (item) => {
    if (
      productItems?.find(
        (productItem) => `${productItem?.id}` === `${item?.id}`,
      )
    )
      return triggerToast("This item has been added to your order list");

    const totalPrice = (
      item?.store_product_properties?.[0]?.pricings?.[0]?.price -
      (item?.store_product_properties?.[0]?.pricings?.[0]?.price *
        item?.store_product_properties?.[0]?.pricings?.[0]?.discount_rate) /
        100
    ).toFixed(2);
    const data = {
      ...item,
      id: `${item?.id}`,
      cart_item_name: item?.name,
      tags: [],
      unit: item?.unit,
      quantity: 1,
      total_price: totalPrice,
      unit_price: item?.store_product_properties?.[0]?.pricings?.[0]?.price,
      discount_amount: (
        (item?.store_product_properties?.[0]?.pricings?.[0]?.price *
          item?.store_product_properties?.[0]?.pricings?.[0]?.discount_rate) /
        100
      ).toFixed(2),
    };
    setProductItems((prev) => [data, ...prev]);
  };

  const { mutate: deleteBillItem, isLoading: isDeleting } = useMutation(
    (data) => removeBillProduct(data),
  );
  const { mutate: updateBill, isLoading: isUpdating } = useMutation((data) =>
    updateBillProduct(data),
  );

  const removeBillItem = ({ cartId, index } = {}) => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 40));
    setIsAction("REMOVE_ITEM");
    deleteBillItem(
      { id: cartId },
      {
        onSuccess: () => {
          removeItemNotWithId(index);
          closeOtpAuthorizationPrompt();
        },
        onSettled: () => setRequestLoaderProgress(100),
      },
    );
  };

  const removeItemNotWithId = (index) => {
    const newProductItems = [...productItems];
    newProductItems.splice(index, 1);
    setProductItems(newProductItems);
  };

  const updateBillItem = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 40));
    setIsAction("UPDATE_ITEM");
    const newProductItems = [];
    productItems?.forEach((productItem) => {
      const data = {
        quantity: productItem?.quantity,
      };
      if (productItem?.is_from_cart)
        data.cart_item_id = productItem?.cart_item_id;
      else {
        const key = productItem?.service_id ? "service_id" : "product_id";
        data[key] = productItem?.service_id
          ? productItem?.service_id
          : productItem?.product_id;
        data.tag_ids = productItem?.tags;
        data.store_id = orderDetails.store?.id;
        data.is_product = productItem?.service_id ? false : true;
        data.pricing_type_id = DEFAULT_PRICING_ID;
        data.variant_id = productItem?.variant_id;
      }
      newProductItems.push(cleanObject(data));
    });

    updateBill(
      cleanObject({
        id: orderDetails?.id,
        items: newProductItems,
        delivery_fee: otherFields?.delivery_fee_amount
          ? {
              amount: otherFields?.delivery_fee_amount,
              type: "flat",
            }
          : null,
        discount: otherFields?.discount_amount
          ? { amount: otherFields?.discount_amount, type: "flat" }
          : null,
      }),
      {
        onSuccess: () => {
          onSuccessHandler();
          closeOtpAuthorizationPrompt();
        },
        onSettled: () => setRequestLoaderProgress(100),
      },
    );
  };

  const onSuccessHandler = () => {
    queryClient.invalidateQueries(["orderDetails"]);
    queryClient.invalidateQueries(["sectionItems"]);
    queryClient.invalidateQueries(["getOrders"]);
    setBillWasUpdated(true);
    resetView("isTnxReceipt");
  };

  return {
    otp,
    setOtp,
    orderDetails,
    productItems,
    isUpdating,
    isLoading,
    // tagList,
    otherFields,
    setOtherFields,
    removeBillItem,
    updateBillItem,
    addProductItem,
    setProductItems,
    removeItemNotWithId,
    isAction,
    isDeleting,
  };
};
export default useBillUpdate;
