import { useState, useContext, useRef } from "react";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import {
  createStore,
  getStoreCategoryList,
  updateStoreDetails,
} from "../../../services/inventory";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { cleanObject, getRandomIntInclusive } from "../../../helpers";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { pathConstants } from "../../../routes/pathContants";
import { useGetAccountingBanksListWithSpreadFilter } from "../../../hooks/api/queries";
import { useGetTimezones } from "../../../hooks/api/queries/useBusiness";
import { getAllRoles } from "../../../services/settings";
import { useEffect } from "react";

const useStoreForm = ({ storeInfo, isEditMode, states, countries }) => {
  const {
    isLoading: isLoadingRoles,
    data: roles,
    refetch: isRefetchingRoles,
  } = useQuery(["roles"], () => getAllRoles().then((res) => res?.data?.data));
  const { business_phone_code, currency_code, currency_symbol } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  const reLabelDefault = (values, nameKey = "label", idKey = "id") => {
    let newArray = [];
    values?.forEach((value) => {
      newArray?.push({
        value: value?.[idKey],
        label: value?.[nameKey],
      });
    });
    return newArray;
  };

  const mapRoles = (roles) =>
    Array.isArray(roles)
      ? roles
          .filter((item) => item?.value !== undefined)
          .map((item) => item.value)
      : [];
  const [storeDetails, setStoreDetails] = useState({
    name: storeInfo?.name || "",
    address: storeInfo?.address || "",
    subaccount_label: storeInfo?.subaccount || "",
    store_category: storeInfo?.store_category?.id || "",
    store_category_label: storeInfo?.store_category?.name || "",
    is_warehouse: storeInfo?.is_warehouse || false,
    vat: storeInfo?.vat,
    allow_generate_bill: storeInfo?.allow_generate_bill || false,
    allow_table: storeInfo?.allow_table || false,
    allow_waybill: storeInfo?.allow_waybill || false,
    allow_prescription: storeInfo?.allow_prescription || false,
    allow_custom_price: storeInfo?.allow_custom_price || false,
    allow_section: storeInfo?.allow_section || false,
    allow_pr_payment: storeInfo?.allow_pr_payment || false,
    //allow_credit_update: storeInfo?.allow_credit_update || false,
    allow_order_price_mod_on_sell_mode:
      storeInfo?.allow_order_price_mod_on_sell_mode || false,
    phone_number: storeInfo?.phone_number?.substring(3) || "",
    phone_code: business_phone_code,
    credit_limit: storeInfo?.credit_limit || "",
    default_bank_account: storeInfo?.default_bank_account?.id || "",
    source: "user_entry",
    support_email: storeInfo?.support_email || "",
    currency_code: storeInfo?.currency_code || currency_code || "",
    currency_symbol: storeInfo?.currency_symbol || currency_symbol || "",
    timezone: storeInfo?.timezone?.id || "",
    country_ids: storeInfo?.countries?.map((country) => `${country?.id}`),
    state_ids: storeInfo?.states?.map((state) => state?.id) || [],
    order_reversal_window: storeInfo?.order_reversal_window,
    service_charge: storeInfo?.service_charge || "",
    city_ids: (() => {
      const cities = storeInfo?.cities?.map((city) => city?.id);
      const newCities = {};
      storeInfo?.states?.forEach((saved_state) => {
        newCities[saved_state?.id] =
          states
            ?.find((state) => `${state?.id}` === `${saved_state?.id}`)
            ?.cities?.filter((city) => cities?.includes(city?.id))
            ?.map((city) => city?.id) || [];
      });
      return newCities;
    })(),
    increase_bill_qty_requires_pin_from_roles:
      storeInfo?.increase_bill_qty_requires_pin_from_roles
        ? reLabelDefault(storeInfo?.increase_bill_qty_requires_pin_from_roles)
        : [],
    reduce_bill_qty_requires_pin_from_roles:
      storeInfo?.reduce_bill_qty_requires_pin_from_roles
        ? reLabelDefault(storeInfo?.reduce_bill_qty_requires_pin_from_roles)
        : [],
    delete_bill_item_requires_pin_from_roles:
      storeInfo?.delete_bill_item_requires_pin_from_roles
        ? reLabelDefault(storeInfo?.delete_bill_item_requires_pin_from_roles)
        : [],
    credit_sales_requires_pin_from_roles:
      storeInfo?.credit_sales_requires_pin_from_roles
        ? reLabelDefault(storeInfo?.credit_sales_requires_pin_from_roles)
        : [],
    pr_sales_requires_pin_from_roles:
      storeInfo?.pr_sales_requires_pin_from_roles
        ? reLabelDefault(
            storeInfo?.pr_sales_requires_pin_from_roles,
            "label",
            "id",
          )
        : [],
    delete_bill_order_requires_pin_from_roles:
      storeInfo?.delete_bill_order_requires_pin_from_roles
        ? reLabelDefault(storeInfo?.delete_bill_order_requires_pin_from_roles)
        : [],
    order_discount_requires_pin_from_roles:
      storeInfo?.order_discount_requires_pin_from_roles
        ? reLabelDefault(storeInfo?.order_discount_requires_pin_from_roles)
        : [],
    order_loyalty_requires_pin_from_roles:
      storeInfo?.order_loyalty_requires_pin_from_roles
        ? reLabelDefault(storeInfo?.order_loyalty_requires_pin_from_roles)
        : [],
    order_giftcard_requires_pin_from_roles:
      storeInfo?.order_giftcard_requires_pin_from_roles
        ? reLabelDefault(storeInfo?.order_giftcard_requires_pin_from_roles)
        : [],
    order_delivery_fee_requires_pin_from_roles:
      storeInfo?.order_delivery_fee_requires_pin_from_roles
        ? reLabelDefault(storeInfo?.order_delivery_fee_requires_pin_from_roles)
        : [],
    credit_repayment_requires_pin_from_roles:
      storeInfo?.credit_repayment_requires_pin_from_roles
        ? reLabelDefault(storeInfo?.credit_repayment_requires_pin_from_roles)
        : [],
    add_credit_debt_requires_pin_from_roles:
      storeInfo?.add_credit_debt_requires_pin_from_roles
        ? reLabelDefault(storeInfo?.add_credit_debt_requires_pin_from_roles)
        : [],
    reprint_docket_requires_pin_from_roles:
      storeInfo?.reprint_docket_requires_pin_from_roles
        ? reLabelDefault(storeInfo?.reprint_docket_requires_pin_from_roles)
        : [],
    order_custom_pricing_requires_pin_from_roles:
      storeInfo?.order_custom_pricing_requires_pin_from_roles
        ? reLabelDefault(
            storeInfo?.order_custom_pricing_requires_pin_from_roles,
          )
        : [],
  });
  const [isLocation, setIsLocation] = useState(false);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const [isOtherSetting, setIsOtherSetting] = useState(false);
  const triggerToast = useContext(ToastContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const stateInputRef = useRef();
  const [isCheckboxChecked, setIsCheckboxChecked] = useState({
    allow_public_relation: false,
    allow_credit_payment: false,
    allow_increment_qty: false,
    allow_reduce_qty: false,
    allow_delete_qty: false,
    allow_delete_bill_order: false,
    allow_discount: false,
    allow_loyalty: false,
    allow_giftcard: false,
    allow_delivery: false,
    allow_credit_repayment: false,
    allow_add_credit_debt: false,
    allow_print_docket: false,
  });
  const { data: category_list } = useQuery(
    ["storeCategoryList"],
    () =>
      getStoreCategoryList().then((res) => {
        return res?.data?.data;
      }),
    { staleTime: Infinity },
  );
  const { data: timezones, isLoading: isLoadingTimezones } = useGetTimezones();

  // create a new store
  const { mutate: addStore, isLoading: isAddingStore } = useMutation((data) =>
    createStore(data),
  );
  const handleAddStore = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));
    const newCityIds = Object.values({ ...storeDetails?.city_ids }).flat();
    const data = cleanObject(
      {
        ...storeDetails,
        phone_number:
          storeDetails?.phone_number !== ""
            ? `${storeDetails.phone_code}${storeDetails.phone_number}`
            : null,
        city_ids: newCityIds,
        increase_bill_qty_requires_pin_from_roles: mapRoles(
          storeDetails?.increase_bill_qty_requires_pin_from_roles,
        ),
        reduce_bill_qty_requires_pin_from_roles: mapRoles(
          storeDetails?.reduce_bill_qty_requires_pin_from_roles,
        ),
        delete_bill_item_requires_pin_from_roles: mapRoles(
          storeDetails?.delete_bill_item_requires_pin_from_roles,
        ),
        credit_sales_requires_pin_from_roles: mapRoles(
          storeDetails?.credit_sales_requires_pin_from_roles,
        ),
        pr_sales_requires_pin_from_roles: mapRoles(
          storeDetails?.pr_sales_requires_pin_from_roles,
        ),
        delete_bill_order_requires_pin_from_roles: mapRoles(
          storeDetails?.delete_bill_order_requires_pin_from_roles,
        ),
        order_discount_requires_pin_from_roles: mapRoles(
          storeDetails?.order_discount_requires_pin_from_roles,
        ),
        order_loyalty_requires_pin_from_roles: mapRoles(
          storeDetails?.order_loyalty_requires_pin_from_roles,
        ),
        order_giftcard_requires_pin_from_roles: mapRoles(
          storeDetails?.order_giftcard_requires_pin_from_roles,
        ),
        order_delivery_fee_requires_pin_from_roles: mapRoles(
          storeDetails?.order_delivery_fee_requires_pin_from_roles,
        ),
        credit_repayment_requires_pin_from_roles: mapRoles(
          storeDetails?.credit_repayment_requires_pin_from_roles,
        ),
        add_credit_debt_requires_pin_from_roles: mapRoles(
          storeDetails?.add_credit_debt_requires_pin_from_roles,
        ),
        reprint_docket_requires_pin_from_roles: mapRoles(
          storeDetails?.reprint_docket_requires_pin_from_roles,
        ),
        order_custom_pricing_requires_pin_from_roles: mapRoles(
          storeDetails?.order_custom_pricing_requires_pin_from_roles,
        ),
      },
      ["store_category_label", "subaccount_label"],
    );

    addStore(data, {
      onSuccess: () => onSuccess({ message: "Store creation successful" }),
      onSettled: () => setRequestLoaderProgress(100),
    });
  };

  // Edit store properties
  const { mutate: updateStore, isLoading: isEditingStore } = useMutation(
    (data) => updateStoreDetails(data),
  );

  const handleEditStore = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));

    const newCityIds = Object.values({ ...storeDetails?.city_ids }).flat();

    const data = cleanObject(
      {
        ...storeDetails,
        phone_number:
          storeDetails?.phone_number !== ""
            ? `${storeDetails?.phone_code}${storeDetails.phone_number}`
            : null,
        city_ids: newCityIds,
        credit_sales_requires_pin_from_roles: mapRoles(
          storeDetails?.credit_sales_requires_pin_from_roles,
        ),
        increase_bill_qty_requires_pin_from_roles: mapRoles(
          storeDetails?.increase_bill_qty_requires_pin_from_roles,
        ),
        reduce_bill_qty_requires_pin_from_roles: mapRoles(
          storeDetails?.reduce_bill_qty_requires_pin_from_roles,
        ),
        delete_bill_item_requires_pin_from_roles: mapRoles(
          storeDetails?.delete_bill_item_requires_pin_from_roles,
        ),
        pr_sales_requires_pin_from_roles: mapRoles(
          storeDetails?.pr_sales_requires_pin_from_roles,
        ),
        delete_bill_order_requires_pin_from_roles: mapRoles(
          storeDetails?.delete_bill_order_requires_pin_from_roles,
        ),
        order_discount_requires_pin_from_roles: mapRoles(
          storeDetails?.order_discount_requires_pin_from_roles,
        ),
        order_loyalty_requires_pin_from_roles: mapRoles(
          storeDetails?.order_loyalty_requires_pin_from_roles,
        ),
        order_giftcard_requires_pin_from_roles: mapRoles(
          storeDetails?.order_giftcard_requires_pin_from_roles,
        ),
        order_delivery_fee_requires_pin_from_roles: mapRoles(
          storeDetails?.order_delivery_fee_requires_pin_from_roles,
        ),
        credit_repayment_requires_pin_from_roles: mapRoles(
          storeDetails?.credit_repayment_requires_pin_from_roles,
        ),
        add_credit_debt_requires_pin_from_roles: mapRoles(
          storeDetails?.add_credit_debt_requires_pin_from_roles,
        ),
        reprint_docket_requires_pin_from_roles: mapRoles(
          storeDetails?.reprint_docket_requires_pin_from_roles,
        ),
        order_custom_pricing_requires_pin_from_roles: mapRoles(
          storeDetails?.order_custom_pricing_requires_pin_from_roles,
        ),
      },
      ["store_category_label", "subaccount_label"],
    );

    updateStore(
      { data, id: storeInfo?.id },
      {
        onSuccess: () => onSuccess({ message: "Store update successful" }),
        onSettled: () => setRequestLoaderProgress(100),
      },
    );
  };

  const submitStoreInfo = () => {
    if (isEditMode) handleEditStore();
    else handleAddStore();
  };

  const onSuccess = ({ message = "" }) => {
    triggerToast(message, "success");
    queryClient.invalidateQueries(["getStoreDetails"]);
    queryClient.invalidateQueries(["getStoreList"]);
    navigate(pathConstants.INVENTORY_STORES);
  };

  const { isLoading: isLoadingBankAccountsList, data: bankAccount } =
    useGetAccountingBanksListWithSpreadFilter({ account_type: "bank_account" });

  const [selectedHours, setSelectedHours] = useState({
    value: "Hours",
    label: "Hours",
  });

  const updateCheckboxState = (key, condition) => {
    if (condition) {
      setIsCheckboxChecked((prev) => ({
        ...prev,
        [key]: true,
      }));
    }
  };

  useEffect(() => {
    updateCheckboxState(
      "allow_public_relation",
      storeDetails?.pr_sales_requires_pin_from_roles?.length > 0,
    );
    updateCheckboxState(
      "allow_delete_bill_order",
      storeDetails?.delete_bill_order_requires_pin_from_roles?.length > 0,
    );
    updateCheckboxState(
      "allow_increment_qty",
      storeDetails?.increase_bill_qty_requires_pin_from_roles?.length > 0,
    );
    updateCheckboxState(
      "allow_reduce_qty",
      storeDetails?.reduce_bill_qty_requires_pin_from_roles?.length > 0,
    );
    updateCheckboxState(
      "allow_delete_qty",
      storeDetails?.delete_bill_item_requires_pin_from_roles?.length > 0,
    );
    updateCheckboxState(
      "allow_add_credit_debt",
      storeDetails?.add_credit_debt_requires_pin_from_roles?.length > 0,
    );
    updateCheckboxState(
      "allow_print_docket",
      storeDetails?.reprint_docket_requires_pin_from_roles?.length > 0,
    );
    updateCheckboxState(
      "allow_credit_repayment",
      storeDetails?.credit_repayment_requires_pin_from_roles?.length > 0,
    );
    updateCheckboxState(
      "allow_credit_payment",
      storeDetails?.credit_sales_requires_pin_from_roles?.length > 0,
    );
    updateCheckboxState(
      "allow_delivery",
      storeDetails?.order_delivery_fee_requires_pin_from_roles?.length > 0,
    );
    updateCheckboxState(
      "allow_giftcard",
      storeDetails?.order_giftcard_requires_pin_from_roles?.length > 0,
    );
    updateCheckboxState(
      "allow_loyalty",
      storeDetails?.order_loyalty_requires_pin_from_roles?.length > 0,
    );
    updateCheckboxState(
      "allow_discount",
      storeDetails?.order_discount_requires_pin_from_roles?.length > 0,
    );
    updateCheckboxState(
      "allow_custom_price",
      storeDetails?.order_custom_pricing_requires_pin_from_roles?.length > 0,
    );
  }, [storeDetails]);

  return {
    isLoadingRoles,
    roles: roles?.filter((role) => role?.label.toLowerCase() !== "owner"),
    isRefetchingRoles,
    isAddingStore,
    isEditingStore,
    storeDetails,
    category_list: category_list || [],
    stateInputRef,
    bankAccount,
    isLoadingTimezones,
    timezones: timezones || [],
    isLoadingBankAccountsList,
    isLocation,
    currencyList: countries?.filter(
      (obj, index, self) =>
        index === self.findIndex((t) => t?.name === obj?.name),
    ),
    setIsLocation,
    setStoreDetails,
    submitStoreInfo,
    selectedHours,
    setSelectedHours,
    isCheckboxChecked,
    setIsCheckboxChecked,
    isOtherSetting,
    setIsOtherSetting,
  };
};

export default useStoreForm;
