import { useMutation, useQueryClient } from "react-query";
import {
  updateStorefrontColor,
  uploadLogo,
} from "../../../../../../../../../services/inventory";
import { useContext, useEffect, useRef, useState } from "react";
import { ToastContext } from "../../../../../../../../../hooks/context";
import { useParams, useSearchParams } from "react-router-dom";
import { useColor } from "react-color-palette";

export const useBranding = ({ menuProperties }) => {
  const { menuId } = useParams();
  const triggerToast = useContext(ToastContext);
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const menu_id = searchParams.get("menu_id");

  const fileRef = useRef(null);
  const bannerRef = useRef(null);

  const [color, setColor] = useColor("#7647ee");
  const [showColorPalette, setShowColorPalette] = useState(null);

  const [primaryColor, setPrimaryColor] = useState(
    menuProperties?.primary_colour ? menuProperties?.primary_colour : "#7647ee",
  );
  const [secondaryColor, setSecondaryColor] = useState(
    menuProperties?.secondary_colour
      ? menuProperties?.secondary_colour
      : "#fef7eb",
  );

  const [selectedLogo, setSelectedLogo] = useState(null);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);
  const [bannerUrl, setBannerUrl] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  // useEffect(() => {
  //   queryClient.invalidateQueries(["getMenusDetails"]);
  // }, [queryClient]);

  useEffect(() => {
    if (menuProperties) {
      setPrimaryColor(
        menuProperties.primary_colour
          ? menuProperties.primary_colour
          : "#7647ee",
      );
      setSecondaryColor(
        menuProperties.secondary_colour
          ? menuProperties.secondary_colour
          : "#fef7eb",
      );
      setLogoUrl(menuProperties.logo_url);
      setBannerUrl(menuProperties?.banner_url);
    }
  }, [menuProperties]);

  const { isLoading: isLoadingStorefrontColor, mutate: mutateStorefrontColor } =
    useMutation({
      mutationFn: (data) => updateStorefrontColor(data),
      onSuccess: () => {
        triggerToast("Storefront brand updated successfully", "success");
        queryClient.invalidateQueries(["getMenusDetails"]);
        setIsEditing((prev) => !prev);
      },
    });

  const { isLoading: isLoadingUpload, mutate: mutateUploadLogo } = useMutation({
    mutationFn: (data) => uploadLogo(data),
    onSuccess: (data) => {
      setLogoUrl(data?.data?.data?.url);
    },
  });

  const { isLoading: isLoadingUploadBanner, mutate: mutateUploadBanner } =
    useMutation({
      mutationFn: (data) => uploadLogo(data),
      onSuccess: (data) => {
        setBannerUrl(data?.data?.data?.url);
      },
    });

  return {
    isLoadingStorefrontColor,
    menuId,
    menu_id,
    fileRef,
    bannerRef,
    color,
    showColorPalette,
    primaryColor,
    secondaryColor,
    selectedLogo,
    selectedBanner,
    logoUrl,
    bannerUrl,
    triggerToast,
    isEditing,
    isLoadingUpload,
    isLoadingUploadBanner,
    setIsEditing,
    setColor,
    setShowColorPalette,
    setPrimaryColor,
    setSecondaryColor,
    setSelectedLogo,
    setSelectedBanner,
    mutateStorefrontColor,
    mutateUploadLogo,
    mutateUploadBanner,
  };
};
