import styles from "./MultipleSelectField.module.scss";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { useSelectField } from "./MultipleSelectFieldLogic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";

const MultipleSelectField = ({
  value = null,
  onChange = () => {},
  options = [],
  idKey,
  nameKey,
  placeholder,
  isRequired = false,
  label = null,
  isDisabled = false,
  errorMessage = undefined,
  isSearchable = true,
  isClearable = true,
  minHeight = "50px",
  fontSize = "16px",
  floatError = true,
  Creatable = false,
  includeSelectAll = false,
  selectInputRef = null,
  state_id,
  giveControl = true,
  country_id,
  onCreateNewOption,
  creatableLabel = "Select All",
  addCity,
}) => {
  const [selectedValues, setSelectedValues] = useState(value || []);
  const { items } = useSelectField({
    options,
    valueKey: idKey,
    labelKey: nameKey,
    value,
  });
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [cityId, setCityId] = useState(null);

  const allOptions = includeSelectAll
    ? [{ label: creatableLabel, value: "select_all" }, ...items]
    : items;

  const handleSelectChange = (selected) => {
    if (selected) {
      if (
        includeSelectAll &&
        selected.some((option) => option.value === "select_all")
      ) {
        if (selectedValues.length === items.length) {
          setSelectedValues([]);
          onChange([]);
        } else {
          setSelectedValues(items);
          onChange(items);
        }
        setMenuIsOpen(false);
      } else {
        setSelectedValues(selected);
        onChange(selected);
        setMenuIsOpen(false);
      }
    } else {
      setSelectedValues([]);
      onChange([]);
      setMenuIsOpen(false);
    }
  };

  useEffect(() => {
    setSelectedValues(value);
  }, [value]);

  const customStyles = (bodyStyles) => {
    return {
      control: (provided, state) => ({
        ...provided,
        border:
          state.isFocused || errorMessage !== undefined
            ? `1px solid ${
                errorMessage !== undefined ? "#dc3545" : "#7647ee"
              } !important`
            : "1px solid #cccccc",
        boxShadow: "none",
        "&:hover": {
          border: "1px solid #7647ee !important",
          boxShadow: "none",
        },
        ...bodyStyles,
      }),

      option: (styles, { isFocused }) => ({
        ...styles,
        backgroundColor: isFocused ? "#7647ee" : null,
        color: isFocused ? "#fff" : null,
        zIndex: 1,
      }),
      menu: (base) => ({
        ...base,
        zIndex: 10,
      }),
    };
  };

  useEffect(() => {
    if (addCity && addCity.data && addCity.data.data.id) {
      setCityId(addCity.data.data.id);
    }
  }, [addCity]);

  return (
    <div className={styles["custom-dropdown"]}>
      {label !== null && (
        <label htmlFor="" data-testid="label">
          {isRequired && <span className="text-danger">*</span>}
          <span>{label}</span>
        </label>
      )}
      {Creatable ? (
        <CreatableSelect
          ref={selectInputRef}
          className={styles["custom-dropdown__select"]}
          classNamePrefix={styles["custom-dropdown__select"]}
          options={allOptions}
          onChange={handleSelectChange}
          value={selectedValues}
          placeholder={placeholder}
          isDisabled={isDisabled}
          label={label}
          styles={customStyles({ minHeight: minHeight, fontSize: fontSize })}
          isMulti
          isSearchable={isSearchable}
          isClearable={isClearable}
          menuIsOpen={menuIsOpen}
          onMenuOpen={() => setMenuIsOpen(true)}
          onMenuClose={() => setMenuIsOpen(false)}
          formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
          createOptionPosition="first"
          onCreateOption={(inputValue) => {
            if (inputValue && onCreateNewOption) {
              onCreateNewOption({
                name: inputValue,
                state_id: state_id,
                country_id: country_id,
              });
            }
            if (cityId !== null) {
              const newOption = {
                value: cityId,
                label: inputValue,
              };

              setSelectedValues((prevValues) => {
                const updatedValues = [...prevValues, newOption];
                onChange(updatedValues);
                return updatedValues;
              });
            }
          }}
        />
      ) : (
        <Select
          ref={selectInputRef}
          className={styles["custom-dropdown__select"]}
          classNamePrefix={styles["custom-dropdown__select"]}
          options={allOptions}
          onChange={handleSelectChange}
          defaultValue={selectedValues}
          value={!giveControl ? selectedValues : undefined}
          placeholder={placeholder}
          isDisabled={isDisabled}
          label={label}
          styles={customStyles({ minHeight: minHeight, fontSize: fontSize })}
          isMulti
          isSearchable={isSearchable}
          isClearable={isClearable}
          menuIsOpen={menuIsOpen}
          onMenuOpen={() => setMenuIsOpen(true)}
          onMenuClose={() => setMenuIsOpen(false)}
        />
      )}
      {/* error message */}
      {floatError || errorMessage !== undefined ? (
        <p
          className={`${styles["error-message"]} text-danger ${
            errorMessage !== undefined ? styles["show"] : ""
          }`}
          data-testid="error-message"
        >
          <FontAwesomeIcon
            icon={["fas", "exclamation-circle"]}
            className="mr-2"
            style={{ fontSize: "13px" }}
          />
          {errorMessage !== undefined && <span>{errorMessage.message}</span>}
        </p>
      ) : null}
    </div>
  );
};

export default MultipleSelectField;
