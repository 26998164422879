import Icon from "../../../../assets/icons";
import styles from "./styles.module.scss";
import { useState, useEffect, useRef } from "react";

const MoreActions = ({ showDeleteModal }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <span className={styles.container} ref={menuRef}>
      <span
        style={{ cursor: "pointer" }}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <img src={Icon.option_alt} alt="" />
      </span>

      {isOpen ? (
        <div className={styles.dropdrown}>
          <div className={styles.dropdrownItem}>
            <p onClick={() => showDeleteModal()} className="error--text">
              Delete
            </p>
          </div>
        </div>
      ) : null}
    </span>
  );
};

export default MoreActions;
