import { useState, useContext } from "react";
import { useGetAuthorizationUsers } from "../../../hooks/api/queries";
import { useSendAuthorizationPinMutation } from "../../../hooks/api/mutations/useInventory";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import { getRandomIntInclusive } from "../../../helpers";
import { cleanObject } from "../../../helpers";
import { useDispatch, useSelector } from "react-redux";
import { setAuthorizationErrorOtpCode } from "../../../store/modules/apiErrorHandler";

const useAuthorizationLogic = ({ action, object_id, object_type }) => {
  const [isAuthUserSelected, setIsAuthUserSelected] = useState();
  const sendAuthorisationPinMutation = useSendAuthorizationPinMutation();
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const [currentStep, setCurrentStep] = useState(1);
  const [otp, setOtp] = useState("");
  const {
    data: authUsers,
    isLoading: isLoadingAuthUsers,
    refetch,
  } = useGetAuthorizationUsers(action);
  const dispatch = useDispatch();
  const otp_code = useSelector(
    (state) => state.apiErrorReducer?.otpAuthorizationError?.otp_code,
  );

  const sendAuthorisationPin = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 20));
    const data = {
      action: action,
      approver_id: isAuthUserSelected,
      object_id: object_id,
      object_type: object_type,
    };
    sendAuthorisationPinMutation.mutate(cleanObject(data), {
      onSuccess: () => {
        triggerToast("Pin Successfully Delivered!", "success");
        setCurrentStep(2);
      },
      onSettled: () => setRequestLoaderProgress(100),
    });
  };

  const resendRequest = (value) => {
    setOtp(value);
    if (value?.length !== 4) return;

    dispatch(setAuthorizationErrorOtpCode(value));
  };

  return {
    isLoadingAuthUsers,
    authUsers,
    isAuthUserSelected,
    currentStep,
    isLoading: sendAuthorisationPinMutation.isLoading,
    otp,
    otp_code,
    setIsAuthUserSelected,
    resendRequest,
    setCurrentStep,
    refetch,
    sendAuthorisationPin,
  };
};

export default useAuthorizationLogic;
