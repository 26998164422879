import { useContext, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { cleanObject, getRandomIntInclusive } from "../../../../helpers";
import { RequestLoader, ToastContext } from "../../../../hooks/context";
import {
  downloadSalesEndOfDay,
  endOfDayDetails,
} from "../../../../services/sales-point";

const useEndOfDayDetails = ({ endOfDayFormDetails }) => {
  const [isDownloadReport, setIsDownloadReport] = useState(false);
  const [endOfDayId, setEndOfDayId] = useState(null);
  const [loadingPercent, setLoadingPercent] = useState("50%");
  const [downloadUrl, setDownloadUrl] = useState(null);

  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const { mutate: exportEndOfDay } = useMutation((data) =>
    downloadSalesEndOfDay(data),
  );

  const { data: endOfDayDetailsData } = useQuery(
    ["endOfDayDetails", endOfDayId],
    () => endOfDayDetails({ id: endOfDayId }).then((res) => res?.data?.data),
    {
      refetchInterval: endOfDayId ? 2000 : false,
      enabled: endOfDayId ? true : false,
      onSuccess: (data) => {
        console.log(data);
        if (data.status === "pending") {
          setLoadingPercent("80%");
        }
        if (data.status === "completed") {
          setDownloadUrl(data?.url);
          setEndOfDayId(null);
          setLoadingPercent("100%");
        }
      },
    },
  );

  const downloadEndOfDay = ({ download_format, delivery_method }) => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 40));
    exportEndOfDay(
      cleanObject({
        store_id: endOfDayFormDetails?.stores.map((item) => item?.value),
        initiator_id: endOfDayFormDetails?.initiators?.some(
          (item) => item?.value === "select all",
        )
          ? []
          : endOfDayFormDetails?.initiators.map((item) => item?.value),
        download_format: download_format,
        start_date: endOfDayFormDetails?.startDate,
        end_date: endOfDayFormDetails?.endDate,
        start_time: endOfDayFormDetails?.startTime,
        end_time: endOfDayFormDetails?.endTime,
        order_source: endOfDayFormDetails?.order_source.value
          ? [endOfDayFormDetails?.order_source.value]
          : [],
        delivery_method,
      }),
      {
        onSuccess: (res) => {
          if (delivery_method === "download") {
            if (res?.data?.data?.end_of_day_log_id)
              setEndOfDayId(res?.data?.data?.end_of_day_log_id);
          } else {
            triggerToast(res?.data?.detail, "success");
          }
        },

        onSettled: () => setRequestLoaderProgress(100),
      },
    );
  };

  return {
    downloadUrl,
    loadingPercent,
    isDownloadReport,
    endOfDayDetailsData,
    sucessEndOfDay: loadingPercent === "100%" ? true : false,
    setIsDownloadReport,
    downloadEndOfDay,
  };
};
export default useEndOfDayDetails;
