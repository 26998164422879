import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import primaryComponents from "../../../../../components/primaryComponents";
import { formatCurrency } from "../../../../../helpers";
import useAddDebt from "./useAddDebt";
import { Validation } from "./Validation";
import { useEffect } from "react";

const AddDebtWidgt = ({ closeWidget, customer, onSuccess }) => {
  const {
    isLoading,
    amount,
    repaymentDate,
    note,
    store,
    storeList,
    setAmount,
    setRepaymentDate,
    setNote,
    handleAddDebt,
    setStore,
  } = useAddDebt({
    closeWidget,
    customer,
    onSuccess,
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    trigger,
    register,
    setValue,
  } = Validation({ amount, repaymentDate });

  useEffect(() => {
    setValue("amount", amount);
    setValue("repaymentDate", repaymentDate);
    setValue("note", note);
  }, [amount, repaymentDate, note]);

  return (
    <>
      <div className="update-credit">
        <FontAwesomeIcon
          onClick={closeWidget}
          className="d-block ml-auto"
          icon="times"
          style={{ fontSize: "25px", cursor: "pointer" }}
        />
        <div className="update-credit__title">Add Debt</div>
        <div className="update-credit__subtitle">
          Record & issue Loans to your customers.
        </div>

        <dl className="update-credit__data mt-4">
          <dt className="outstanding">Balance</dt>
          <dd
            className="outstanding"
            style={{
              color: customer?.outstanding_balance <= 0 ? "#19AE57" : "#E02020",
            }}
          >
            {formatCurrency({
              value:
                customer?.outstanding_balance <= 0
                  ? Math.abs(customer?.outstanding_balance)
                  : -Math.abs(customer?.outstanding_balance),
              currencyCode: customer?.store?.currency_code,
            })}
          </dd>

          <dt>Customer Name</dt>
          <dd>{customer.full_name}</dd>

          <dt>Total Credit</dt>
          <dd>
            {formatCurrency({
              value: customer.total_credit,
              currencyCode: customer.store?.currency_code,
            })}
          </dd>

          <dt>Total Repayment</dt>
          <dd>
            {formatCurrency({
              value: customer.total_repayment,
              currencyCode: customer.store?.currency_code,
            })}
          </dd>

          <dt>Status</dt>
          <dd>
            <span
              style={{ fontSize: "10px" }}
              className="success--squared ml-3"
            >
              Active
            </span>
          </dd>
        </dl>

        <div className="update-credit__payment-form">
          <primaryComponents.InputFieldV2
            name="amount"
            control={control}
            label={"Amount"}
            isRequired={true}
            placeholder="Amount"
            value={amount}
            type="number"
            onChange={({ target }) => setAmount(target.value)}
            errorMessage={
              errors.amount && {
                message: errors.amount.message,
              }
            }
            onKeyUp={() =>
              errors.amount !== undefined && trigger("accountNumber")
            }
            register={register}
          />

          <primaryComponents.SelectFieldV2
            control={control}
            name="StoreId"
            label={"Which store is to add the debt to?"}
            options={storeList}
            idKey="id"
            nameKey="name"
            onChange={(value) => {
              setStore(value);
            }}
            value={store || "Select store"}
            classNames="store-select"
            register={register}
            errorMessage={errors.StoreId}
          />

          <primaryComponents.DateInputV2
            name="repaymentDate"
            control={control}
            label={"Repayment Date"}
            isRequired={true}
            value={repaymentDate}
            onChange={({ target: { value } }) => setRepaymentDate(value)}
            errorMessage={
              errors.repaymentDate && {
                message: errors.repaymentDate.message,
              }
            }
            register={register}
          />

          <primaryComponents.TextAreaV2
            control={control}
            type="text"
            label={"Note"}
            name="note"
            placeholder="Add note for this loan transaction"
            value={note}
            onChange={({ target: { value } }) => setNote(value)}
            register={register}
          />
        </div>

        <div className="mt-2">
          <primaryComponents.Button
            classNames="btn btn--primary cover"
            loadingText="Adding Debt..."
            onClick={handleSubmit(handleAddDebt)}
            isLoading={isLoading}
            isDisabled={isLoading}
          >
            Add
          </primaryComponents.Button>
        </div>
      </div>
    </>
  );
};

export default AddDebtWidgt;
