const TemplateIcon = () => {
  return (
    <svg
      width="56"
      height="25"
      viewBox="0 0 56 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2120_49163)">
        <path
          d="M51.8376 21.1892H67.7295C69.1924 21.1892 70.3782 20.4099 70.3782 19.4486V5.52429C70.3782 4.56302 69.1924 3.78375 67.7295 3.78375H51.8376C50.3748 3.78375 49.189 4.56302 49.189 5.52429V19.4486C49.189 20.4099 50.3748 21.1892 51.8376 21.1892Z"
          fill="#FFEEE9"
        />
        <path
          d="M51.3079 17.7081H56.6052H61.9025M51.3079 19.4486C52.8594 19.4486 55.2809 19.4486 55.2809 19.4486M51.8376 21.1892H67.7295C69.1924 21.1892 70.3782 20.4099 70.3782 19.4486V5.52429C70.3782 4.56302 69.1924 3.78375 67.7295 3.78375H51.8376C50.3748 3.78375 49.189 4.56302 49.189 5.52429V19.4486C49.189 20.4099 50.3748 21.1892 51.8376 21.1892Z"
          stroke="#15112D"
          strokeWidth="0.507027"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M61.499 5.24658H53.0233C52.3546 5.24658 51.8125 5.78868 51.8125 6.45739V14.9331C51.8125 15.6018 52.3546 16.1439 53.0233 16.1439H61.499C62.1677 16.1439 62.7098 15.6018 62.7098 14.9331V6.45739C62.7098 5.78868 62.1677 5.24658 61.499 5.24658Z"
          fill="url(#paint0_linear_2120_49163)"
          stroke="#FD561F"
          strokeWidth="0.363243"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M53.0234 16.1439L59.6829 9.48444L62.7099 12.5115"
          stroke="#FFEEE9"
          strokeWidth="0.217946"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M55.142 9.48443C55.6435 9.48443 56.0501 9.07786 56.0501 8.57632C56.0501 8.07479 55.6435 7.66821 55.142 7.66821C54.6405 7.66821 54.2339 8.07479 54.2339 8.57632C54.2339 9.07786 54.6405 9.48443 55.142 9.48443Z"
          stroke="#FFEEE9"
          strokeWidth="0.217946"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.6218 21.1892H43.5137C44.9765 21.1892 46.1623 20.4099 46.1623 19.4486V5.52429C46.1623 4.56302 44.9765 3.78375 43.5137 3.78375H27.6218C26.159 3.78375 24.9731 4.56302 24.9731 5.52429V19.4486C24.9731 20.4099 26.159 21.1892 27.6218 21.1892Z"
          fill="#FEF7EB"
        />
        <path
          d="M27.0921 17.7081H32.3894H37.6867M27.0921 19.4486C28.6436 19.4486 31.065 19.4486 31.065 19.4486M27.6218 21.1892H43.5137C44.9765 21.1892 46.1623 20.4099 46.1623 19.4486V5.52429C46.1623 4.56302 44.9765 3.78375 43.5137 3.78375H27.6218C26.159 3.78375 24.9731 4.56302 24.9731 5.52429V19.4486C24.9731 20.4099 26.159 21.1892 27.6218 21.1892Z"
          stroke="#15112D"
          strokeWidth="0.507027"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.2827 5.24658H28.807C28.1383 5.24658 27.5962 5.78868 27.5962 6.45739V14.9331C27.5962 15.6018 28.1383 16.1439 28.807 16.1439H37.2827C37.9514 16.1439 38.4935 15.6018 38.4935 14.9331V6.45739C38.4935 5.78868 37.9514 5.24658 37.2827 5.24658Z"
          fill="#F9BD63"
          stroke="#F7AD3C"
          strokeWidth="0.363243"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.8071 16.1439L35.4666 9.48444L38.4936 12.5115"
          stroke="#FEF7EB"
          strokeWidth="0.217946"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.9262 9.48443C31.4277 9.48443 31.8343 9.07786 31.8343 8.57632C31.8343 8.07479 31.4277 7.66821 30.9262 7.66821C30.4246 7.66821 30.0181 8.07479 30.0181 8.57632C30.0181 9.07786 30.4246 9.48443 30.9262 9.48443Z"
          stroke="#FEF7EB"
          strokeWidth="0.217946"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M44.6489 7.56757H39.3516"
          stroke="#696677"
          strokeWidth="0.507027"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M42.3786 9.08105H39.3516"
          stroke="#696677"
          strokeWidth="0.507027"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.40548 21.1892H19.2974C20.7602 21.1892 21.946 20.4099 21.946 19.4486V5.52429C21.946 4.56302 20.7602 3.78375 19.2974 3.78375H3.40548C1.94268 3.78375 0.756836 4.56302 0.756836 5.52429V19.4486C0.756836 20.4099 1.94268 21.1892 3.40548 21.1892Z"
          fill="#E8F7EE"
        />
        <path
          d="M2.87575 17.7081H8.17305H13.4703M2.87575 19.4486C4.4273 19.4486 6.84873 19.4486 6.84873 19.4486M3.40548 21.1892H19.2974C20.7602 21.1892 21.946 20.4099 21.946 19.4486V5.52429C21.946 4.56302 20.7602 3.78375 19.2974 3.78375H3.40548C1.94268 3.78375 0.756836 4.56302 0.756836 5.52429V19.4486C0.756836 20.4099 1.94268 21.1892 3.40548 21.1892Z"
          stroke="#15112D"
          strokeWidth="0.507027"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.0669 5.24658H4.59118C3.92247 5.24658 3.38037 5.78868 3.38037 6.45739V14.9331C3.38037 15.6018 3.92247 16.1439 4.59118 16.1439H13.0669C13.7356 16.1439 14.2777 15.6018 14.2777 14.9331V6.45739C14.2777 5.78868 13.7356 5.24658 13.0669 5.24658Z"
          fill="#47BE79"
          stroke="#19AE57"
          strokeWidth="0.363243"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.59082 16.1439L11.2503 9.48444L14.2773 12.5115"
          stroke="#E8F7EE"
          strokeWidth="0.217946"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.70987 9.48443C7.2114 9.48443 7.61797 9.07786 7.61797 8.57632C7.61797 8.07479 7.2114 7.66821 6.70987 7.66821C6.20833 7.66821 5.80176 8.07479 5.80176 8.57632C5.80176 9.07786 6.20833 9.48443 6.70987 9.48443Z"
          stroke="#E8F7EE"
          strokeWidth="0.217946"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.4326 7.56757H15.1353"
          stroke="#696677"
          strokeWidth="0.507027"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.1623 9.08105H15.1353"
          stroke="#696677"
          strokeWidth="0.507027"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="50.7026"
          y="3.78381"
          width="5.2973"
          height="17.4054"
          fill="url(#paint1_linear_2120_49163)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2120_49163"
          x1="55.2434"
          y1="10.5945"
          x2="63.9461"
          y2="10.5945"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FD784C" />
          <stop offset="1" stopColor="#97482D" stopOpacity="0.8" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2120_49163"
          x1="50.7026"
          y1="11.1267"
          x2="58.1189"
          y2="11.1267"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9D9D9" stopOpacity="0" />
          <stop offset="1" stopOpacity="0.55" />
        </linearGradient>
        <clipPath id="clip0_2120_49163">
          <rect width="56" height="24.2162" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TemplateIcon;
