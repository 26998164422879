import { useState, useContext } from "react";
import { ToastContext } from "../../../hooks/context";
import { useEditCreditLimitMutation } from "../../../hooks/api/mutations/useInventory";
import { useQueryClient } from "react-query";

const useUpdateCreditLogLogic = ({ closeModal, customer }) => {
  const triggerToast = useContext(ToastContext);
  const [creditLimit, setCreditLimit] = useState(customer?.credit_limit || "");
  const editCreditLimitMutation = useEditCreditLimitMutation();
  const queryClient = useQueryClient();

  const updateCreditLimitById = () => {
    editCreditLimitMutation.mutate(
      { id: customer?.id, credit_limit: Number(creditLimit) },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["getCustomerDetails"]);
          triggerToast("Credit Limit Updated Successfully", "success");
          closeModal();
        },
      },
    );
  };

  return {
    updateCreditLimitById,
    creditLimit,
    setCreditLimit,
    isUpdating: editCreditLimitMutation.isLoading,
  };
};
export default useUpdateCreditLogLogic;
