import { Controller } from "react-hook-form";
import StoreCheckoutValidation from "./Validation";
import StoreCheckoutLogic from "./StoreCheckoutLogic";
import styles from "./StoreCheckout.module.scss";
import secondaryComponents from "..";
import CardPayment from "./CardPayment";
import CashPayment from "./CashPayment";
import CreditPayment from "./CreditPayment";
import TransferPayment from "./TransferPayment";
import SplitPayment from "./SplitPayment";
import { formatCurrency } from "../../../helpers";
import WarehousePayment from "./WarehousePayment";
import Icon from "../../../assets/icons";
import primaryComponents from "../../primaryComponents";
import Orderflows from "./Orderflows";
import { useStoreCurrency } from "../../../hooks/useStoreCurrency";
import { useSelector } from "react-redux";
import StoreWalletPayment from "./StoreWalletPayment";
import OrderCancel from "../BillsWidget/orderCancel";

const StoreCheckout = ({
  closeStoreCheckout,
  cancelCurrentOrder,
  refetchPendingSessions = () => {},
}) => {
  const { allow_ledger_account } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  const { store_currency } = useStoreCurrency();
  const {
    checkoutType,
    isLoadingOrder,
    orderDetails,
    isPendingSessions,
    // screenViews,
    showWareHouse,
    pendCheckout,
    closePaymentSession,
    openTransactionsession,
    setCheckoutType,
    bankAccount,
    isLoadingBankAccountsList,
    selectBank,
    setSelectedBank,
    isOrderCancel,
    setIsOrderCancel,
  } = StoreCheckoutLogic({
    closeStoreCheckout,
    refetchPendingSessions,
  });
  const { control } = StoreCheckoutValidation(checkoutType);
  return (
    <>
      <secondaryComponents.LoaderHelper
        isLoading={isLoadingOrder}
        classNames="mt-5"
      >
        {!orderDetails?.is_paid ? (
          <div className={styles.storeCheckout}>
            <div className="d-flex justify-content-end" style={{ gap: "10px" }}>
              <primaryComponents.Button
                classNames="btn btn--outline"
                onClick={pendCheckout}
                isDisabled={isPendingSessions}
                isLoading={isPendingSessions}
                loadingText="Pending..."
              >
                <img className="mr-2" src={Icon.pauseIcon} alt="" />
                Pend Order
              </primaryComponents.Button>
              <primaryComponents.Button
                classNames="btn btn--outline"
                onClick={() => setIsOrderCancel(orderDetails?.id)}
              >
                <img className="mr-2" src={Icon.closeIcon} alt="" />
                Cancel Order
              </primaryComponents.Button>
              {/* <FontAwesomeIcon
              onClick={() => cancelCurrentOrder()}
              className="d-block"
              icon="times"
              style={{ fontSize: "25px" }}
            /> */}
            </div>

            <div className="mt-4 mb-4">
              <h5 className="mb-3 font-weight-normal">Select Payment Method</h5>
              <div className={styles.storeCheckout__purchaseOptions}>
                {/* Cash payment */}
                <secondaryComponents.NavigateWithPermission
                  permissionParentKey={"Inventory Management"}
                  permissionChildKey="can_checkout_with_cash_payment"
                >
                  <div className={styles.storeCheckout__purchaseOption}>
                    <Controller
                      defaultValue={checkoutType}
                      name="checkout_type"
                      control={control}
                      render={({ field: { onChange } }) => (
                        <secondaryComponents.WalletOption
                          title={"Cash"}
                          name="purchase-option"
                          value={"cash"}
                          checked={checkoutType === "cash"}
                          onChange={(option) => {
                            onChange(option);
                            setCheckoutType(option);
                          }}
                        />
                      )}
                    />
                  </div>
                </secondaryComponents.NavigateWithPermission>
                {/* Card payment */}
                <secondaryComponents.NavigateWithPermission
                  permissionParentKey={"Inventory Management"}
                  permissionChildKey="can_checkout_with_card_payment"
                >
                  <div className={styles.storeCheckout__purchaseOption}>
                    <Controller
                      defaultValue={checkoutType}
                      name="checkout_type"
                      control={control}
                      render={({ field: { onChange } }) => (
                        <secondaryComponents.WalletOption
                          title={"Card"}
                          name="purchase-option"
                          value={"card"}
                          checked={checkoutType === "card"}
                          onChange={(option) => {
                            onChange(option);
                            setCheckoutType(option);
                          }}
                        />
                      )}
                    />
                  </div>
                </secondaryComponents.NavigateWithPermission>
                {/* Transfer payment */}
                <secondaryComponents.NavigateWithPermission
                  permissionParentKey={"Inventory Management"}
                  permissionChildKey="can_checkout_with_transfer"
                >
                  <div className={styles.storeCheckout__purchaseOption}>
                    <Controller
                      defaultValue={checkoutType}
                      name="checkout_type"
                      control={control}
                      render={({ field: { onChange } }) => (
                        <secondaryComponents.WalletOption
                          title={"Transfer"}
                          name="purchase-option"
                          value={"transfer"}
                          checked={checkoutType === "transfer"}
                          onChange={(option) => {
                            onChange(option);
                            setCheckoutType(option);
                          }}
                        />
                      )}
                    />
                  </div>
                </secondaryComponents.NavigateWithPermission>
                {/* store wallet payment */}
                <secondaryComponents.NavigateWithPermission
                  permissionParentKey={"Inventory Management"}
                  permissionChildKey="can_checkout_with_store_wallet"
                >
                  <div className={styles.storeCheckout__purchaseOption}>
                    <Controller
                      defaultValue={checkoutType}
                      name="checkout_type"
                      control={control}
                      render={({ field: { onChange } }) => (
                        <secondaryComponents.WalletOption
                          title={"Store Wallet"}
                          name="purchase-option"
                          value={"storeWallet"}
                          checked={checkoutType === "storeWallet"}
                          onChange={(option) => {
                            onChange(option);
                            setCheckoutType(option);
                          }}
                        />
                      )}
                    />
                  </div>
                </secondaryComponents.NavigateWithPermission>
                {/* Credit payment */}
                <div className={styles.storeCheckout__purchaseOption}>
                  <Controller
                    defaultValue={checkoutType}
                    name="checkout_type"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <secondaryComponents.WalletOption
                        title={"Credit"}
                        name="purchase-option"
                        value={"credit"}
                        checked={checkoutType === "credit"}
                        onChange={(option) => {
                          onChange(option);
                          setCheckoutType(option);
                        }}
                      />
                    )}
                  />
                </div>
                {/* Split payment */}
                <secondaryComponents.NavigateWithPermission
                  permissionParentKey={"Inventory Management"}
                  permissionChildKey="can_checkout_with_split_payment"
                >
                  <div className={styles.storeCheckout__purchaseOption}>
                    <Controller
                      defaultValue={checkoutType}
                      name="checkout_type"
                      control={control}
                      render={({ field: { onChange } }) => (
                        <secondaryComponents.WalletOption
                          title={"Split"}
                          name="purchase-option"
                          value={"split"}
                          checked={checkoutType === "split"}
                          onChange={(option) => {
                            onChange(option);
                            setCheckoutType(option);
                          }}
                        />
                      )}
                    />
                  </div>
                </secondaryComponents.NavigateWithPermission>
                {/* Warehouse_checkout payment */}
                {showWareHouse ? (
                  <secondaryComponents.NavigateWithPermission
                    permissionParentKey={"Inventory Management"}
                    permissionChildKey="can_checkout_with_warehouse"
                  >
                    <div className={styles.storeCheckout__purchaseOption}>
                      <Controller
                        defaultValue={checkoutType}
                        name="checkout_type"
                        control={control}
                        render={({ field: { onChange } }) => (
                          <secondaryComponents.WalletOption
                            title={"PR"}
                            name="purchase-option"
                            value={"warehouse_checkout"}
                            checked={checkoutType === "warehouse_checkout"}
                            onChange={(option) => {
                              onChange(option);
                              setCheckoutType(option);
                            }}
                          />
                        )}
                      />
                    </div>
                  </secondaryComponents.NavigateWithPermission>
                ) : null}
              </div>
              {checkoutType === "warehouse_checkout" ? (
                <p className="dark--text">
                  Order Value:{" "}
                  <span className="purple--text">
                    {formatCurrency({
                      value: orderDetails?.total_amount,
                      currencyCode: store_currency,
                    })}
                  </span>
                </p>
              ) : (
                <div className="d-flex justify-content-between align-items-center">
                  <p className="dark--text">
                    Total Payable Amount:{" "}
                    <span className="purple--text">
                      {formatCurrency({
                        value: orderDetails?.total_amount,
                        currencyCode: store_currency,
                      })}
                    </span>
                  </p>
                  {allow_ledger_account === true &&
                    (checkoutType === "transfer" ||
                      checkoutType === "card" ||
                      checkoutType === "split") && (
                      <>
                        <secondaryComponents.LoaderHelper
                          isLoading={isLoadingBankAccountsList}
                          className="mt-1"
                        >
                          <div style={{ width: "200px" }}>
                            <primaryComponents.SelectField
                              options={bankAccount?.data?.map((bank) => ({
                                id: bank?.id,
                                name: bank?.account_name,
                              }))}
                              idKey="id"
                              nameKey="name"
                              value={
                                bankAccount
                                  ? bankAccount?.data[0]?.account_name
                                  : "Select Bank"
                              }
                              onChange={(value) => {
                                setSelectedBank(value);
                              }}
                              floatError={false}
                              classNames="bank-name"
                              isSearchable={false}
                            />
                          </div>
                        </secondaryComponents.LoaderHelper>
                        {/* <div
                              className="d-flex purple--text align-items-center"
                              style={{
                                gap: "5px",
                                flexDirection: "column",
                                position: "relative",
                              }}
                              onClick={() => setShowAccount(!showAccount)}
                            >
                              <p className="default-text-size d-flex purple--text align-items-center">
                                Select account
                                <FontAwesomeIcon
                                  className={`pl-2 ${showAccount ? "purple--text" : "dark--text"}`}
                                  icon={`${showAccount ? "angle-up" : "angle-down"}`}
                                  fontSize={"18px"}
                                />
                              </p>
      
                              {showAccount && (
                                <div
                                  style={{
                                    marginTop: "1px",
                                    backgroundColor: "#F3F1FC",
                                    padding: "5px",
                                    borderRadius: "8px",
                                  }}
                                >
                                  {
                                    <p className="dark--text small-text-size pr-4 pl-4">
                                      {bankAccount?.data?.map(
                                        (account) => account?.account_name,
                                      )}
                                    </p>
                                  }
                                </div>
                              )}
                            </div> */}
                      </>
                    )}
                </div>
              )}
            </div>

            <>
              {/* cash payment section */}
              {checkoutType === "cash" && (
                <CashPayment
                  orderDetails={orderDetails}
                  closePaymentSession={closePaymentSession}
                />
              )}
              {/* card payment section */}
              {checkoutType === "card" && (
                <CardPayment
                  orderDetails={orderDetails}
                  closePaymentSession={closePaymentSession}
                  selectBank={selectBank}
                />
              )}
              {/* card payment section */}
              {checkoutType === "storeWallet" && (
                <StoreWalletPayment
                  orderDetails={orderDetails}
                  closePaymentSession={closePaymentSession}
                  selectBank={selectBank}
                />
              )}
              {/* transfer payment section */}
              {checkoutType === "transfer" && (
                <TransferPayment
                  orderDetails={orderDetails}
                  closePaymentSession={closePaymentSession}
                  selectBank={selectBank}
                />
              )}
              {/* credit payment section */}
              {checkoutType === "credit" && (
                <CreditPayment
                  orderDetails={orderDetails}
                  closePaymentSession={closePaymentSession}
                  openTransactionsession={openTransactionsession}
                />
              )}
              {/* split payment section */}
              {checkoutType === "split" && (
                <SplitPayment
                  orderDetails={orderDetails}
                  closePaymentSession={closePaymentSession}
                  openTransactionsession={openTransactionsession}
                  selectBank={selectBank}
                />
              )}
              {/* warehouse_checkout payment section */}
              {checkoutType === "warehouse_checkout" && (
                <WarehousePayment
                  orderDetails={orderDetails}
                  closePaymentSession={closePaymentSession}
                />
              )}
            </>
          </div>
        ) : (
          <>
            {/* cusstomer form */}
            <Orderflows
              orderId={orderDetails?.id}
              closeModal={closeStoreCheckout}
              viaSellMode={true}
              isViaBill={orderDetails?.bill_url ? true : false}
            />
          </>
        )}
      </secondaryComponents.LoaderHelper>
      <secondaryComponents.Modal
        isActive={isOrderCancel !== null}
        closeModal={() => setIsOrderCancel(null)}
        width={500}
      >
        <OrderCancel
          closeWidget={() => setIsOrderCancel(null)}
          orderDetails={orderDetails}
          cancelCurrentOrder={cancelCurrentOrder}
          type="order"
          pendCheckout={pendCheckout}
        />
      </secondaryComponents.Modal>
    </>
  );
};
export default StoreCheckout;
