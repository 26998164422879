import axios from "axios";
import store from "../store";
import { setAccessToken, setRequestingTokens } from "../store/modules/profile";
import {
  setApiErrorMessage,
  setAuthorizationErrorOtpCode,
  setOtpAuthorizationError,
  setSubscriptionAuthorizationError,
  setVerifiedOTPCode,
} from "../store/modules/apiErrorHandler";
import {
  logoutHandler,
  getUrlQuerysection,
  get422errorMessage,
  encodeToBase64,
  cleanObject,
} from "../helpers";

const awaitApprovalCodeEntry = async ({
  originalRequest,
  issueData,
  interval = 100,
  duration = 300000,
  updateIssueData = true,
}) => {
  if (updateIssueData)
    store.dispatch(
      setOtpAuthorizationError(
        cleanObject({
          action: issueData?.action_key,
          object_id: issueData?.object_id || null,
          object_type: issueData?.object_type || null,
          message: issueData?.detail,
          otp_code: null,
          // originalRequest: originalRequest,
        }),
      ),
    );

  return new Promise((resolve, reject) => {
    let countedInterval = 0;
    let timeoutId;

    const checkCondition = () => {
      const state = store.getState();
      const otp_code = state.apiErrorReducer.otpAuthorizationError.otp_code;
      const action = state.apiErrorReducer.otpAuthorizationError.action;
      if (otp_code) {
        clearTimeout(timeoutId); // Clean up the timeout
        originalRequest.params = {
          ...originalRequest.params,
          auth_pin: encodeToBase64(otp_code),
        };
        store.dispatch(setAuthorizationErrorOtpCode(null));
        store.dispatch(setVerifiedOTPCode(otp_code));
        resolve(); // Resolve the promise when the key becomes true
      } else {
        countedInterval += interval;
        if (!action || countedInterval >= duration) {
          clearTimeout(timeoutId); // Clean up the timeout
          store.dispatch(
            setOtpAuthorizationError({
              action: null,
              object_id: null,
              object_type: null,
              message: null,
              otp_code: null,
            }),
          );
          reject(new Error("OTP approval timed out or invalid action")); // Reject with error
        } else {
          timeoutId = setTimeout(checkCondition, interval); // Check again after the interval
        }
      }
    };

    checkCondition(); // Start the condition check
  });
};

// base url
// eslint-disable-next-line no-undef
let baseUrlBusiness = process.env.REACT_APP_LUMI_MERCHANT_APP_BASE_URL;

const httpClient = axios.create({
  baseURL: baseUrlBusiness,
});

// setting authorization header
const httpRequest = (config) => {
  const state = store.getState();
  const { accessToken } = state.profileDetailsReducer;
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
    config.headers["X-CHANNEL"] = "web";
  }
  return config;
};

// Response interceptors
const onFulfilled = (response) => {
  return Promise.resolve(response);
};

const onRejected = async (error) => {
  if (error?.response) {
    if (error?.response?.status) {
      const originalRequest = error.config;
      if (error?.response?.status === 401 && !originalRequest._retry) {
        const state = store.getState();
        const { requestingTokens } = state.profileDetailsReducer;
        if (requestingTokens) return httpClient(originalRequest);
        if (localStorage.getItem("refreshToken")) {
          originalRequest._retry = true;
          store.dispatch(setRequestingTokens(true));
          // eslint-disable-next-line no-undef
          return axios
            .post(
              `${process.env.REACT_APP_LUMI_MERCHANT_APP_BASE_URL}/tokens/refresh/`,
              {
                refresh: localStorage.getItem("refreshToken"),
              },
            )
            .then((res) => {
              store.dispatch(setRequestingTokens(false));
              localStorage.setItem("refreshToken", res.data.refresh);
              store.dispatch(setAccessToken(res.data.access));
              // Change Authorization header
              httpClient.defaults.headers.common["Authorization"] =
                `Bearer ${res.data.access}`;
              // return originalRequest object with Axios.
              return httpClient(originalRequest);
            })
            .catch(() => {
              store.dispatch(setRequestingTokens(false));
              localStorage.setItem(
                "nextRoute",
                `${window.location.pathname}${getUrlQuerysection()}`,
              );
              logoutHandler();
            });
        }
        localStorage.setItem(
          "nextRoute",
          `${window.location.pathname}${getUrlQuerysection()}`,
        );

        logoutHandler();
      }

      if (error?.response?.status === 403) {
        if (error?.response?.data?.error_type === "approval_required") {
          const issueData = error?.response?.data;
          const originalRequest = error.config;
          try {
            await awaitApprovalCodeEntry({ originalRequest, issueData });
          } catch {
            return Promise.reject(error);
          }

          return httpClient(originalRequest);
        } else {
          store.dispatch(
            setSubscriptionAuthorizationError({
              message: error?.response?.data?.detail,
            }),
          );
        }
      }

      if (error?.response?.status === 400) {
        const { url } = error?.response?.config || {};
        if (url?.endsWith("/view-storefront-item/")) {
          window.location.replace(
            `${window.location.origin}/storefront-not-found`,
          );
        } else {
          store.dispatch(
            setApiErrorMessage({ message: error?.response?.data?.detail }),
          );

          if (error?.response?.data?.error_type === "invalid_auth_pin") {
            const originalRequest = error.config;
            try {
              await awaitApprovalCodeEntry({
                originalRequest,
                updateIssueData: false,
              });
            } catch {
              return Promise.reject(error);
            }

            return httpClient(originalRequest);
          }
        }
      }

      if (error?.response?.status === 422) {
        const errors = error?.response?.data?.errors;
        if (Object.keys(errors)?.length > 0) {
          const errorMessage = get422errorMessage(errors);
          store.dispatch(setApiErrorMessage({ message: errorMessage }));
        } else {
          store.dispatch(
            setApiErrorMessage({ message: error?.response?.data?.detail }),
          );
        }
      }

      if (error?.response?.status === 500)
        store.dispatch(
          setApiErrorMessage({
            message: "Something went wrong, please try again",
          }),
        );

      if (error?.response?.status === 503)
        store.dispatch(setApiErrorMessage({ message: "Service unavailable" }));

      if (error?.response?.status === 404)
        store.dispatch(
          setApiErrorMessage({ message: "This resource was not found" }),
        );
    }
  }

  if (!navigator.onLine) {
    store.dispatch(
      setApiErrorMessage({
        message:
          "No internet connection, please check your internet connectivity",
      }),
    );
  }

  return Promise.reject(error);
};

/** Adding the request interceptors */
httpClient.interceptors.request.use(httpRequest);
/** Adding the response interceptors */
httpClient.interceptors.response.use(onFulfilled, onRejected);

export default httpClient;
