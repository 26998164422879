import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const arrayFieldSchema = (conditionKey) =>
  yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string().required("Label is required"),
        value: yup
          .number()
          .integer("Value must be an integer")
          .required("Value is required"),
      }),
    )
    .when(conditionKey, {
      is: true,
      then: yup
        .array()
        .min(1, "Minimum of one role is required")
        .required("Minimum of one role is required"),
      otherwise: yup.array().notRequired(),
    });

const schema = yup.object().shape(
  {
    storeName: yup.string().required("Store Name is required"),
    storeEmail: yup
      .string()
      .email("Invalid email address")
      .max(50, "Maximum of 50 characters is allowed"),
    storeAddress: yup.string().required("Store address is required"),
    storeCategory: yup.string().required("Please select your store category"),
    increaseBills: arrayFieldSchema("allowIncrementQty"),
    reduceBills: arrayFieldSchema("allowReduceQty"),
    deleteBills: arrayFieldSchema("allowDeleteBills"),
    creditPayment: arrayFieldSchema("allowCreditPayment"),
    publicRelation: arrayFieldSchema("allowPublicRelation"),
    deleteBillOrder: arrayFieldSchema("allowDeleteBillOrder"),
    discounting: arrayFieldSchema("allowDiscount"),
    loyalty: arrayFieldSchema("allowLoyalty"),
    giftcard: arrayFieldSchema("allowDelivery"),
    delivery: arrayFieldSchema("allowDelivery"),
    creditRepayment: arrayFieldSchema("allowCreditRepayment"),
    addCreditDebt: arrayFieldSchema("allowAddCreditDebt"),
    addDocketPrint: arrayFieldSchema("allowDocketPrint"),
    addCustomPricing: arrayFieldSchema("allowCustomPricing"),
    orderReversalWindow: yup
      .number()
      .min(0.01, "Minimum value is 0.01")
      .nullable()
      .transform((value, originalValue) =>
        originalValue === "" ? undefined : value,
      )
      .test("max-decimal", "Decimal value cannot exceed .59", (value) => {
        if (!value) return true;
        const decimalPart = value - Math.floor(value);
        return decimalPart <= 0.59;
      }),
    storePhone: yup
      .string()
      .notRequired()
      .when("storePhone", {
        is: (val) => val?.length,
        then: yup
          .string()
          .matches(/^[0-9]+$/, "Phone number must contain only digits")
          .test(
            "num",
            "Invalid phone number format",
            (val) => !val.startsWith("0"),
          )
          .min(10, "Invalid phone number format")
          .max(10, "Invalid phone number format"),
      }),
  },
  ["storePhone", "storePhone"],
);

const Validation = ({ storeDetails, isCheckboxChecked }) => {
  return useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      storeName: storeDetails?.name,
      storeAddress: storeDetails?.address,
      storeCategory: storeDetails?.store_category,
      storePhone: storeDetails?.phone_number,
      storeEmail: storeDetails?.email,
      orderReversalWindow: storeDetails?.order_reversal_window,
      increaseBills: storeDetails?.increase_bill_qty_requires_pin_from_roles,
      reduceBills: storeDetails?.reduce_bill_qty_requires_pin_from_roles,
      deleteBills: storeDetails?.delete_bill_item_requires_pin_from_roles,
      creditPayment: storeDetails?.credit_sales_requires_pin_from_roles,
      publicRelation: storeDetails?.pr_sales_requires_pin_from_roles,
      deleteBillOrder: storeDetails?.delete_bill_order_requires_pin_from_roles,
      discounting: storeDetails?.order_discount_requires_pin_from_roles,
      loyalty: storeDetails?.order_loyalty_requires_pin_from_roles,
      giftcard: storeDetails?.order_giftcard_requires_pin_from_roles,
      delivery: storeDetails?.order_delivery_fee_requires_pin_from_roles,
      creditRepayment: storeDetails?.credit_repayment_requires_pin_from_roles,
      addCreditDebt: storeDetails?.add_credit_debt_requires_pin_from_roles,
      addDocketPrint: storeDetails?.reprint_docket_requires_pin_from_roles,
      addCustomPricing:
        storeDetails?.order_custom_pricing_requires_pin_from_roles,
      allowIncrementQty: isCheckboxChecked?.allow_increment_qty || false,
      allowReduceQty: isCheckboxChecked?.allow_reduce_qty || false,
      allowDeleteBills: isCheckboxChecked?.allow_delete_qty || false,
      allowCreditPayment: isCheckboxChecked?.allow_credit_payment || false,
      allowPublicRelation: isCheckboxChecked?.allow_public_relation || false,
      allowDeleteBillOrder: isCheckboxChecked?.allow_delete_bill_order || false,
      allowDiscount: isCheckboxChecked?.allow_discount || false,
      allowLoyalty: isCheckboxChecked?.allow_loyalty || false,
      allowGiftCard: isCheckboxChecked?.allow_giftcard || false,
      allowDelivery: isCheckboxChecked?.allow_delivery || false,
      allowCreditRepayment: isCheckboxChecked?.allow_credit_repayment || false,
      allowAddCreditDebt: isCheckboxChecked?.allow_add_credit_debt || false,
      allowDocketPrint: isCheckboxChecked?.allow_print_docket || false,
      allowCustomPricing: isCheckboxChecked?.allow_custom_price || false,
    },
  });
};

export default Validation;
