import { useOutletContext } from "react-router-dom";
import Icon from "../../../../../../../../../assets/icons";
import primaryComponents from "../../../../../../../../../components/primaryComponents";
import secondaryComponents from "../../../../../../../../../components/secondaryComponents";
import { cleanObject } from "../../../../../../../../../helpers";
import styles from "./styles.module.scss";
import { useBranding } from "./useBranding";
import { ColorPicker } from "react-color-palette";
import "react-color-palette/css";

const Branding = () => {
  const { menuProperties } = useOutletContext();
  const {
    isLoadingStorefrontColor,
    menuId,
    menu_id,
    fileRef,
    bannerRef,
    color,
    showColorPalette,
    primaryColor,
    secondaryColor,
    selectedLogo,
    selectedBanner,
    logoUrl,
    bannerUrl,
    triggerToast,
    isEditing,
    isLoadingUpload,
    isLoadingUploadBanner,
    setIsEditing,
    setColor,
    setShowColorPalette,
    setPrimaryColor,
    setSecondaryColor,
    setSelectedLogo,
    setSelectedBanner,
    mutateStorefrontColor,
    mutateUploadLogo,
    mutateUploadBanner,
  } = useBranding({ menuProperties });

  const handleUploadPic = (event, type) => {
    if (event?.target?.files) {
      const file = event.target.files[0];
      const reader = new FileReader();

      if (file) {
        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;

          img.onload = () => {
            // Set the image dimensions in the state
            const formData = new FormData();
            formData.append("file", file);

            if (type === "logo") {
              if (img.width > 120 || img.height > 80) {
                triggerToast(
                  "Please ensure your image dimension is within the range of 120px by 80px",
                  "warning",
                );
              } else {
                mutateUploadLogo(formData);

                setSelectedLogo({
                  name: file.name,
                  type: file.type,
                  preview: reader.result,
                });
              }
            } else {
              mutateUploadBanner(formData);

              setSelectedBanner({
                name: file.name,
                type: file.type,
                preview: reader.result,
              });
            }
          };
        };

        reader.readAsDataURL(file);
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.brandHeading}>
        <div className={styles.brandItem}>
          <img src={Icon.brand} alt="" />
          <div>
            <h5>Brand Your Storefront</h5>
            <p>Edit your storefront to fit your brand identity.</p>
          </div>
        </div>
        <primaryComponents.Button
          classNames="btn btn--primary"
          onClick={() => {
            if (isEditing) {
              mutateStorefrontColor(
                cleanObject({
                  storefront_id: menuId ? menuId : menu_id,
                  primary_colour: primaryColor,
                  secondary_colour: secondaryColor,
                  logo_url: logoUrl,
                  banner_url: bannerUrl,
                }),
              );
            } else {
              setIsEditing(true);
            }
          }}
          loadingText={"Saving..."}
          isLoading={isLoadingStorefrontColor}
          isDisabled={
            isLoadingStorefrontColor || isLoadingUpload || isLoadingUploadBanner
          }
        >
          <span>{isEditing ? "Save" : "Edit"}</span>
        </primaryComponents.Button>
      </div>
      {isEditing && (
        <div className={styles.brandContainer}>
          <div className={styles.brandLogo}>
            <div className={styles.logoHeading}>
              <h6>Brand Logo & Banner Image</h6>
            </div>
            <div className={styles.bannerContainer}>
              <div
                className={styles.iconContainer}
                onClick={() => bannerRef.current?.click()}
              >
                <input
                  ref={bannerRef}
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={(event) => handleUploadPic(event, "banner")}
                  multiple={false}
                />
                <div className={styles.uploadContainer}>
                  <img
                    src={Icon.uploadLogo}
                    height={32}
                    width={16}
                    alt="logo"
                  />
                  <p>
                    {isLoadingUploadBanner
                      ? "Uploading..."
                      : "Upload Banner Image"}
                  </p>
                </div>
                <span style={{ color: "#E02020", fontSize: 12 }}>
                  (Recommended size: 1500px by 500px)
                </span>
              </div>
              <div className={styles.bannerFrame}>
                {bannerUrl ? (
                  <img
                    className={styles.logo}
                    src={bannerUrl}
                    style={{ objectFit: "cover" }}
                    alt="banner"
                  />
                ) : selectedBanner ? (
                  <img
                    className={styles.logo}
                    src={selectedBanner?.preview}
                    style={{ objectFit: "cover" }}
                    alt="banner"
                  />
                ) : (
                  <h6>BANNER SECTION</h6>
                )}
                <div
                  onClick={() => fileRef.current?.click()}
                  className={styles.logoContainer}
                >
                  <div className={styles.logoSection}>
                    {logoUrl ? (
                      <img className={styles.logo} src={logoUrl} alt="logo" />
                    ) : selectedLogo ? (
                      <img
                        className={styles.logo}
                        src={selectedLogo?.preview}
                        alt="logo"
                      />
                    ) : (
                      <p>LOGO SECTION</p>
                    )}
                  </div>
                  <div className={styles.iconContainer}>
                    <input
                      ref={fileRef}
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={(event) => handleUploadPic(event, "logo")}
                      multiple={false}
                    />
                    <div className={styles.uploadItem}>
                      <img
                        src={Icon.uploadLogo}
                        height={32}
                        width={16}
                        alt="logo"
                      />
                      <p>{isLoadingUpload ? "Uplaoding..." : "Upload Logo"}</p>
                    </div>
                    <span style={{ color: "#E02020", fontSize: 12 }}>
                      (Size: 120px by 80px)
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.colorPallete}>
            <div className={styles.colorHeading}>
              <h6>Edit Storefront Color Palette</h6>
            </div>
            <div className={styles.colorContainer}>
              <div
                onClick={() => setShowColorPalette("Primary")}
                className={styles.colorItem}
              >
                <div className={styles.textContainer}>
                  <p>Primary Color</p>
                  <span>
                    Colors of Header, Buttons and Prices will be updated
                  </span>
                </div>
                <div>
                  <div
                    style={{ background: primaryColor }}
                    className={styles.colorCircle}
                  />
                </div>
              </div>
              <div
                onClick={() => setShowColorPalette("Secondary")}
                className={styles.colorItem}
              >
                <div className={styles.textContainer}>
                  <p>Secondary Color</p>
                  <span>Color of categorized section will be updated</span>
                </div>
                <div>
                  <div
                    style={{ background: secondaryColor }}
                    className={styles.colorCircle}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <secondaryComponents.Modal
        width={"686px"}
        isActive={showColorPalette ? true : false}
        closeModal={() => setShowColorPalette(null)}
      >
        <div className={styles.modalContainer}>
          <div className={styles.colorPaletteContainer}>
            <div className={styles.heading}>
              <p>
                {showColorPalette === "Primary"
                  ? "Primary Color"
                  : "Secondary Color"}
              </p>
              <span>
                Select Your{" "}
                {showColorPalette === "Primary" ? "Primary" : "Secondary"} Color
              </span>
            </div>
            <div>
              <ColorPicker color={color} onChange={setColor} />
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <primaryComponents.Button
              classNames="btn btn--outline"
              onClick={() => setShowColorPalette(null)}
            >
              <span>Back</span>
            </primaryComponents.Button>
            <primaryComponents.Button
              classNames="btn btn--primary"
              onClick={() => {
                showColorPalette === "Primary"
                  ? setPrimaryColor(color.hex)
                  : setSecondaryColor(color.hex);

                setShowColorPalette(null);
              }}
            >
              <span>Save</span>
            </primaryComponents.Button>
          </div>
        </div>
      </secondaryComponents.Modal>
    </div>
  );
};

export default Branding;
