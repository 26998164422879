import styles from "./PrintOnDevice.module.scss";
import "./PrintOnDevice.scss";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";
import primaryComponents from "../../../primaryComponents";
import useTransactionDetails from "../TransactionDetailsV2/useTransactionDetails";
import React, { useState } from "react";
import { useGetSectionItems } from "../../../../hooks/api/queries/useSection";
import secondaryComponents from "../..";
import { SectionView } from "../../../printViews";
import { motion } from "framer-motion";
import PrintSelect from "../PrintSelect";

const PrintOnDevice = ({
  orderId,
  viaPos = false,
  setSectionIdExternal = () => {},
  resetView = () => {},
  billWasUpdated = false,
}) => {
  const { isLoading: isPrinting, printReceipt } = useTransactionDetails({
    orderId,
  });
  const {
    isLoading: isFetchingSectionItems,
    data: sectionItems,
    refetch,
  } = useGetSectionItems({
    queries: `?order_id=${orderId}`,
  });
  const [sectionId, setSectionId] = useState(false);
  const [isPrintModal, setIsPrintModal] = useState(false);
  const DocketOptions = [
    { name: "All Dockets", id: "ALL_DOCKETS" },
    { name: "New Dockets", id: "NEW_DOCKETS" },
    { name: "Printed Dockets", id: "PRINTED_DOCKETS" },
  ];
  const [selectedDocket, setSelectedDocket] = useState("NEW_DOCKETS");

  return (
    <>
      <motion.div
        initial={{ y: 100 }}
        animate={{ y: 0 }}
        className={`${styles.printOnDevice} printOnDevice`}
      >
        <div className={globalStyles.cardWithBorder}>
          <h4>Receipt</h4>

          <primaryComponents.Button
            onClick={() => {
              if (viaPos) {
                resetView("isTerminal");
                setSectionIdExternal(null);
              } else {
                if (!billWasUpdated) printReceipt();
                else setIsPrintModal(true);
              }
            }}
            isDisabled={isPrinting}
            isLoading={isPrinting}
            loadingText="Proccessing..."
            classNames={"btn btn--primary"}
          >
            Print Receipt
          </primaryComponents.Button>
        </div>

        <div className={globalStyles.cardWithBorder}>
          <div
            className="d-flex justify-content-between"
            style={{ gap: "20px" }}
          >
            <h4>Dockets</h4>
            <div style={{ width: "170px" }}>
              <primaryComponents.SelectField
                options={DocketOptions}
                value={
                  DocketOptions?.find(
                    (docketItem) => docketItem?.id === selectedDocket,
                  )?.name
                }
                idKey={"id"}
                nameKey={"name"}
                onChange={(val) => setSelectedDocket(val)}
              />
            </div>
          </div>
          <secondaryComponents.LoaderHelper
            isLoading={isFetchingSectionItems}
            classNames={"mt-4"}
          >
            <div className={styles.printOnDevice__items}>
              {sectionItems?.map((sectionItem) => (
                <React.Fragment key={sectionItem?.id}>
                  {selectedDocket === "ALL_DOCKETS" ||
                  (selectedDocket === "PRINTED_DOCKETS" &&
                    sectionItem?.is_printed) ||
                  (selectedDocket === "NEW_DOCKETS" &&
                    !sectionItem?.is_printed) ? (
                    <div
                      className={`${styles.printOnDevice__items__card} ${
                        sectionItem?.is_printed ? styles.isPrinted : ""
                      }`}
                    >
                      <div className={styles.cardTop}>
                        <h5 className="mb-0">
                          {sectionItem?.section?.name}{" "}
                          {!sectionItem?.is_printed ? (
                            <span
                              className="ml-2 failed--text small-text-size"
                              style={{
                                borderRadius: "3px",
                                padding: "4px 10px",
                              }}
                            >
                              New Docket
                            </span>
                          ) : (
                            ""
                          )}
                        </h5>

                        <p className="small-text-size">
                          {new Date(sectionItem?.created_at).toLocaleString(
                            "en-us",
                          )}
                        </p>
                      </div>
                      <div
                        className="d-flex justify-content-between"
                        style={{ gap: "30px" }}
                      >
                        <p className="small-text-size">
                          {sectionItem?.docket_items?.map((item, index) => (
                            <React.Fragment key={item?.id}>
                              {item?.quantity} x{" "}
                              <span className="dark--text">
                                {item?.cart_item?.cart_item_name}
                              </span>
                              {index === sectionItem?.docket_items?.length - 1
                                ? ""
                                : ", "}
                            </React.Fragment>
                          ))}
                          <br />
                          {sectionItem?.docket_items?.find(
                            (item) => item.has_reduced,
                          ) ? (
                            <span
                              className="ml-1 failed--text smallest-text-size"
                              style={{ padding: "5px 10px" }}
                            >
                              QTY Updated
                            </span>
                          ) : null}
                        </p>

                        <primaryComponents.Button
                          classNames={`btn ml-auto ${
                            sectionItem?.is_printed
                              ? "btn--outline"
                              : "btn--primary"
                          }`}
                          style={{ minWidth: "150px", maxWidth: "200px" }}
                          onClick={() => {
                            if (viaPos) {
                              setSectionIdExternal(sectionItem?.id);
                              resetView("isTerminal");
                            } else setSectionId(sectionItem?.id);
                          }}
                        >
                          Print
                        </primaryComponents.Button>
                      </div>
                    </div>
                  ) : null}
                </React.Fragment>
              ))}
            </div>
          </secondaryComponents.LoaderHelper>
        </div>
      </motion.div>

      {/* print view */}
      {sectionId ? (
        <SectionView
          section_id={sectionId}
          allowPrint={sectionId ? true : false}
          setAllowPrint={() => setSectionId(null)}
          isDocket={true}
          invalidateQueries={() => {
            refetch();
          }}
        />
      ) : null}

      <secondaryComponents.Modal
        isActive={isPrintModal}
        closeModal={() => setIsPrintModal(false)}
        width={500}
      >
        <PrintSelect
          setIsPrintModal={setIsPrintModal}
          printReceipt={printReceipt}
        />
      </secondaryComponents.Modal>
    </>
  );
};
export default PrintOnDevice;
