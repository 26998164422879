/* eslint-disable jsx-a11y/anchor-is-valid */
import { useGetCreditLogTransaction } from "../../../hooks/api/queries";
import secondaryComponents from "../../secondaryComponents";
import styles from "./TransactionDetailsV2.module.scss";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatCurrency } from "../../../helpers";
import { useSelector } from "react-redux";

const CreditLogTransaction = ({ orderId, closeModal = () => {}, refsId }) => {
  const { isLoading, data: creditDetails } = useGetCreditLogTransaction(refsId);
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  return (
    <>
      <motion.div
        initial={{ y: 100 }}
        animate={{ y: 0 }}
        className={styles.transactionDetails}
      >
        <FontAwesomeIcon
          onClick={closeModal}
          className="d-block ml-auto"
          icon="times"
          style={{ fontSize: "25px" }}
        />
        <secondaryComponents.LoaderHelper
          classNames={"mt-5"}
          isLoading={isLoading}
        >
          <div
            className="d-flex justify-content-between align-items-center mt-3 mb-2"
            style={{ gap: "15px" }}
          >
            <h5 className="mb-0">Credit Log Transaction Details</h5>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <p> Date:</p>
            <p className="text-right dark--text">
              {new Date(creditDetails?.created_at).toLocaleString("en-us")}
            </p>
          </div>

          <div className="d-flex justify-content-between mb-2">
            <p>Transaction Reference:</p>
            <p className="text-right dark--text">
              {creditDetails?.reference || "N/A"}
            </p>
          </div>
          {creditDetails?.order_id ? (
            <div
              className="d-flex justify-content-between mb-2"
              onClick={() => {
                orderId(creditDetails?.order_id);
                closeModal();
              }}
              style={{ cursor: "pointer" }}
            >
              <p>Order ID:</p>
              <p className="text-right purple--text">
                {creditDetails?.order_id || "N/A"}
              </p>
            </div>
          ) : null}
          <div className="d-flex justify-content-between mb-2">
            <p>Repayment Method:</p>
            <p className="text-right dark--text">
              {creditDetails?.repayment_method || "N/A"}
            </p>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <p>Amount:</p>
            <p className="text-right dark--text">
              {formatCurrency({
                value: creditDetails?.amount,
                currencyCode: storeList?.find(
                  (storeItem) =>
                    `${storeItem?.id}` === `${creditDetails?.store_id}`,
                )?.currency_code,
              }) || "N/A"}
            </p>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <p>Description:</p>
            <p className="text-right dark--text">
              {creditDetails?.type || "N/A"}
            </p>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <p>Cashier:</p>
            <p className="text-right dark--text">
              {creditDetails?.cashier_name || "N/A"}
            </p>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <p>Closing Outstanding Balance:</p>
            <p className="text-right dark--text">
              {formatCurrency({
                value:
                  creditDetails?.closing_outstanding_balance <= 0
                    ? Math.abs(creditDetails?.closing_outstanding_balance)
                    : -Math.abs(creditDetails?.closing_outstanding_balance),
                currencyCode: storeList?.find(
                  (storeItem) =>
                    `${storeItem?.id}` === `${creditDetails?.store_id}`,
                )?.currency_code,
              }) || "N/A"}
            </p>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <p>Opening Outstanding Balance:</p>
            <p className="text-right dark--text">
              {formatCurrency({
                value:
                  creditDetails?.closing_outstanding_balance <= 0
                    ? Math.abs(creditDetails?.opening_outstanding_balance)
                    : -Math.abs(creditDetails?.opening_outstanding_balance),
                currencyCode: storeList?.find(
                  (storeItem) =>
                    `${storeItem?.id}` === `${creditDetails?.store_id}`,
                )?.currency_code,
              }) || "N/A"}
            </p>
          </div>
          {creditDetails?.type !== "payment" ? (
            <div className="d-flex justify-content-between mb-2">
              <p>Expected Repayment Date:</p>
              <p className="text-right dark--text">
                {new Date(
                  creditDetails?.expected_repayment_date,
                ).toLocaleDateString("en-us", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }) || "N/A"}
              </p>
            </div>
          ) : null}
          <div className="d-flex justify-content-between mb-2">
            <p>Store Name:</p>
            <p className="text-right dark--text">
              {creditDetails?.store_name || "N/A"}
            </p>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <p>Note:</p>
            <p className="text-right dark--text">
              {creditDetails?.note || "N/A"}
            </p>
          </div>
        </secondaryComponents.LoaderHelper>
      </motion.div>
    </>
  );
};

export default CreditLogTransaction;
