import { useMutation } from "react-query";
import { orderMenuCategoryList } from "../../../../../../../../../services/inventory";
import { useContext, useState } from "react";
import { ToastContext } from "../../../../../../../../../hooks/context";
import { useParams, useSearchParams } from "react-router-dom";

export const useSortCategories = () => {
  const { menuId } = useParams();
  const triggerToast = useContext(ToastContext);
  const [searchParams] = useSearchParams();
  const menu_id = searchParams.get("menu_id");

  const [isEditing, setIsEditing] = useState(false);

  // useEffect(() => {
  //   queryClient.invalidateQueries(["getMenusDetails"]);
  // }, [queryClient]);

  const { isLoading: isLoadingSortCategories, mutate: mutateSortCategories } =
    useMutation({
      mutationFn: (data) =>
        orderMenuCategoryList({ id: menuId ? menuId : menu_id, data }),
      onSuccess: () => {
        triggerToast("Categories sorted successfully", "success");
        setIsEditing((prev) => !prev);
      },
      onError: (error) => {
        triggerToast(`${error.response.data.message}`, "warning");
      },
    });

  return {
    isLoadingSortCategories,
    isEditing,
    setIsEditing,
    mutateSortCategories,
  };
};
