import styles from "./SectionView.module.scss";
import { truncateString } from "../../../helpers";
import useSectionView from "./useSectionView";
import { MobilePrint } from "./MobilePrint";

export const SectionView = ({
  section_id,
  allowPrint = false,
  setAllowPrint,
  isDocket = false,
  invalidateQueries = () => {},
}) => {
  const {
    isMobile,
    business_name,
    address,
    sectionDetails,
    sectionPrintComponent,
    clearAfterPrint,
  } = useSectionView({
    section_id,
    allowPrint,
    setAllowPrint,
    isDocket,
    invalidateQueries,
  });

  return (
    <>
      <div className="d-none">
        <div>
          <div className={styles.sectionView} ref={sectionPrintComponent}>
            <div className="text-center mb-2">
              <h6>{truncateString(business_name, 150)}</h6>
              <h6 className="dark--text">{truncateString(address, 150)}</h6>
            </div>

            <div className={styles.sectionView__item}>
              <h6 className="small-text-size">Queue Number:</h6>
              <h4 className="mb-0">
                {sectionDetails?.order?.queue_number || "N/A"}
              </h4>
            </div>
            <div className={styles.sectionView__item}>
              <h6 className="small-text-size">Order Date:</h6>
              <h6>
                {new Date(sectionDetails?.created_at).toLocaleString("en-us")}
              </h6>
            </div>
            <div className={styles.sectionView__item}>
              <h6 className="small-text-size">Order For:</h6>
              <h6>{sectionDetails?.section?.name}</h6>
            </div>
            <div className={styles.sectionView__item}>
              <h6 className="small-text-size">Order ID:</h6>
              <h6>{sectionDetails?.order?.id}</h6>
            </div>
            <div className={styles.sectionView__item}>
              <h6 className="small-text-size">Table:</h6>
              <h6>{sectionDetails?.order?.table?.name || "N/A"}</h6>
            </div>
            <div className={styles.sectionView__item}>
              <h6 className="small-text-size">Order Type:</h6>
              <h6>{sectionDetails?.order?.order_type?.replace("_", " ")}</h6>
            </div>
            <div className={styles.sectionView__item}>
              <h6 className="small-text-size">Cashier Name:</h6>
              <h6>{sectionDetails?.order?.initiator?.first_name || "N/A"}</h6>
            </div>
            <div className={styles.sectionView__item}>
              <h6 className="small-text-size">Customer Name:</h6>
              <h6>{sectionDetails?.order?.customer?.full_name || "N/A"}</h6>
            </div>
            <div className={styles.sectionView__item}>
              <h6 className="small-text-size">Order:</h6>
              <h6>
                {sectionDetails?.docket_items?.map((item, index) => (
                  <span key={item?.id}>
                    {" "}
                    {item?.quantity} x{" "}
                    <span className="dark--text">
                      {item?.cart_item?.cart_item_name}
                    </span>
                    {index === sectionDetails?.docket_items?.length - 1
                      ? ""
                      : ","}
                  </span>
                ))}
              </h6>
            </div>

            <div>
              <h6 className="small-text-size">Notes:</h6>
              <h6>{sectionDetails?.order?.notes || "N/A"}</h6>
            </div>
          </div>
        </div>
      </div>

      {isMobile && sectionDetails && allowPrint ? (
        <MobilePrint
          address={address}
          business_name={business_name}
          sectionDetails={sectionDetails}
          clearAfterPrint={clearAfterPrint}
        />
      ) : null}
    </>
  );
};
