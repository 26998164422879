import React, { useContext } from "react";
import { pathConstants } from "./pathContants";
import appPages from "../pages/appPages";
import { getPlatform } from "../helpers";
import { platformHandler } from "../hooks/context";
import layouts from "../layouts";
import authPages from "../pages/authPages";
import storePages from "../pages/storePages";
import secondaryComponents from "../components/secondaryComponents";
import publicPages from "../pages/publicPages";
import { Navigate, Outlet } from "react-router-dom";
import RoutePermissionRedirector from "../components/secondaryComponents/RoutePermissionRedirector";
import Error404 from "../pages/ErrorPages/Error404";
import SectionPages from "../pages/sectionPages";
import PushNotifications from "../components/secondaryComponents/PushNotifications";
import { ClientLogin, ExistingClientConnect } from "../pages/HelperPages";

const useGetRoutes = () => {
  const { platform } = useContext(platformHandler);
  const baseRoute = getPlatform(platform);

  const routes = [
    {
      path: "/",
      element: <Navigate to={pathConstants.LOGIN} replace />,
    },

    // auth
    {
      element: <secondaryComponents.OnboardingGuard />,
      children: [
        {
          path: pathConstants.SIGNUP,
          element: <authPages.Signup />,
        },
        {
          path: pathConstants.LOGIN,
          element: <authPages.Login />,
        },
        {
          path: pathConstants.LOGIN_VERIFICATION,
          element: <authPages.LoginOtpVerification />,
        },
        {
          path: pathConstants.FORGOT_PASSOWRD,
          element: <authPages.ForgotPassword />,
        },
        {
          path: pathConstants.RESET_PASSWORD,
          element: <authPages.ResetPassword />,
        },
        {
          path: pathConstants.EMAIL_VERIFICATION,
          element: <authPages.EmailVerification />,
        },
        {
          path: pathConstants.SIGNUP_INVITE,
          element: <authPages.InviteSignup />,
        },
      ],
    },

    // public pages
    {
      path: pathConstants.PLATFORM_NAVIGATOR,
      element: <secondaryComponents.PlatformNavigator />,
    },
    {
      path: pathConstants.SUBSCRIPTION_PLAN,
      element: <publicPages.SubscriptionView />,
    },
    {
      element: <layouts.MenuPageLayout />,
      children: [
        {
          path: pathConstants.MENU({ id: ":id", path: "/*" }),
          element: <publicPages.MenuPage />,
        },
      ],
    },
    {
      path: pathConstants.INVENTORY_STOREFRONT_RIDER_ORDERS({ id: ":id" }),
      element: <publicPages.AllRiderOrders />,
    },
    {
      path: pathConstants.INVENTORY_STOREFRONT_ORDER_DETAILS({ id: ":id" }),
      element: <publicPages.RiderOrderDetails />,
    },
    {
      path: pathConstants.INVENTORY_STOREFRONT_ORDER_STATUS({
        id: ":id",
        menuId: ":menuId",
      }),
      element: <publicPages.OrderStatusTrack />,
    },

    // app routes
    {
      element: (
        <PushNotifications>
          {baseRoute === "/app" ? (
            <layouts.AppLayout>
              <secondaryComponents.AppGuard />
            </layouts.AppLayout>
          ) : (
            <layouts.MobileAppLayout>
              <secondaryComponents.AppGuard />
            </layouts.MobileAppLayout>
          )}
        </PushNotifications>
      ),
      children: [
        {
          path: pathConstants.DASHBOARD_MAIN,
          element: <appPages.MainDashboard />,
        },
        {
          element: (
            <secondaryComponents.CountryChecker>
              <RoutePermissionRedirector
                permissionParentKey={"Payments"}
                permissionChildKey={
                  "can_view_payment_dashboard_overview_cash_and_customer_analytics"
                }
                redirectPath={pathConstants.PAYMENT_TRANSACTIONS_ALL}
              >
                <layouts.DashboardLayout />
              </RoutePermissionRedirector>
            </secondaryComponents.CountryChecker>
          ),
          children: [
            {
              path: pathConstants.DASHBOARD_OVERVIEW,
              element: <appPages.Overview />,
            },
            {
              path: pathConstants.DASHBOARD_CASHFLOW_ANALYTICS,
              element: <appPages.CashflowAnalytics />,
            },
            {
              path: pathConstants.DASHBOARD_CUSTOMER_ANALYTICS,
              element: <appPages.CustomerAnalytics />,
            },
          ],
        },
        {
          element: (
            <secondaryComponents.CountryChecker>
              <RoutePermissionRedirector
                permissionParentKey={"Accounting"}
                permissionChildKey={"can_allow_ledger_account"}
              >
                <layouts.AccountingLayout beta={true} />
              </RoutePermissionRedirector>
            </secondaryComponents.CountryChecker>
          ),
          children: [
            {
              path: pathConstants.BANK_ACCOUNTINGS,
              element: <appPages.Bank />,
            },
            {
              path: pathConstants.ACCOUNTINGS_TRANSACTION,
              element: <appPages.Transaction />,
            },
            {
              path: pathConstants.CASH_ACCOUNTINGS,
              element: <appPages.AccountingCashOnHand />,
            },
          ],
        },
        {
          element: (
            <secondaryComponents.CountryChecker>
              <RoutePermissionRedirector
                permissionParentKey={"Payments"}
                redirectPath={pathConstants.PAYMENT_SUBACCOUNTS}
              >
                <layouts.TransfersLayout />
              </RoutePermissionRedirector>
            </secondaryComponents.CountryChecker>
          ),
          children: [
            {
              element: (
                <RoutePermissionRedirector
                  permissionParentKey={"Payments"}
                  permissionChildKey={"can_view_transfer"}
                  redirectPath={pathConstants.PAYMENT_SUBACCOUNTS}
                >
                  <appPages.AllTransfers />
                </RoutePermissionRedirector>
              ),
              children: [
                {
                  path: pathConstants.PAYMENT_TRANSFER_APPROVED,
                  element: <appPages.ApprovedTransfers />,
                },
                {
                  path: pathConstants.PAYMENT_TRANSFER_PENDING,
                  element: <appPages.PendingTransfers />,
                },
                {
                  path: pathConstants.PAYMENT_TRANSFER_REJECTED,
                  element: <appPages.RejectedTransfers />,
                },
                {
                  path: pathConstants.PAYMENT_TRANSFER_SAVEDBENEFICIARY,
                  element: <appPages.SavedBeneficiaries />,
                },
              ],
            },
            {
              path: pathConstants.PAYMENT_TRANSFER_DETAILS({
                transferId: ":transferId",
              }),
              element: <appPages.TransferDetails />,
            },
          ],
        },
        {
          element: (
            <secondaryComponents.CountryChecker>
              <RoutePermissionRedirector
                permissionParentKey={"Payments"}
                permissionChildKey={"can_view_transactions_history"}
                redirectPath={pathConstants.PAYMENT_SUBACCOUNTS}
              >
                <layouts.TransactionsLayout />
              </RoutePermissionRedirector>
            </secondaryComponents.CountryChecker>
          ),
          children: [
            {
              path: pathConstants.PAYMENT_TRANSACTIONS_ALL,
              element: <appPages.AllTransactions />,
            },
            {
              path: pathConstants.PAYMENT_TRANSACTIONS_DETAILS({
                id: ":transactionId",
              }),
              element: <appPages.TransactionDetails />,
            },
          ],
        },
        {
          element: (
            <secondaryComponents.CountryChecker>
              <RoutePermissionRedirector permissionParentKey={"Payments"}>
                <layouts.SubAccountLayout />
              </RoutePermissionRedirector>
            </secondaryComponents.CountryChecker>
          ),
          children: [
            {
              path: pathConstants.PAYMENT_SUBACCOUNTS,
              element: <appPages.AllSubAccounts />,
            },
            {
              path: pathConstants.PAYMENT_SUBACCOUNTS_CREATE,
              element: <appPages.CreateSubAccount />,
            },
          ],
        },
        {
          element: (
            <secondaryComponents.CountryChecker>
              <RoutePermissionRedirector
                permissionParentKey={"Payments"}
                permissionChildKey="can_view_and_request_pos"
                redirectPath={pathConstants.PAYMENT_SUBACCOUNTS}
              >
                <layouts.TerminalsLayout />
              </RoutePermissionRedirector>
            </secondaryComponents.CountryChecker>
          ),
          children: [
            {
              element: <appPages.Terminals />,
              children: [
                {
                  path: pathConstants.PAYMENT_POS_ALL_TERMINALS,
                  element: <appPages.AllTerminals />,
                },
                {
                  path: pathConstants.PAYMENT_POS_REQUEST_TERMINAL,
                  element: <appPages.TerminalRequests />,
                },
              ],
            },
            {
              path: pathConstants.PAYMENT_POS_TERMINAL_DETAILS({
                terminalId: ":terminalId",
              }),
              element: <appPages.TerminalDetails />,
            },
            {
              path: pathConstants.PAYMENT_POS_TERMINAL_CREDENTIALS({
                terminalId: ":terminalId",
              }),
              element: <appPages.TerminalCredentials />,
            },
            {
              path: pathConstants.PAYMENT_POS_ADD_TERMINALS,
              element: <appPages.NewTerminal />,
            },
          ],
        },
        {
          element: (
            <secondaryComponents.CountryChecker>
              <RoutePermissionRedirector permissionParentKey={"Payments"}>
                <layouts.TagsLayout />
              </RoutePermissionRedirector>
            </secondaryComponents.CountryChecker>
          ),
          children: [
            {
              path: pathConstants.PAYMENT_ALL_TAGS,
              element: (
                <RoutePermissionRedirector
                  permissionParentKey={"Payments"}
                  permissionChildKey="can_view_tags"
                  redirectPath={pathConstants.PAYMENT_SUBACCOUNTS}
                >
                  <appPages.Tags />
                </RoutePermissionRedirector>
              ),
            },
          ],
        },
        {
          element: (
            <RoutePermissionRedirector
              permissionParentKey={"Inventory Management"}
            >
              <layouts.InventoryLayout isMobileApp={baseRoute === "/mobile"} />
            </RoutePermissionRedirector>
          ),
          children: [
            {
              path: pathConstants.INVENTORY,
              element: (
                <Navigate to={pathConstants.INVENTORY_ANALYTICS} replace />
              ),
            },
            {
              path: pathConstants.INVENTORY_ANALYTICS,
              element: (
                <RoutePermissionRedirector
                  permissionParentKey={"Inventory Management"}
                  permissionChildKey={"can_view_analytics"}
                  redirectPath={pathConstants.INVENTORY_PRODUCTS}
                >
                  <appPages.InventoryAnalyticsPage />
                </RoutePermissionRedirector>
              ),
            },
            {
              path: pathConstants.INVENTORY_PRODUCTS,
              element: (
                <RoutePermissionRedirector
                  permissionParentKey={"Inventory Management"}
                  permissionChildKey="can_view_products"
                  redirectPath={pathConstants.INVENTORY_SALES_TRANSACTIONS}
                >
                  <appPages.AllProducts />
                </RoutePermissionRedirector>
              ),
            },
            {
              path: pathConstants.INVENTORY_PRODUCT_BULK_TRANSFER_HISTORY,
              element: (
                <RoutePermissionRedirector
                  permissionParentKey={"Inventory Management"}
                  permissionChildKey="can_view_stock_transfer"
                  redirectPath={
                    pathConstants.INVENTORY_PRODUCT_BULK_TRANSFER_HISTORY
                  }
                >
                  <appPages.StockTransferHistory />
                </RoutePermissionRedirector>
              ),
            },
            {
              path: pathConstants.INVENTORY_PRODUCT_BULK_TRANSFER,
              element: (
                <RoutePermissionRedirector
                  permissionParentKey={"Inventory Management"}
                  permissionChildKey="can_create_or_update_stock_transfer"
                >
                  <appPages.CreateStockTransfer />
                </RoutePermissionRedirector>
              ),
            },
            {
              path: pathConstants.INVENTORY_STOCK_TRANSFER_EDIT({
                id: ":id",
              }),
              element: (
                <RoutePermissionRedirector
                  permissionParentKey={"Inventory Management"}
                  permissionChildKey="can_create_or_update_stock_transfer"
                >
                  <appPages.EditStockTransfer />
                </RoutePermissionRedirector>
              ),
            },
            {
              path: pathConstants.INVENTORY_CATEGORIES,
              element: (
                <RoutePermissionRedirector
                  permissionParentKey={"Inventory Management"}
                  permissionChildKey="can_view_product_category"
                >
                  <appPages.ProductCategories />
                </RoutePermissionRedirector>
              ),
            },
            {
              path: pathConstants.INVENTORY_SALES_TRANSACTIONS,
              element: (
                <RoutePermissionRedirector
                  permissionParentKey={"Inventory Management"}
                  permissionChildKey="can_view_sales_transaction"
                >
                  <appPages.SalesTransactions />
                </RoutePermissionRedirector>
              ),
            },
            {
              path: pathConstants.INVENTORY_STOCK_HISTORY,
              element: (
                <RoutePermissionRedirector
                  permissionParentKey={"Inventory Management"}
                  permissionChildKey="can_view_stock_history"
                >
                  <appPages.StockHistory />
                </RoutePermissionRedirector>
              ),
            },
            {
              path: pathConstants.INVENTORY_STOCK_TRANSFER_DETAILS({
                id: ":id",
              }),
              element: (
                <RoutePermissionRedirector
                  permissionParentKey={"Inventory Management"}
                  permissionChildKey="can_view_stock_transfer"
                >
                  <appPages.TransferStockDetails />,
                </RoutePermissionRedirector>
              ),
            },
            {
              path: pathConstants.INVENTORY_SUPPLIERS,
              element: (
                <RoutePermissionRedirector
                  permissionParentKey={"Inventory Management"}
                  permissionChildKey="can_view_edit_and_create_suppliers"
                >
                  <appPages.Suppliers />
                </RoutePermissionRedirector>
              ),
            },
            {
              path: pathConstants.INVENTORY_RECONCILIATIONS,
              element: (
                <RoutePermissionRedirector
                  permissionParentKey={"Inventory Management"}
                  permissionChildKey="can_create_and_submit_reconciliation"
                >
                  <appPages.AllReconciliations />
                </RoutePermissionRedirector>
              ),
            },
            {
              path: pathConstants.INVENTORY_PRODUCTS_BATCH,
              element: (
                <RoutePermissionRedirector
                  permissionParentKey={"Inventory Management"}
                  permissionChildKey="can_view_products"
                >
                  <appPages.ProductsBatch />
                </RoutePermissionRedirector>
              ),
            },
            {
              path: pathConstants.INVENTORY_PRODUCTS_BATCH_EDIT,
              element: (
                <RoutePermissionRedirector
                  permissionParentKey={"Inventory Management"}
                  permissionChildKey="can_view_products"
                >
                  <appPages.EditProductsBatch />
                </RoutePermissionRedirector>
              ),
            },
            {
              path: pathConstants.INVENTORY_TABLES,
              element: (
                <RoutePermissionRedirector
                  permissionParentKey={"Inventory Management"}
                  permissionChildKey="can_view_edit_and_create_tables"
                >
                  <appPages.InventoryTables />
                </RoutePermissionRedirector>
              ),
            },
            {
              path: pathConstants.INVENTORY_TABLES_DETAILS,
              element: (
                <RoutePermissionRedirector
                  permissionParentKey={"Inventory Management"}
                  permissionChildKey="can_view_edit_and_create_tables"
                >
                  <appPages.InventoryTableDetails />
                </RoutePermissionRedirector>
              ),
            },
            {
              element: (
                <RoutePermissionRedirector
                  permissionParentKey={"Inventory Management"}
                >
                  <Outlet />
                </RoutePermissionRedirector>
              ),
              children: [
                {
                  path: pathConstants.INVENTORY_STORES,
                  element: (
                    <RoutePermissionRedirector
                      permissionParentKey={"Inventory Management"}
                      permissionChildKey={"can_view_stores"}
                    >
                      <appPages.Stores />
                    </RoutePermissionRedirector>
                  ),
                },
                {
                  path: pathConstants.INVENTORY_STORES_CREATE,
                  element: (
                    <RoutePermissionRedirector
                      permissionParentKey={"Inventory Management"}
                      permissionChildKey={"can_create_stores"}
                    >
                      <appPages.CreateStore />
                    </RoutePermissionRedirector>
                  ),
                },
                {
                  path: pathConstants.INVENTORY_STORE_EDIT({ id: ":id" }),
                  element: (
                    <RoutePermissionRedirector
                      permissionParentKey={"Inventory Management"}
                      permissionChildKey="can_edit_stores"
                    >
                      <appPages.EditStore />
                    </RoutePermissionRedirector>
                  ),
                },
                {
                  path: pathConstants.INVENTORY_STORE_DETIALS,
                  element: (
                    <RoutePermissionRedirector
                      permissionParentKey={"Inventory Management"}
                      permissionChildKey={"can_edit_stores"}
                    >
                      <appPages.EditStoreDetails />
                    </RoutePermissionRedirector>
                  ),
                },
              ],
            },
            {
              path: pathConstants.INVENTORY_CREDITLOG,
              element: (
                <RoutePermissionRedirector
                  permissionParentKey={"Inventory Management"}
                  permissionChildKey="can_view_and_update_credits"
                >
                  <appPages.CreditLog />
                </RoutePermissionRedirector>
              ),
            },
            {
              path: pathConstants.INVENTORY_MENU,
              element: (
                // <RoutePermissionRedirector
                //   permissionParentKey={"Inventory Management"}
                //   permissionChildKey="can_view_edit_and_create_storefront"
                // >
                <appPages.AllFoodMenu />
                // </RoutePermissionRedirector>
              ),
              children: [
                {
                  path: pathConstants.INVENTORY_SHIPPINGS,
                  element: <appPages.AllShippings />,
                },
                {
                  path: pathConstants.INVENTORY_RIDERS,
                  element: <appPages.AllRiders />,
                },
              ],
            },
            {
              path: pathConstants.INVENTORY_STORE_WALLET_LIST,
              element: (
                <RoutePermissionRedirector
                  permissionParentKey={"Inventory Management"}
                  permissionChildKey="can_view_store_wallet"
                >
                  <appPages.AllStoreWalllet />
                </RoutePermissionRedirector>
              ),
            },
            {
              path: pathConstants.INVENTORY_STORE_WALLET_DETAILS({ id: ":id" }),
              element: (
                <RoutePermissionRedirector
                  permissionParentKey={"Inventory Management"}
                  permissionChildKey="can_view_store_wallet"
                >
                  <appPages.StoreWalletDetails />
                </RoutePermissionRedirector>
              ),
            },
            {
              path: pathConstants.INVENTORY_STORE_WALLET_TRANSACTION_DETAILS({
                id: ":id",
              }),
              element: (
                <RoutePermissionRedirector
                  permissionParentKey={"Inventory Management"}
                  permissionChildKey="can_view_store_wallet"
                >
                  <appPages.StoreWalletTransactionDetails />
                </RoutePermissionRedirector>
              ),
            },
          ],
        },
        {
          path: pathConstants.INVENTORY_CREDITLOG_DETAILS,
          element: (
            <RoutePermissionRedirector
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_view_and_update_credits"
            >
              <appPages.CreditLogDetails />
            </RoutePermissionRedirector>
          ),
        },
        {
          path: pathConstants.INVENTORY_PRODUCT_STOCKHISTORY,
          element: (
            <RoutePermissionRedirector
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_view_products"
            >
              <appPages.ProductStockHistory />
            </RoutePermissionRedirector>
          ),
        },
        {
          path: pathConstants.INVENTORY_INVOICE_DETAILS({ id: ":id" }),
          element: <appPages.InvoiceDetails />,
        },
        {
          path: pathConstants.INVENTORY_SUPPLIERS_DETAILS,
          element: (
            <RoutePermissionRedirector
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_view_edit_and_create_suppliers"
            >
              <appPages.SupplierDetails />
            </RoutePermissionRedirector>
          ),
        },
        {
          path: pathConstants.INVENTORY_SUPPLIERS_DETAILS_EDIT,
          element: (
            <RoutePermissionRedirector
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_view_edit_and_create_suppliers"
            >
              <appPages.EditSupplier />
            </RoutePermissionRedirector>
          ),
        },
        {
          path: pathConstants.INVENTORY_RECONCILIATIONS_EDIT,
          element: (
            <RoutePermissionRedirector
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_create_and_submit_reconciliation"
            >
              <appPages.ReconcileStocks />
            </RoutePermissionRedirector>
          ),
        },
        {
          path: pathConstants.INVENTORY_RECONCILIATIONS_REVIEW,
          element: (
            <RoutePermissionRedirector
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_approve_reconciliation"
            >
              <appPages.ReconciliationReview />
            </RoutePermissionRedirector>
          ),
        },
        {
          path: pathConstants.INVENTORY_PRODUCTS_ADD_SINGLE,
          element: (
            <RoutePermissionRedirector
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_add_products"
            >
              <appPages.AddSingleProduct />
            </RoutePermissionRedirector>
          ),
        },
        {
          path: pathConstants.INVENTORY_PRODUCTS_ADD_COMPOSITE,
          element: (
            <RoutePermissionRedirector
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_add_products"
            >
              <appPages.CreateCompositeProduct />
            </RoutePermissionRedirector>
          ),
        },
        {
          path: pathConstants.INVENTORY_SERVICE_EDIT({ id: ":id" }),
          element: (
            <RoutePermissionRedirector
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_edit_or_update_product"
            >
              <appPages.EditService />
            </RoutePermissionRedirector>
          ),
        },
        {
          path: pathConstants.INVENTORY_PRODUCT_EDIT({ id: ":id" }),
          element: (
            <RoutePermissionRedirector
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_edit_or_update_product"
            >
              <appPages.EditProduct />
            </RoutePermissionRedirector>
          ),
        },
        {
          path: pathConstants.INVENTORY_PRODUCTS_EDIT_COMPOSITE({ id: ":id" }),
          element: (
            <RoutePermissionRedirector
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_edit_or_update_product"
            >
              <appPages.EditCompositeProducts />
            </RoutePermissionRedirector>
          ),
        },
        {
          path: pathConstants.INVENTORY_PRODUCT_ITEM_TRACKING({ id: ":id" }),
          element: (
            <RoutePermissionRedirector
              permissionParentKey={"Inventory Management"}
              // permissionChildKey="can_edit_or_update_product"
            >
              <appPages.TrackItem />
            </RoutePermissionRedirector>
          ),
        },
        {
          path: pathConstants.INVENTORY_PRODUCTS_BULK_RESTOCK,
          element: (
            <RoutePermissionRedirector
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_restock_products_bulk_and_single_restock"
            >
              <appPages.BulkRestock />
            </RoutePermissionRedirector>
          ),
        },
        {
          path: pathConstants.INVENTORY_PRODUCTS_ADD_MULTIPLE,
          element: (
            <RoutePermissionRedirector
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_add_products"
            >
              <appPages.AddMultipleProducts />
            </RoutePermissionRedirector>
          ),
        },
        {
          path: pathConstants.INVENTORY_MENU_CREATE,
          element: (
            <RoutePermissionRedirector
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_view_edit_and_create_storefront"
            >
              <appPages.CreateFoodMenu />
            </RoutePermissionRedirector>
          ),
          children: [
            {
              path: pathConstants.INVENTORY_MENU_CREATE_CUSTOMIZATION({
                menuId: ":menuId",
              }),
              element: <appPages.CustomizationNavigation />,
              children: [
                {
                  path: pathConstants.INVENTORY_MENU_CREATE_CUSTOMIZATION_CHECKOUT(
                    {
                      menuId: ":menuId",
                    },
                  ),
                  element: <appPages.StorefrontSettingsCheckout />,
                },
                {
                  path: pathConstants.INVENTORY_MENU_CREATE_CUSTOMIZATION_PAYMENT(
                    {
                      menuId: ":menuId",
                    },
                  ),
                  element: <appPages.StorefrontSettingsPayment />,
                },
                {
                  path: pathConstants.INVENTORY_MENU_CREATE_CUSTOMIZATION_TEMPLATES(
                    {
                      menuId: ":menuId",
                    },
                  ),
                  element: <appPages.StorefrontSettingsTemplates />,
                },
                {
                  path: pathConstants.INVENTORY_MENU_CREATE_CUSTOMIZATION_BRANDING(
                    {
                      menuId: ":menuId",
                    },
                  ),
                  element: <appPages.StorefrontSettingsBranding />,
                },
                {
                  path: pathConstants.INVENTORY_MENU_CREATE_CUSTOMIZATION_SORT_CATEGORIES(
                    {
                      menuId: ":menuId",
                    },
                  ),
                  element: <appPages.StorefrontSettingsSortCategories />,
                },
              ],
            },
            {
              path: pathConstants.INVENTORY_MENU_CREATE_SHIPPING({
                menuId: ":menuId",
              }),
              element: <appPages.CreateShippingForm />,
            },
          ],
        },
        {
          path: pathConstants.INVENTORY_MENU_CREATE_EDIT({
            menuId: ":menuId",
          }),
          element: (
            <RoutePermissionRedirector
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_view_edit_and_create_storefront"
            >
              <appPages.CreateFoodMenu />
            </RoutePermissionRedirector>
          ),
        },
        {
          path: pathConstants.INVENTORY_MENU_PAYMENT,
          element: (
            <RoutePermissionRedirector
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_view_edit_and_create_storefront"
            >
              <appPages.PaymentSetup />
            </RoutePermissionRedirector>
          ),
        },
        {
          path: pathConstants.INVENTORY_MENU_PAYMENT_DETAILS({
            paymentId: ":paymentId",
          }),
          element: (
            <RoutePermissionRedirector
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_view_edit_and_create_storefront"
            >
              <appPages.PaymentSetup />
            </RoutePermissionRedirector>
          ),
        },
        {
          path: pathConstants.INVENTORY_MENU_DETAILS({
            menuId: ":menuId",
          }),
          element: (
            <RoutePermissionRedirector
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_view_edit_and_create_storefront"
            >
              <appPages.CreateFoodMenu />
            </RoutePermissionRedirector>
          ),
          children: [
            {
              path: pathConstants.INVENTORY_MENU_DETAILS_CUSTOMIZATION({
                menuId: ":menuId",
              }),
              element: <appPages.CustomizationNavigation />,
              children: [
                {
                  path: pathConstants.INVENTORY_MENU_DETAILS_CUSTOMIZATION_CHECKOUT(
                    {
                      menuId: ":menuId",
                    },
                  ),
                  element: <appPages.StorefrontSettingsCheckout />,
                },
                {
                  path: pathConstants.INVENTORY_MENU_DETAILS_CUSTOMIZATION_PAYMENT(
                    {
                      menuId: ":menuId",
                    },
                  ),
                  element: <appPages.StorefrontSettingsPayment />,
                },
                {
                  path: pathConstants.INVENTORY_MENU_DETAILS_CUSTOMIZATION_TEMPLATES(
                    {
                      menuId: ":menuId",
                    },
                  ),
                  element: <appPages.StorefrontSettingsTemplates />,
                },
                {
                  path: pathConstants.INVENTORY_MENU_DETAILS_CUSTOMIZATION_BRANDING(
                    {
                      menuId: ":menuId",
                    },
                  ),
                  element: <appPages.StorefrontSettingsBranding />,
                },
                {
                  path: pathConstants.INVENTORY_MENU_DETAILS_CUSTOMIZATION_SORT_CATEGORIES(
                    {
                      menuId: ":menuId",
                    },
                  ),
                  element: <appPages.StorefrontSettingsSortCategories />,
                },
              ],
            },
          ],
        },
        {
          element: (
            <RoutePermissionRedirector
              permissionParentKey={"Inventory Management"}
            >
              <layouts.StoresLayout />
            </RoutePermissionRedirector>
          ),
          children: [
            {
              path: pathConstants.STORES,
              element: (
                <RoutePermissionRedirector
                  permissionParentKey={"Inventory Management"}
                  permissionChildKey="can_view_stores"
                >
                  <appPages.AllStores />
                </RoutePermissionRedirector>
              ),
            },
          ],
        },
        {
          element: (
            <RoutePermissionRedirector
              permissionParentKey={"Inventory Management"}
            >
              <layouts.AllSectionLayout />
            </RoutePermissionRedirector>
          ),
          children: [
            {
              path: pathConstants.SECTIONS,
              element: (
                <RoutePermissionRedirector
                  permissionParentKey={"Inventory Management"}
                >
                  <appPages.AllSections />
                </RoutePermissionRedirector>
              ),
            },

            {
              path: pathConstants.MANAGE_SECTIONS,
              element: (
                <RoutePermissionRedirector
                  permissionParentKey={"Inventory Management"}
                  permissionChildKey="can_create_kitchen_sections"
                >
                  <appPages.ManageSections />
                </RoutePermissionRedirector>
              ),
            },
          ],
        },
        {
          element: (
            <RoutePermissionRedirector permissionParentKey={"Expense"}>
              <layouts.ExpensesLayout />
            </RoutePermissionRedirector>
          ),
          children: [
            {
              path: pathConstants.EXPENSES_LIST + "/list",
              element: <Navigate to={pathConstants.EXPENSES_LIST} replace />,
            },
            {
              path: pathConstants.EXPENSES_ANALYTICS,
              element: (
                <RoutePermissionRedirector
                  permissionParentKey={"Expense"}
                  permissionChildKey={"can_view_expense_analytics"}
                  redirectPath={pathConstants.EXPENSES_LIST}
                >
                  <appPages.ExpenseAnalytics />
                </RoutePermissionRedirector>
              ),
            },
            {
              path: pathConstants.EXPENSES_LIST,
              element: (
                <RoutePermissionRedirector
                  permissionParentKey={"Expense"}
                  permissionChildKey={"can_create_expense"}
                >
                  <appPages.Expenses />
                </RoutePermissionRedirector>
              ),
            },
            {
              path: pathConstants.EXPENSES_CATEGORIES,
              element: (
                <RoutePermissionRedirector
                  permissionParentKey={"Expense"}
                  permissionChildKey={
                    "can_view_edit_and_create_expense_category"
                  }
                >
                  <appPages.ExpenseCategories />
                </RoutePermissionRedirector>
              ),
            },
          ],
        },
        {
          path: pathConstants.EXPENSES_DETAILS,
          element: (
            <RoutePermissionRedirector
              permissionParentKey={"Expense"}
              permissionChildKey={"can_create_expense"}
            >
              <appPages.ExpenseDetails />
            </RoutePermissionRedirector>
          ),
        },
        {
          element: (
            <RoutePermissionRedirector permissionParentKey={"Accounting"}>
              <layouts.AccountingLayout />
            </RoutePermissionRedirector>
          ),
          children: [
            {
              path: pathConstants.ACCOUNTING_OVERVIEW,
              element: (
                <RoutePermissionRedirector permissionParentKey={"Accounting"}>
                  <appPages.AccountingOverview />
                </RoutePermissionRedirector>
              ),
            },
          ],
        },
        {
          path: pathConstants.ACCOUNTING_DETAILS({ id: ":id" }),
          element: (
            <RoutePermissionRedirector
              permissionParentKey={"Accounting"}
              permissionChildKey={"can_view_all_reports"}
            >
              <appPages.AccountingDetails />
            </RoutePermissionRedirector>
          ),
        },
        {
          path: pathConstants.ACCOUNTING_PROFITANDLOSS,
          element: (
            <RoutePermissionRedirector
              permissionParentKey={"Accounting"}
              permissionChildKey={"can_view_all_reports"}
            >
              <appPages.ProfitAndLost />
            </RoutePermissionRedirector>
          ),
        },
        {
          path: pathConstants.REQUEST_CAPITAL,
          element: <appPages.RequestCapital />,
        },
        {
          element: (
            // <secondaryComponents.CountryChecker>
            <RoutePermissionRedirector permissionParentKey={"Loyalty"}>
              <layouts.LoyaltyLayout />
            </RoutePermissionRedirector>
            // </secondaryComponents.CountryChecker>
          ),
          children: [
            {
              path: pathConstants.LOYALTY_MANAGE,
              element: (
                <Navigate to={pathConstants.LOYALTY_MANAGE_CAMPAIGN} replace />
              ),
            },
            {
              path: pathConstants.LOYALTY_CREATE_CAMPAIGN,
              element: (
                <RoutePermissionRedirector
                  permissionParentKey={"Loyalty"}
                  permissionChildKey={"can_view_and_create_loyalty_campaigns"}
                >
                  <appPages.CreateCampaign />
                </RoutePermissionRedirector>
              ),
            },
            {
              path: pathConstants.LOYALTY_CREATE_GIFTCARD,
              element: (
                <RoutePermissionRedirector
                  permissionParentKey={"Loyalty"}
                  permissionChildKey={"can_view_and_create_gift_cards"}
                >
                  <appPages.CreateGiftCard />
                </RoutePermissionRedirector>
              ),
            },
            {
              path: pathConstants.LOYALTY_MANAGE_CAMPAIGN,
              element: (
                <RoutePermissionRedirector
                  permissionParentKey={"Loyalty"}
                  permissionChildKey={"can_view_and_create_loyalty_campaigns"}
                  redirectPath={pathConstants.LOYALTY_MANAGE_GIFTCARDS}
                >
                  <appPages.AllCampaigns />
                </RoutePermissionRedirector>
              ),
            },
            {
              path: pathConstants.LOYALTY_MANAGE_GIFTCARDS,
              element: (
                <RoutePermissionRedirector
                  permissionParentKey={"Loyalty"}
                  permissionChildKey={"can_view_and_create_gift_cards"}
                >
                  <appPages.AllGiftCards />
                </RoutePermissionRedirector>
              ),
            },
            {
              element: (
                <RoutePermissionRedirector permissionParentKey={"Loyalty"}>
                  <layouts.CustomerSubLayout />
                </RoutePermissionRedirector>
              ),
              children: [
                {
                  path: pathConstants.LOYALTY_CUSTOMERS,
                  element: <appPages.LoyaltyCustomer />,
                },
                {
                  path: pathConstants.LOYALTY_CUSTOMERS_HISTORY,
                  element: <appPages.LoyaltyCustomeHistory />,
                },
              ],
            },
            {
              path: pathConstants.LOYALTY_CUSTOMERS_DETAILS({ id: ":id" }),
              element: (
                <RoutePermissionRedirector permissionParentKey={"Loyalty"}>
                  <appPages.LoyaltyCustomerDetails />
                </RoutePermissionRedirector>
              ),
            },
            {
              path: pathConstants.LOYALTY_NOTIFICATION,
              element: (
                <RoutePermissionRedirector permissionParentKey={"Loyalty"}>
                  <appPages.LoyaltyNotification />
                </RoutePermissionRedirector>
              ),
            },
            {
              path: pathConstants.LOYALTY_EDIT_CAMPAIGN({
                campaignId: ":campaignId",
              }),
              element: (
                <RoutePermissionRedirector
                  permissionParentKey={"Loyalty"}
                  permissionChildKey={"can_view_and_create_loyalty_campaigns"}
                >
                  <appPages.EditCampaign />
                </RoutePermissionRedirector>
              ),
            },
            {
              path: pathConstants.LOYALTY_EDIT_GIFTCARD({
                giftcardId: ":giftcardId",
              }),
              element: (
                <RoutePermissionRedirector
                  permissionParentKey={"Loyalty"}
                  permissionChildKey={"can_view_and_create_gift_cards"}
                >
                  <appPages.EditGiftCard />
                </RoutePermissionRedirector>
              ),
            },
          ],
        },
        {
          element: (
            <RoutePermissionRedirector
              permissionParentKey={"Customers"}
              permissionChildKey={"can_view_customer"}
            >
              <layouts.CustomersLayout />
            </RoutePermissionRedirector>
          ),
          children: [
            {
              path: pathConstants.CUSTOMERS,
              element: <appPages.AllCustomers />,
            },
            {
              path: pathConstants.CUSTOMER_DETAILS({
                customerId: ":customerId",
              }),
              element: <appPages.CustomerDetails />,
            },
          ],
        },
        {
          path: pathConstants.APPS_MAIN,
          element: <appPages.AllMarkets />,
        },
        {
          path: pathConstants.APPS_DETAILS({ id: ":id" }),
          element: <appPages.MarketDetails />,
        },
        {
          path: pathConstants.KYB_VERIFICATION,
          element: (
            <RoutePermissionRedirector
              permissionParentKey={"Settings"}
              permissionChildKey={"can_submit_bvn_and_kyb"}
              redirectPath={pathConstants.DASHBOARD_MAIN}
            >
              <appPages.KYBVerification />
            </RoutePermissionRedirector>
          ),
        },
        {
          path: pathConstants.DOCUMENTATION,
          element: <appPages.Documentation />,
        },
        {
          element: <layouts.SettingsLayout />,
          children: [
            {
              path: pathConstants.SETTINGS,
              element: <Navigate to={pathConstants.SETTINGS_PROFILE} replace />,
            },
            {
              path: pathConstants.SETTINGS_USERS,
              element: (
                <RoutePermissionRedirector
                  permissionParentKey={"Settings"}
                  permissionChildKey={"can_create_update_and_invite_users"}
                >
                  <appPages.Users />
                </RoutePermissionRedirector>
              ),
            },
            {
              path: pathConstants.SETTINGS_SETTLEMENTS,
              element: (
                <secondaryComponents.CountryChecker>
                  <RoutePermissionRedirector
                    permissionParentKey={"Settings"}
                    permissionChildKey={
                      "can_edit_and_update_settlement_accounts"
                    }
                  >
                    <appPages.Settlements />
                  </RoutePermissionRedirector>
                </secondaryComponents.CountryChecker>
              ),
            },
            {
              path: pathConstants.SETTINGS_APIKEYS,
              element: (
                <RoutePermissionRedirector
                  permissionParentKey={"Settings"}
                  permissionChildKey={"can_view_update_api_key"}
                >
                  <appPages.ApiKeys />
                </RoutePermissionRedirector>
              ),
            },
            {
              element: <appPages.Profile />,
              children: [
                {
                  path: pathConstants.SETTINGS_PROFILE,
                  element: <appPages.UserProfile />,
                },
                {
                  path: pathConstants.SETTINGS_BUSINESS_PROFILE,
                  element: (
                    <RoutePermissionRedirector
                      permissionParentKey={"Settings"}
                      permissionChildKey="can_update_business_profile"
                      redirectPath={pathConstants.SETTINGS_PROFILE}
                    >
                      <appPages.BusinessProfile />
                    </RoutePermissionRedirector>
                  ),
                },
              ],
            },
            {
              element: (
                <RoutePermissionRedirector
                  permissionParentKey={"Settings"}
                  permissionChildKey={"can_update_subscriptions"}
                >
                  <appPages.Subscriptions />
                </RoutePermissionRedirector>
              ),
              children: [
                {
                  path: pathConstants.SETTINGS_SUBSCRIPTIONS,
                  element: <appPages.SubscriptionHistory />,
                },
                {
                  path: pathConstants.SETTINGS_SUBSCRIPTIONS_PLANS,
                  element: <appPages.SubscriptionPlans />,
                },
              ],
            },
          ],
        },
        {
          element: (
            <RoutePermissionRedirector
              permissionParentKey={"Settings"}
              permissionChildKey={"can_create_update_and_invite_users"}
            >
              <appPages.ManageRoles />
            </RoutePermissionRedirector>
          ),
          children: [
            {
              path: pathConstants.SETTINGS_USERS_MANAGEROLES,
              element: <appPages.DefaultRoles />,
            },
            {
              path: pathConstants.SETTINGS_USERS_MANAGEROLES_DEFAULT,
              element: <appPages.DefaultRoles />,
            },
            {
              path: pathConstants.SETTINGS_USERS_MANAGEROLES_CUSTOM,
              element: <appPages.CustomRoles />,
            },
          ],
        },
        {
          path: pathConstants.SETTINGS_USERS_MANAGEROLES_DETAILS({ id: ":id" }),
          element: (
            <RoutePermissionRedirector
              permissionParentKey={"Settings"}
              permissionChildKey={"can_create_update_and_invite_users"}
            >
              <appPages.RolePage />
            </RoutePermissionRedirector>
          ),
        },
        {
          path: pathConstants.APP_SUBSCRIPTION_PLAN,
          element: <appPages.SubscriptionSelection />,
        },
      ],
    },
    {
      element: (
        <PushNotifications>
          <layouts.SellModeLayout />
        </PushNotifications>
      ),
      children: [
        {
          path: pathConstants.SELL_MODE,
          element: <storePages.ProductsPage />,
        },
      ],
    },
    {
      element: <layouts.SectionLayout />,
      children: [
        {
          path: pathConstants.SECTION_MODE({ section_id: ":section_id" }),
          element: <SectionPages.StaffSection />,
        },
      ],
    },
    {
      element: <SectionPages.CustomersView />,
      path: pathConstants.SECTION_CUSTOMER_MODE({ store_id: ":store_id" }),
    },

    // helper page
    {
      element: <ClientLogin />,
      path: pathConstants.CLIENT_LOGIN,
    },
    {
      element: (
        <secondaryComponents.AppGuard checkSubscription={false}>
          <ExistingClientConnect />
        </secondaryComponents.AppGuard>
      ),
      path: pathConstants.CLIENT_EXISTING_CONNECT,
    },

    { path: "*", element: <Error404 /> },
  ];

  return routes;
};

export default useGetRoutes;
