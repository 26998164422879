import { useContext, useState } from "react";
import primaryComponents from "../../primaryComponents";
import { ToastContext } from "../../../hooks/context";
import { useGetOrderActionDetails } from "../../../hooks/api/queries";
import { useParams } from "react-router-dom";
import { useCloseOtpAuthorizationPrompt } from "../../../hooks/useCloseOtpAuthorizationPrompt";
import { useDispatch, useSelector } from "react-redux";
import { setDiscountOtp } from "../../../store/modules/storeCart";
import { setVerifiedOTPCode } from "../../../store/modules/apiErrorHandler";
import { encodeToBase64 } from "../../../helpers";
import { formatNumberWithCommas } from "../../../helpers";

const Discount = ({
  setDiscount,
  discount,
  currency_symbol,
  closeModal,
  totalAmount,
}) => {
  const triggerToast = useContext(ToastContext);
  const {
    cartDetails: { total_items_amount },
  } = useSelector((state) => state.cartReducer);
  const [discountVal, setDiscountVal] = useState(discount);
  const verified_otp_code = useSelector(
    (state) => state.apiErrorReducer?.verifiedOTPCode,
  );
  const { store_id } = useParams();
  const dispatch = useDispatch();
  const { closeOtpAuthorizationPrompt } = useCloseOtpAuthorizationPrompt();
  const { refetch: fetchOrderActionDetails, isLoading: isLoadingAuthAction } =
    useGetOrderActionDetails({
      store_id: store_id,
      action: "add_discount_to_order",
      enabled: false,
      onSuccess: () => {
        dispatch(setDiscountOtp(encodeToBase64(verified_otp_code)));
        dispatch(setVerifiedOTPCode(null));
        setDiscount(discountVal);
        closeModal();
        closeOtpAuthorizationPrompt();
      },
    });
  const [decimalVal, setDecimalVal] = useState(
    (discountVal / total_items_amount) * 100,
  );
  const [isAmountType, setIsAmountType] = useState(true);

  return (
    <>
      <div
        className="p-3"
        style={{ display: "flex", flexDirection: "column", gap: "15px" }}
      >
        <h4>Apply Discount</h4>

        <div className="d-flex my-1" style={{ gap: "10px" }}>
          <primaryComponents.BoxedRadioInput
            name={"valueType"}
            id="DISCOUNT_AMOUNT"
            isChecked={isAmountType}
            setRadioValue={() => {
              setIsAmountType(true);
            }}
          >
            Amount
          </primaryComponents.BoxedRadioInput>
          <primaryComponents.BoxedRadioInput
            name={"valueType"}
            id="DISCOUNT_PERCENT"
            isChecked={!isAmountType}
            setRadioValue={() => {
              setIsAmountType(false);
            }}
          >
            Percentage
          </primaryComponents.BoxedRadioInput>
        </div>

        <div
          style={{
            border: "1px solid #ddd",
            padding: "8px",
            borderRadius: "4px",
          }}
        >
          <primaryComponents.InputField
            label={isAmountType ? "Amount" : "Percentage"}
            classNames="white"
            placeholder={
              isAmountType
                ? `Enter discount amount e.g. ${currency_symbol}1000`
                : "Enter discount (%)"
            }
            isRequired={false}
            value={isAmountType ? discountVal : decimalVal}
            type="number"
            min="0"
            name="discount"
            floatError={false}
            onChange={({ target: { value } }) => {
              const realValue = value;
              value = `${value}`;
              const [integerPart, decimalPart] = value?.split(".");
              value =
                decimalPart || value?.includes(".")
                  ? `${integerPart}.${decimalPart || 0}`
                  : integerPart;

              if (isAmountType) {
                setDiscountVal(realValue);
                setDecimalVal((value / total_items_amount) * 100);
              } else {
                setDecimalVal(realValue);
                setDiscountVal((value * total_items_amount) / 100);
              }
            }}
          />
          <span className="purple--text small-text-size">
            Amount: {`${currency_symbol}${formatNumberWithCommas(discountVal)}`}
          </span>
        </div>

        <primaryComponents.Button
          classNames={"btn btn--primary cover"}
          onClick={() => {
            if (Number(totalAmount) < Number(discountVal))
              return triggerToast(
                "Discount cannot be more than the total price of items",
              );
            fetchOrderActionDetails();
            // setDiscount(discountVal);
            // closeModal();
          }}
          isLoading={isLoadingAuthAction}
          isDisabled={isLoadingAuthAction}
        >
          Apply
        </primaryComponents.Button>
      </div>
    </>
  );
};
export default Discount;
