import { useState, useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import { useSelector } from "react-redux";
import { createAwaitingPaymentSession } from "../../../services/sales-point";
import { getRandomIntInclusive } from "../../../helpers";
import { useGetOrderDetails } from "../../../hooks/api/queries/useOrder";
import { useGetAccountingBanksListWithSpreadFilter } from "../../../hooks/api/queries";

const StoreCheckoutLogic = ({ closeStoreCheckout }) => {
  const [queryParams] = useSearchParams();
  const orderId = queryParams.get("order_id");
  const [checkoutType, setCheckoutType] = useState("cash");
  const [isOrderCancel, setIsOrderCancel] = useState(null);
  const triggerToast = useContext(ToastContext);
  const [screenViews, setScreenViews] = useState({
    isPaymentDone: false,
  });
  const { storeList } = useSelector((state) => state.profileDetailsReducer);

  const { store_id } = useParams();
  const storeDetails = storeList?.find((item) => `${item?.id}` === store_id);
  const showTables = storeDetails?.allow_table;
  const showWareHouse = storeDetails?.is_warehouse;
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const queryClient = useQueryClient();
  const [showAccount, setShowAccount] = useState(true);
  const [selectBank, setSelectedBank] = useState("");
  const {
    isLoading: isLoadingBankAccountsList,
    data: bankAccount,
    refetch: fetchBankAccountsList,
  } = useGetAccountingBanksListWithSpreadFilter({
    account_type: "bank_account",
  });

  const {
    isLoading: isLoadingOrder,
    data: orderDetails,
    refetch: refetchOrderDetails,
  } = useGetOrderDetails({
    id: orderId,
  });

  const closePaymentSession = () => {
    refetchOrderDetails();
    queryClient.invalidateQueries(["getOrders"]);
    queryClient.invalidateQueries(["orderDetails"]);
    queryClient.invalidateQueries(["getPendingSessions"]);
  };

  const { mutate: pendSession, isLoading: isPendingSessions } = useMutation(
    (data) => createAwaitingPaymentSession(data),
  );
  const pendCheckout = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 40));
    pendSession(
      {
        order_id: orderId,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["getPendingSessions"]);
          closeStoreCheckout();
          triggerToast("Session Pended!", "success");
        },

        onSettled: setRequestLoaderProgress(100),
      },
    );
  };

  return {
    isLoadingOrder,
    checkoutType,
    orderDetails,
    showWareHouse,
    showTables,
    isPendingSessions,
    screenViews,
    setScreenViews,
    pendCheckout,
    setCheckoutType,
    closePaymentSession,
    showAccount,
    setShowAccount,
    storeDetails,
    bankAccount,
    isLoadingBankAccountsList,
    fetchBankAccountsList,
    selectBank,
    setSelectedBank,
    isOrderCancel,
    setIsOrderCancel,
  };
};

export default StoreCheckoutLogic;
