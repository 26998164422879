import { useTable } from "react-table";
import { useEffect, useMemo, useState } from "react";
import { truncateString } from "../../../../../../../helpers";
import { useNavigate } from "react-router-dom";
import primaryComponents from "../../../../../../../components/primaryComponents";
import secondaryComponents from "../../../../../../../components/secondaryComponents";
import CopyToClipboard from "react-copy-to-clipboard";
import Icon from "../../../../../../../assets/icons";
import MoreActions from "./components/MoreAction";
import { pathConstants } from "../../../../../../../routes/pathContants";

const useMenuDetails = ({
  foodMenus,
  setIsDeleteFoodMenu,
  setIsFoodMenuWidget,
  selectedMenuTab,
  onCopy = () => {},
}) => {
  const navigate = useNavigate();
  const [isTabReady, setIsTabReady] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      return (
        /android|iphone|ipad|ipod/.test(userAgent) || window.innerWidth <= 768
      );
    };
    setIsMobile(checkIfMobile());
  }, []);

  useEffect(() => {
    if (selectedMenuTab) {
      setIsTabReady(true);
    }
  }, [selectedMenuTab]);

  const columns = useMemo(
    () => [
      {
        Header: "Storefront",
        accessor: "name",
        Cell: ({ cell: { value } }) => <span>{truncateString(value, 50)}</span>,
      },
      {
        Header: "Store",
        accessor: "store",
        Cell: ({ cell: { value } }) => <span>{value.name}</span>,
      },
      {
        Header: "URL",
        accessor: "slug_url",
        Cell: ({ cell: { value, row } }) => (
          <div className={"d-flex align-items-center"} style={{ gap: "15px" }}>
            {row?.original?.template_in_use === "template-2" ? (
              <>
                <a
                  href={`${window.location.origin}/storefront/${
                    value?.split("/storefront/")[1]
                  }`}
                  className="gray--text"
                  target="_blank"
                  rel="noreferrer"
                >
                  {truncateString(
                    `${window.location.origin}/storefront/${
                      value?.split("/storefront/")[1]
                    }`,
                    15,
                  )}{" "}
                </a>
                <CopyToClipboard
                  text={`${window.location.origin}/storefront/${
                    value?.split("/storefront/")[1]
                  }`}
                  onCopy={() => onCopy()}
                >
                  <img width="15px" className="mr-2" src={Icon.copy} alt="" />
                </CopyToClipboard>
              </>
            ) : row?.original?.template ? (
              <>
                <a
                  href={`${process.env.REACT_APP_LUMI_STOREFRONT_APP_BASE_URL}/NG?sfid=${row?.original?.id}`}
                  className="gray--text"
                  target="_blank"
                  rel="noreferrer"
                >
                  {truncateString(
                    `${process.env.REACT_APP_LUMI_STOREFRONT_APP_BASE_URL}/NG?sfid=${row?.original?.id}`,
                    15,
                  )}{" "}
                </a>
                <CopyToClipboard
                  text={`${process.env.REACT_APP_LUMI_STOREFRONT_APP_BASE_URL}/NG?sfid=${row?.original?.id}`}
                  onCopy={() => onCopy()}
                >
                  <img width="15px" className="mr-2" src={Icon.copy} alt="" />
                </CopyToClipboard>
              </>
            ) : (
              <>
                <a
                  href={`${window.location.origin}/storefront/${
                    value?.split("/storefront/")[1]
                  }`}
                  className="gray--text"
                  target="_blank"
                  rel="noreferrer"
                >
                  {truncateString(
                    `${window.location.origin}/storefront/${
                      value?.split("/storefront/")[1]
                    }`,
                    15,
                  )}{" "}
                </a>
                <CopyToClipboard
                  text={`${window.location.origin}/storefront/${
                    value?.split("/storefront/")[1]
                  }`}
                  onCopy={() => onCopy()}
                >
                  <img width="15px" className="mr-2" src={Icon.copy} alt="" />
                </CopyToClipboard>
              </>
            )}
          </div>
        ),
      },
      {
        Header: "Action",
        accessor: "",
        Cell: ({ cell: { row } }) => {
          return selectedMenuTab === "Storefront" ? (
            <div className="d-flex" style={{ gap: "10px" }}>
              <secondaryComponents.NavigateWithPermission
                permissionParentKey={"Inventory Management"}
                permissionChildKey="can_view_edit_and_create_storefront"
              >
                <primaryComponents.Button
                  classNames={"btn btn--primary smallBtn"}
                  onClick={() =>
                    navigate(
                      pathConstants.INVENTORY_MENU_DETAILS({
                        menuId: row.original.id,
                      }) + `?store_id=${row.original.store?.id}`,
                    )
                  }
                >
                  Update Inventory
                </primaryComponents.Button>
                <primaryComponents.Button
                  classNames={"btn btn--outline smallBtn"}
                  onClick={() =>
                    navigate(
                      pathConstants.INVENTORY_MENU_DETAILS_CUSTOMIZATION_CHECKOUT(
                        {
                          menuId: row.original.id,
                        },
                      ) + `?store_id=${row.original.store?.id}`,
                    )
                  }
                >
                  Settings
                </primaryComponents.Button>
                <MoreActions
                  setIsDeleteFoodMenu={setIsDeleteFoodMenu}
                  setIsFoodMenuWidget={setIsFoodMenuWidget}
                  productId={row?.original?.id}
                  isMobile={isMobile}
                />
              </secondaryComponents.NavigateWithPermission>
            </div>
          ) : (
            <primaryComponents.Button
              classNames={"btn btn--primary smallBtn"}
              onClick={() =>
                navigate(
                  `${row.original.id}?store_id=${row.original.store?.id}&sort=true`,
                )
              }
            >
              Customize
            </primaryComponents.Button>
          );
        },
      },
    ],
    [selectedMenuTab, navigate, setIsDeleteFoodMenu, setIsFoodMenuWidget],
  );

  const instance = useTable({ columns, data: foodMenus || [] });

  return {
    instance,
    isTabReady,
  };
};

export default useMenuDetails;
