import styles from "./styles.module.scss";
import Icon from "../../../../../assets/icons";
import secondaryComponents from "../../../../../components/secondaryComponents";
import primaryComponents from "../../../../../components/primaryComponents";
import { motion } from "framer-motion";
import { PRODUCT_DEFAULT_ICONS } from "../../../../../helpers/constants";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  removeFromMenuCart,
  setMenuItemQty,
} from "../../../../../store/modules/menuCart";
import {
  getPriceWithDiscout,
  getPriceWithoutDiscout,
  getVariantPriceWithDiscout,
  getVariantPriceWithoutDiscout,
} from "../../../../../helpers";

const ProductCart = ({ product, cartIndex, menuObjectDetail }) => {
  const dispatch = useDispatch();

  const [quantity, setQuantity] = useState(product.quantity);
  const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false);

  const primaryColor = menuObjectDetail?.primary_colour
    ? menuObjectDetail?.primary_colour
    : "#7647ee";

  useEffect(() => {
    dispatch(setMenuItemQty({ ...product, quantity }));
  }, [quantity]);

  useEffect(() => {
    setQuantity(product.quantity);
  }, [product]);

  return (
    <>
      <motion.div
        initial={{ y: 20 }}
        animate={{ y: 0 }}
        className={styles.cartContainer}
      >
        <div className={styles["product"]}>
          <div className={styles["product-image"]}>
            <img
              src={product?.image_url || PRODUCT_DEFAULT_ICONS[cartIndex % 5]}
              alt=""
            />
          </div>
          <div className={styles["product-details"]}>
            <p className={`${styles["product-name"]} dark--text`}>
              {product.name}
            </p>
            <p className={styles["product-quantity"]}>Qty: {quantity}</p>
            <div className={styles["product-order"]}>
              <p className={"small-text-size font-weight-semibold dark--text"}>
                {product.store?.currency_symbol}{" "}
                {product?.is_variant
                  ? (
                      getVariantPriceWithDiscout(product) * quantity
                    ).toLocaleString()
                  : (
                      getPriceWithDiscout(product) * quantity
                    ).toLocaleString()}{" "}
                {(product?.is_variant
                  ? product.store_variant_properties[0]?.pricings?.find(
                      (item) => item.pricing_type.id === 1,
                    )?.discount_rate > 0
                  : product?.is_service
                    ? product.store_service_properties[0]?.pricings?.find(
                        (item) => item.pricing_type.id === 1,
                      )?.discount_rate > 0
                    : product.store_product_properties[0]?.pricings?.find(
                        (item) => item.pricing_type.id === 1,
                      )?.discount_rate > 0) && (
                  <span className={`error--text ${styles["product-price"]}`}>
                    <span style={{ textDecoration: "line-through" }}>
                      {product.store?.currency_symbol}{" "}
                      {product?.is_variant
                        ? (
                            getVariantPriceWithoutDiscout(product) * quantity
                          ).toLocaleString()
                        : (
                            getPriceWithoutDiscout(product) * quantity
                          ).toLocaleString()}
                    </span>{" "}
                    {product?.is_variant
                      ? product.store_variant_properties[0]?.pricings?.find(
                          (item) => item.pricing_type.id === 1,
                        )?.discount_rate
                      : product?.is_service
                        ? product.store_service_properties[0]?.pricings?.find(
                            (item) => item.pricing_type.id === 1,
                          )?.discount_rate > 0
                        : product.store_product_properties[0]?.pricings?.find(
                            (item) => item.pricing_type.id === 1,
                          )?.discount_rate}
                    % OFF
                  </span>
                )}
              </p>
            </div>
          </div>
        </div>

        <div
          className="d-flex justify-content-end align-item-center mt-3"
          style={{ gap: "15px" }}
        >
          <div className={styles.selectButtonsContainer}>
            <div
              onClick={() =>
                setQuantity((prev) => (prev > 1 ? prev - 1 : prev))
              }
              className={styles.decrement}
              style={{ borderColor: primaryColor }}
            >
              -
            </div>
            <div className={styles.value}>{quantity}</div>
            <div
              onClick={() => setQuantity((prev) => prev + 1)}
              className={styles.increment}
              style={{ background: primaryColor }}
            >
              +
            </div>
            <div
              className={styles.delete}
              onClick={() => setOpenDeleteDialogue(true)}
            >
              <img src={Icon.deleteIcon} alt="" />
            </div>
          </div>
        </div>
      </motion.div>
      <secondaryComponents.Modal
        isActive={openDeleteDialogue}
        closeModal={() => setOpenDeleteDialogue(false)}
        width="450px"
      >
        <h4 className="text-center">
          Are you sure you want to remove this item from your cart?
        </h4>
        <div className="d-flex align-items-center justify-content-between mt-4">
          <primaryComponents.Button
            onClick={() => setOpenDeleteDialogue(false)}
            classNames={"btn btn--outline"}
            style={{ color: primaryColor, borderColor: primaryColor }}
          >
            Cancel
          </primaryComponents.Button>
          <primaryComponents.Button
            onClick={() => dispatch(removeFromMenuCart(product))}
            classNames={"btn btn--danger"}
            style={{ background: primaryColor }}
          >
            Remove item
          </primaryComponents.Button>
        </div>
      </secondaryComponents.Modal>
    </>
  );
};

export default ProductCart;
