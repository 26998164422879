import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartDetails: {
    data: [],
    detail: null,
    total_items: 0,
    total_items_amount: 0,
    total_amount: 0,
    vat: 0,
    vat_amount: 0,
    service_charge: 0,
    service_charge_amount: 0,
    custom_pricing_auth_pin: null,
    discount_auth_pin: null,
    delivery_fee_auth_pin: null,
    loyalty_auth_pin: null,
    giftcard_auth_pin: null,
  },
  pendingCheckoutSessions: {
    data: [],
    meta: {},
  },
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCartDetails: (state, action) => {
      state.cartDetails = action.payload;
    },
    setCustomPricingOtp: (state, action) => {
      state.cartDetails.custom_pricing_auth_pin = action.payload;
    },
    setDiscountOtp: (state, action) => {
      state.cartDetails.discount_auth_pin = action.payload;
    },
    setDeliveryOtp: (state, action) => {
      state.cartDetails.delivery_fee_auth_pin = action.payload;
    },
    setLoyaltyOtp: (state, action) => {
      state.cartDetails.loyalty_auth_pin = action.payload;
    },
    setGiftcardOtp: (state, action) => {
      state.cartDetails.giftcard_auth_pin = action.payload;
    },
    setPendingCheckoutSessions: (state, action) => {
      state.pendingCheckoutSessions = action.payload;
    },
  },
});

export const {
  setCartDetails,
  setPendingCheckoutSessions,
  setCustomPricingOtp,
  setDiscountOtp,
  setDeliveryOtp,
  setLoyaltyOtp,
  setGiftcardOtp,
} = cartSlice.actions;
