import { useContext, useState } from "react";
import { addDebt } from "../../../../../services/credit";
import { useMutation } from "react-query";
import { ToastContext } from "../../../../../hooks/context";
import { useSelector } from "react-redux";
import { cleanObject } from "../../../../../helpers";
import { useCloseOtpAuthorizationPrompt } from "../../../../../hooks/useCloseOtpAuthorizationPrompt";

const useAddDebt = ({ closeWidget, customer, onSuccess }) => {
  const triggerToast = useContext(ToastContext);
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const { closeOtpAuthorizationPrompt } = useCloseOtpAuthorizationPrompt();
  const [amount, setAmount] = useState("");
  const [repaymentDate, setRepaymentDate] = useState("");
  const [note, setNote] = useState("");
  const [store, setStore] = useState("");

  const { isLoading, mutate } = useMutation({
    mutationFn: (data) => addDebt(data),
    onSuccess: (data) => {
      if (data) onSuccessQuery(data);
    },
  });

  const handleAddDebt = () => {
    mutate(
      cleanObject({
        amount,
        store_id: store,
        customer_id: customer?.id,
        repayment_date: repaymentDate,
        note,
      }),
    );
  };

  const onSuccessQuery = (data) => {
    triggerToast(data?.data?.detail, "success");
    onSuccess();
    closeWidget();
    closeOtpAuthorizationPrompt();
  };

  return {
    isLoading,
    amount,
    repaymentDate,
    note,
    store,
    setAmount,
    setRepaymentDate,
    setNote,
    handleAddDebt,
    setStore,
    storeList,
  };
};

export default useAddDebt;
