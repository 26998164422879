import { useMutation, useQueryClient } from "react-query";
import { useContext, useEffect, useState } from "react";
import { ToastContext } from "../../../../../../../../../hooks/context";
import { useParams, useSearchParams } from "react-router-dom";
import { updateStorefrontSocialMedia } from "../../../../../../../../../services/inventory";
import { cleanObject } from "../../../../../../../../../helpers";

export const useSocialMedia = ({ menuProperties }) => {
  const queryClient = useQueryClient();
  const { menuId } = useParams();
  const triggerToast = useContext(ToastContext);
  const [searchParams] = useSearchParams();
  const menu_id = searchParams.get("menu_id");

  const [isEditing, setIsEditing] = useState(false);
  const [socialMediaDetails, setSocialMediaDetails] = useState({
    facebook: "",
    instagram: "",
    twitter: "",
    tiktok: "",
  });

  useEffect(() => {
    if (menuProperties) {
      const updatedObject = menuProperties?.social_media?.reduce(
        (acc, { key, link }) => {
          acc[key] = link;
          return acc;
        },
        {},
      );
      setSocialMediaDetails((prev) => ({ ...prev, ...updatedObject }));
    }
  }, [menuProperties]);

  const {
    isLoading: isLoadingStorefrontSocialMedia,
    mutate: mutateStorefrontSocialMedia,
  } = useMutation({
    mutationFn: (data) => updateStorefrontSocialMedia(data),
    onSuccess: () => {
      triggerToast("Storefront social media updated successfully", "success");
      queryClient.invalidateQueries(["getMenusDetails"]);
      setIsEditing((prev) => !prev);
    },
  });

  const handleSubmit = () => {
    const social_media = Object.entries(cleanObject(socialMediaDetails))?.map(
      ([key, link]) => ({ key, link }),
    );
    if (social_media && social_media?.length > 0) {
      mutateStorefrontSocialMedia({
        storefront_id: menuId ? menuId : menu_id,
        social_media,
      });
    } else {
      setIsEditing((prev) => !prev);
    }
  };

  return {
    isEditing,
    isLoadingStorefrontSocialMedia,
    socialMediaDetails,
    setIsEditing,
    setSocialMediaDetails,
    handleSubmit,
  };
};
