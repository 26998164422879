import { useState } from "react";
import { useMutation } from "react-query";
import { salesEndOfDayView } from "../../../services/sales-point";
import { cleanObject } from "../../../helpers";

const useStockHistoryStatement = () => {
  const [isEndOfDayDetails, setIsEndOfDayDetails] = useState(false);
  const [endOfDayDetails, setEndOfDayDetails] = useState();
  const [endOfDayFormDetails, setEndOfDayFormDetails] = useState({
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    stores: [],
    initiators: [],
    order_source: "",
  });

  const { isLoading: isGeneratingDetails, mutate: getEndOfDayView } =
    useMutation((data) => salesEndOfDayView(data));
  const getEndOfDayDetails = () => {
    getEndOfDayView(
      cleanObject({
        store_id: endOfDayFormDetails?.stores
          .map((item) => item?.value)
          ?.join("|"),
        start_date: endOfDayFormDetails?.startDate,
        end_date: endOfDayFormDetails?.endDate,
        start_time: endOfDayFormDetails?.startTime,
        end_time: endOfDayFormDetails?.endTime,
        initiator_id: endOfDayFormDetails?.initiators?.some(
          (item) => item?.value === "select all",
        )
          ? []
          : endOfDayFormDetails?.initiators
              ?.map((item) => item?.value)
              ?.join("|"),
        order_source: endOfDayFormDetails?.order_source?.value,
      }),
      {
        onSuccess: (res) => {
          setEndOfDayDetails(res?.data?.data);
          setIsEndOfDayDetails(true);
        },
      },
    );
  };

  return {
    isEndOfDayDetails,
    endOfDayDetails,
    endOfDayFormDetails,
    isGeneratingDetails,
    setEndOfDayFormDetails,
    getEndOfDayDetails,
    setEndOfDayDetails,
    setIsEndOfDayDetails,
  };
};
export default useStockHistoryStatement;
