import "./OtpInputField.scss";

import OtpInput from "react-otp-input";

const OtpInputField = ({
  name,
  value,
  numOfInputs = 4,
  onChange,
  secret = false,
}) => {
  return (
    <div className="otp-input__field">
      <OtpInput
        className="otp-input__field"
        value={value}
        name={name}
        numInputs={numOfInputs}
        isInputNum={true}
        shouldAutoFocus={true}
        isInputSecure={secret}
        inputMode="numeric"
        onChange={onChange}
      />
    </div>
  );
};

export default OtpInputField;
