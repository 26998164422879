import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  calculateCartItem,
  deepCopyFunction,
  encodeToBase64,
  findPricingViaIdHelper,
  truncateString,
} from "../../../helpers";
import { useParams } from "react-router-dom";
import { ToastContext } from "../../../hooks/context";
import { useGetOrderActionDetails } from "../../../hooks/api/queries";
import { useCloseOtpAuthorizationPrompt } from "../../../hooks/useCloseOtpAuthorizationPrompt";
import { setVerifiedOTPCode } from "../../../store/modules/apiErrorHandler";
import { setCustomPricingOtp } from "../../../store/modules/storeCart";

const useProductCarting = ({
  custom_price,
  addCartItemToLocalStorage,
  product,
  pricing_type_id,
  cartIndex,
}) => {
  const { store_id } = useParams();
  const [tagIsActive, setTagIsActive] = useState(false);
  const dispatch = useDispatch();
  const { closeOtpAuthorizationPrompt } = useCloseOtpAuthorizationPrompt();
  const [isValue, setIsValue] = useState();
  const [customValue, setCustomValue] = useState(custom_price || "");
  const {
    data: cartItems,
    vat,
    service_charge,
  } = useSelector((state) => state.cartReducer.cartDetails);
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const verified_otp_code = useSelector(
    (state) => state.apiErrorReducer?.verifiedOTPCode,
  );

  const storeProperties = storeList?.find(
    (item) => `${item?.id}` === `${store_id}`,
  );
  const triggerToast = useContext(ToastContext);
  const [pricingTypeId, setPricingTypeId] = useState(pricing_type_id);

  const addCustomPricing = (position) => {
    if (!storeProperties?.allow_custom_price)
      return triggerToast(
        "Activate this feature in your store settings or contact your store admin for assistance",
        "warning",
      );

    if (isValue === "")
      return triggerToast("Please input a value for custom pricing", "warning");

    const newCartItems = deepCopyFunction(cartItems);
    newCartItems[position].custom_price = Number(isValue);
    newCartItems[position].total_price =
      Number(isValue) * newCartItems[position]?.quantity;

    const data = calculateCartItem(newCartItems, vat, service_charge);
    addCartItemToLocalStorage({ ...data });
    triggerToast(
      `Custom Pricing set for item: (${truncateString(
        newCartItems?.[position]?.cart_item_name,
        40,
      )})`,
      "success",
    );
    setTagIsActive(false);
  };

  const { refetch: fetchOrderActionDetails, isLoading: isLoadingAuthAction } =
    useGetOrderActionDetails({
      store_id: store_id,
      action: "use_custom_pricing_on_order",
      enabled: false,
      onSuccess: () => {
        setCustomValue(isValue);
        addCustomPricing(cartIndex);
        dispatch(setCustomPricingOtp(encodeToBase64(verified_otp_code)));
        dispatch(setVerifiedOTPCode(null));
        closeOtpAuthorizationPrompt();
      },
    });

  const changePricingType = (position, pricingTypeId) => {
    const pricingDetails = findPricingViaIdHelper(pricingTypeId, pricings());
    const unitPrice = (
      pricingDetails?.price -
      (pricingDetails?.price * pricingDetails?.discount_rate) / 100
    )?.toFixed(2);

    const newCartItems = deepCopyFunction(cartItems);
    newCartItems[position].pricing_type_id = pricingTypeId;
    newCartItems[position].total_price = (
      unitPrice * Number(newCartItems[position]?.quantity)
    )?.toFixed(2);
    newCartItems[position].unit_price = unitPrice;
    newCartItems[position].discount_rate = pricingDetails?.discount_rate;
    newCartItems[position].total_price_without_discount = (
      pricingDetails?.price * Number(newCartItems[position]?.quantity)
    )?.toFixed(2);
    delete newCartItems?.[position]?.custom_price;
    const data = calculateCartItem(newCartItems, vat, service_charge);
    addCartItemToLocalStorage({ ...data });
  };

  const pricings = () => {
    let pricings = null;
    if (product?.variant) {
      pricings = product?.variant?.store_variant_properties?.[0]?.pricings;
    } else {
      pricings =
        product?.[
          product?.is_service
            ? "store_service_properties"
            : "store_product_properties"
        ]?.[0]?.pricings;
    }
    return pricings;
  };

  return {
    tagIsActive,
    customValue,
    pricingTypeId,
    pricings,
    setPricingTypeId,
    setCustomValue,
    setTagIsActive,
    addCustomPricing,
    changePricingType,
    fetchOrderActionDetails,
    isLoadingAuthAction,
    setIsValue,
    isValue,
  };
};
export default useProductCarting;
