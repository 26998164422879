import "./KYBVerification.scss";
import { useSelector } from "react-redux";
import secondaryComponents from "../../../components/secondaryComponents";
import { Controller } from "react-hook-form";
import KYBFormValidation from "./KYBFormValidation";
import KYBVerificationLogic from "./KYBVerificationLogic";
import { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { pathConstants } from "../../../routes/pathContants";

const KYBVerification = () => {
  const { first_name } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails,
  );
  const navigate = useNavigate();

  const {
    business_type,
    setBusinessType,
    handleDocumentUpload,
    fetchDocuments,
    isFetchingDocuments,
    verificationData,
    step,
    navigation,
  } = KYBVerificationLogic();
  const { control } = KYBFormValidation(business_type);

  useEffect(() => {
    if (
      verificationData?.verification_status === "verified" ||
      verificationData?.verification_status === "pending_review"
    ) {
      return navigate(pathConstants.DASHBOARD_MAIN);
    }
  }, []);

  return (
    <div className="kyb-verification-page">
      {step === 0 && (
        <Fragment>
          <h3 className="font-weight-semibold">Verify Your Business</h3>
          <p>Hi, {first_name}. Let&quot;s set up your account real quick</p>
          <div className="kyb-verification-page__form__header mt-4">
            <h4 className="kyb-verification-page__form__header__title font-weight-semibold">
              Select an option to create your wallet
            </h4>
            <div className="kyb-verification-page__form__header-options">
              {/* Individual */}
              {/* <Controller
                control={control}
                name="business_type"
                defaultValue={business_type}
                render={({ field: { onChange } }) => (
                  <secondaryComponents.WalletOption
                    title="Individuals"
                    subtitle="Use your personal Information and BVN"
                    name="business_type"
                    value="individual"
                    checked={business_type === "individual"}
                    onChange={(option) => {
                      onChange(option);
                      setBusinessType(option);
                    }}
                  />
                )}
              /> */}
              {/* Business Name */}
              <Controller
                control={control}
                name="business_type"
                defaultValue={business_type}
                render={({ field: { onChange } }) => (
                  <secondaryComponents.WalletOption
                    title="Business Name"
                    subtitle="Use certificate of registration of name"
                    name="business_type"
                    value="SoleProprietor"
                    checked={business_type === "SoleProprietor"}
                    onChange={(option) => {
                      onChange(option);
                      setBusinessType(option);
                    }}
                  />
                )}
              />
              {/* LLC */}
              <Controller
                control={control}
                name="business_type"
                defaultValue={business_type}
                render={({ field: { onChange } }) => (
                  <secondaryComponents.WalletOption
                    title="Limited Liability Company"
                    subtitle="Use your limited liability registration docs"
                    name="business_type"
                    value="LLC"
                    checked={business_type === "LLC"}
                    onChange={(option) => {
                      onChange(option);
                      setBusinessType(option);
                    }}
                  />
                )}
              />
            </div>
            <div className="kyb-verification-page__form__content">
              <secondaryComponents.LoaderHelper
                isLoading={isFetchingDocuments}
                classNames="mt-4"
              >
                {/* {business_type === "individual" && (
                  <secondaryComponents.IndividualKYBForm
                    verificationData={verificationData}
                    uploadDocument={handleDocumentUpload}
                    navigation={navigation}
                    fetchDocuments={fetchDocuments}
                  />
                )} */}
                {business_type === "SoleProprietor" && (
                  <secondaryComponents.SoleProprietorKYBForm
                    verificationData={verificationData}
                    uploadDocument={handleDocumentUpload}
                    navigation={navigation}
                    fetchDocuments={fetchDocuments}
                  />
                )}
                {business_type === "LLC" && (
                  <secondaryComponents.LLCKYBForm
                    verificationData={verificationData}
                    uploadDocument={handleDocumentUpload}
                    navigation={navigation}
                    fetchDocuments={fetchDocuments}
                  />
                )}
              </secondaryComponents.LoaderHelper>
            </div>
          </div>
        </Fragment>
      )}

      {step === 1 && <secondaryComponents.KYBVerificationResponseWidget />}
    </div>
  );
};

export default KYBVerification;
