import primaryComponents from "../../primaryComponents";

const OrderCancel = ({
  orderDetails,
  closeWidget,
  cancelCurrentOrder,
  loadingText = "Cancelling...",
  type = "bill",
  pendCheckout,
}) => {
  return (
    <div className="py-3 px-3">
      <h4 className="text-center font-weight-semibold">
        {type === "order"
          ? "Are you sure you want to permanently cancel this order ?"
          : "Are you sure you want to cancel this bill"}
      </h4>
      <div
        style={{
          backgroundColor: "#F3F1FC",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          borderRadius: "8px",
          margin: "20px 0",
        }}
      >
        <p
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p className="dark--text mb-1 normal-text-size">
            {type === "order"
              ? "Cancelling this order is permanent and cannot be undone. Alternatively, you can place this order on hold and check out later. Please choose an option"
              : "Order Number"}
          </p>
          {type === "order" ? null : <span>{orderDetails?.id}</span>}
        </p>
      </div>

      <div
        className="d-flex justify-content-between mt-2"
        style={{ gap: "20px" }}
      >
        <primaryComponents.Button
          onClick={() => {
            if (type === "order") {
              pendCheckout();
              closeWidget();
            }
            closeWidget();
          }}
          classNames={"btn btn--outline cover"}
        >
          {type === "order" ? "Place Order on Hold" : "Cancel"}
        </primaryComponents.Button>
        <primaryComponents.Button
          onClick={() => {
            cancelCurrentOrder(orderDetails?.id);
            closeWidget();
          }}
          classNames={"btn btn--danger cover"}
          loadingText={loadingText}
        >
          {type === "order" ? "Cancel Order Permanently" : "Continue"}
        </primaryComponents.Button>
      </div>
    </div>
  );
};
export default OrderCancel;
