import { useOutletContext } from "react-router-dom";
import Icon from "../../../../../../../../../assets/icons";
import primaryComponents from "../../../../../../../../../components/primaryComponents";
import styles from "./styles.module.scss";
import { useSocialMedia } from "./useSocialMedia";

const SocialMedia = () => {
  const { menuProperties } = useOutletContext();
  const {
    isLoadingStorefrontSocialMedia,
    isEditing,
    socialMediaDetails,
    setIsEditing,
    setSocialMediaDetails,
    handleSubmit,
  } = useSocialMedia({ menuProperties });

  return (
    <div className={styles.container}>
      <div className={styles.socialHeading}>
        <div className={styles.social}>
          <img src={Icon.socialMedia} alt="" />
          <div>
            <h5>Social Media</h5>
            <p>Add social media links to your storefront</p>
          </div>
        </div>
        <primaryComponents.Button
          classNames="btn btn--primary"
          onClick={() => {
            if (isEditing) {
              handleSubmit();
            } else {
              setIsEditing(true);
            }
          }}
          loadingText={"Saving..."}
          isLoading={isLoadingStorefrontSocialMedia}
          isDisabled={isLoadingStorefrontSocialMedia}
        >
          <span>{isEditing ? "Save" : "Edit"}</span>
        </primaryComponents.Button>
      </div>
      {isEditing && (
        <div className={styles.socialContainer}>
          <div className={styles.socialItem}>
            <p>Facebook</p>
            <primaryComponents.InputField
              label=""
              placeholder="Insert link here e.g https://www.facebook.com"
              name="facebook"
              value={socialMediaDetails.facebook}
              onChange={({ target: { value } }) => {
                setSocialMediaDetails((prev) => ({
                  ...prev,
                  facebook: value,
                }));
              }}
            />
          </div>
          <div className={styles.socialItem}>
            <p>Instagram</p>
            <primaryComponents.InputField
              label=""
              placeholder="Insert link here e.g https://www.instagram.com"
              name="instagram"
              value={socialMediaDetails.instagram}
              onChange={({ target: { value } }) => {
                setSocialMediaDetails((prev) => ({
                  ...prev,
                  instagram: value,
                }));
              }}
            />
          </div>
          <div className={styles.socialItem}>
            <p>Twitter(X)</p>
            <primaryComponents.InputField
              label=""
              placeholder="Insert link here e.g https://www.twitter.com"
              name="twitter"
              value={socialMediaDetails.twitter}
              onChange={({ target: { value } }) => {
                setSocialMediaDetails((prev) => ({
                  ...prev,
                  twitter: value,
                }));
              }}
            />
          </div>
          <div className={styles.socialItem}>
            <p>Tiktok</p>
            <primaryComponents.InputField
              label=""
              placeholder="Insert link here e.g https://www.tiktok.com"
              name="tiktok"
              value={socialMediaDetails.tiktok}
              onChange={({ target: { value } }) => {
                setSocialMediaDetails((prev) => ({
                  ...prev,
                  tiktok: value,
                }));
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SocialMedia;
