import primaryComponents from "../../primaryComponents";
import LoaderHelper from "../LoaderHelper/LoaderHelper";
import "./Authorization.scss";
import useAuthorizationLogic from "./useAuthorizationLogic";
import Validation from "./Validation";
import secondaryComponents from "..";

const AuthorizationModal = ({
  action,
  object_id,
  isApprovalMessage,
  object_type,
}) => {
  const {
    isLoadingAuthUsers,
    authUsers,
    isAuthUserSelected,
    otp,
    currentStep,
    isLoading,
    otp_code,
    resendRequest,
    sendAuthorisationPin,
    setIsAuthUserSelected,
  } = useAuthorizationLogic({
    action,
    object_id,
    object_type,
  });

  const {
    control,
    formState: { errors },
  } = Validation({ authUsers });

  return (
    <div className="login-verification-page">
      <div className="login-verification-page__container">
        <h6 className={"purple--text text-center"}>
          {currentStep === 1
            ? isApprovalMessage
            : "Enter Manager Authorization Code"}
        </h6>

        {currentStep === 1 && (
          <div
            className="d-flex align-items-center justify-content-center m-4"
            style={{ width: "90%" }}
          >
            <LoaderHelper isLoading={isLoadingAuthUsers || !authUsers}>
              <primaryComponents.SelectFieldV2
                name="authUser"
                control={control}
                label="Select Manager for Authorization"
                value={isAuthUserSelected || "Select Manager for Authorization"}
                options={authUsers}
                nameKey="full_name"
                idKey="id"
                classNames="w-100 white"
                onChange={(value) => {
                  setIsAuthUserSelected(value);
                }}
                isDisabled={isLoadingAuthUsers}
                errorMessage={errors.authUser}
              />
            </LoaderHelper>
          </div>
        )}

        {currentStep === 2 && (
          <form className="verification__form">
            <secondaryComponents.LoaderHelper isLoading={otp_code}>
              <primaryComponents.OtpInputField
                numOfInputs={4}
                onChange={resendRequest}
                value={otp}
                secret={true}
              />
            </secondaryComponents.LoaderHelper>
          </form>
        )}

        {currentStep === 1 && (
          <primaryComponents.Button
            classNames={"btn btn--primary cover mx-auto"}
            onClick={sendAuthorisationPin}
            disabled={isLoading || !isAuthUserSelected}
            isLoading={isLoading}
            loadingText={"Sending"}
          >
            Next
          </primaryComponents.Button>
        )}
      </div>
    </div>
  );
};

export default AuthorizationModal;
