import globalStyles from "../../../assets/styles/base/globalPage.module.scss";
import primaryComponents from "../../primaryComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import secondaryComponents from "..";
import {
  DocumentationIcon,
  LogoutIcon,
  MarketsIcon,
  SettingIcon,
} from "../../../assets/svgIcons";
import { getPlatform, logoutHandler } from "../../../helpers";
import styles from "./MoreModal.module.scss";
import React, { useContext, useState } from "react";
import { pathConstants } from "../../../routes/pathContants";
import { matchPath, useNavigate, useLocation } from "react-router-dom";
import { platformHandler } from "../../../hooks/context";

const MoreOption = ({ closeModal }) => {
  const [isSetting, setIsSetting] = useState(true);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { platform } = useContext(platformHandler);
  const toggleSettingDropdown = () => {
    setIsSetting((prev) => !prev);
  };

  const handleMenuClick = (path) => {
    navigate(path);
    closeModal();
  };

  return (
    <div>
      <h5 className="p-2">More</h5>
      <hr />
      <div className={`${globalStyles.pageColumnGaps} p-2`}>
        <div
          className={`${styles.hoverEffect}`}
          onClick={(e) => {
            e.stopPropagation();
            window.open(
              "https://enlumidatainc.zohodesk.com/portal/en/kb/enlumi-data-inc/lumi-business",
            );
          }}
          style={{ cursor: "pointer" }}
        >
          <DocumentationIcon />
          <span className="small-text-size m-1">Documentation</span>
        </div>
        <div
          className={`${styles.hoverEffect}`}
          onClick={(e) => {
            e.stopPropagation();
            navigate(pathConstants.APPS_MAIN);
            closeModal();
          }}
          style={{ cursor: "pointer" }}
        >
          <MarketsIcon
            strokeColor={
              matchPath(getPlatform(platform) + "/markets/*", pathname)
                ? "#7647ee"
                : undefined
            }
          />
          <span className="small-text-size m-1">Apps</span>
        </div>

        <div className={"d-flex justify-content-between"}>
          <div
            className={`${styles.hoverEffect}`}
            onClick={toggleSettingDropdown}
          >
            <SettingIcon
              strokeColor={
                matchPath(getPlatform(platform) + "/settings/*", pathname)
                  ? "#7647ee"
                  : undefined
              }
            />
            <span
              className={`${
                matchPath(getPlatform(platform) + "/settings/*", pathname)
                  ? "purple--text"
                  : "dark--text"
              } small-text-size m-1`}
            >
              Settings
            </span>
          </div>

          <primaryComponents.Button
            classNames={`btn btn--outline ${styles.icon}`}
            onClick={toggleSettingDropdown}
          >
            <FontAwesomeIcon
              className={`${isSetting ? "purple--text" : "dark--text"}`}
              icon={`${isSetting ? "angle-up" : "angle-down"}`}
              fontSize={"12px"}
            />
          </primaryComponents.Button>
        </div>
        {isSetting && (
          <div className={`${globalStyles.pageColumnGaps} ml-5`}>
            <span
              className={`${styles.hoverEffect} small-text-size`}
              onClick={() => handleMenuClick(pathConstants.SETTINGS_PROFILE)}
            >
              Profiles
            </span>
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Settings"}
              permissionChildKey={"can_create_update_and_invite_users"}
            >
              <span
                className={`${styles.hoverEffect} small-text-size`}
                onClick={() => handleMenuClick(pathConstants.SETTINGS_USERS)}
              >
                Users
              </span>
            </secondaryComponents.NavigateWithPermission>
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Settings"}
              permissionChildKey={"can_edit_and_update_settlement_accounts"}
            >
              <span
                className={`${styles.hoverEffect} small-text-size`}
                onClick={() =>
                  handleMenuClick(pathConstants.SETTINGS_SETTLEMENTS)
                }
              >
                Settlements
              </span>
            </secondaryComponents.NavigateWithPermission>
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Settings"}
              permissionChildKey={"can_view_update_api_key"}
            >
              <span
                className={`${styles.hoverEffect} small-text-size`}
                onClick={() => handleMenuClick(pathConstants.SETTINGS_APIKEYS)}
              >
                API
              </span>
            </secondaryComponents.NavigateWithPermission>
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Settings"}
              permissionChildKey={"can_update_subscriptions"}
            >
              <span
                className={`${styles.hoverEffect} small-text-size`}
                onClick={() =>
                  handleMenuClick(pathConstants.SETTINGS_SUBSCRIPTIONS)
                }
              >
                Subscriptions
              </span>
            </secondaryComponents.NavigateWithPermission>
          </div>
        )}

        <div className={`${styles.hoverEffect}`} onClick={logoutHandler}>
          <LogoutIcon />
          <span className="small-text-size mt-0 ml-1">Log out</span>
        </div>
      </div>
    </div>
  );
};

export default MoreOption;
