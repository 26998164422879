import { useContext, useState } from "react";
import { ToastContext } from "../../../../hooks/context";
import { warehouseCheckout } from "../../../../services/sales-point";
import { useMutation } from "react-query";
import { useCloseOtpAuthorizationPrompt } from "../../../../hooks/useCloseOtpAuthorizationPrompt";

const WarehouseCheckoutPaymentLogic = ({ closePaymentSession }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const orderId = queryParams.get("order_id");
  const triggerToast = useContext(ToastContext);
  const { closeOtpAuthorizationPrompt } = useCloseOtpAuthorizationPrompt();
  const [isConfirmingPayment, setIsConfirmingPayment] = useState(false);

  const { mutate: updateWarehouse, isLoading: isUpdating } = useMutation(
    (data) => warehouseCheckout(data),
  );

  const payViaWarehouseCheckout = () => {
    setIsConfirmingPayment(true);
    updateWarehouse(
      { order_id: orderId },
      {
        onSuccess: () => {
          setIsConfirmingPayment(false);
          triggerToast("Payment confirmation successful", "success");
          closePaymentSession();
          closeOtpAuthorizationPrompt();
        },
        onError: (error) => {
          setIsConfirmingPayment(false);
          if (error?.response === undefined) return;
        },
      },
    );
  };

  return {
    isConfirmingPayment,
    payViaWarehouseCheckout,
    isUpdating,
  };
};
export default WarehouseCheckoutPaymentLogic;
