import { useState } from "react";
import primaryComponents from "../../primaryComponents";
import { useGetOrderActionDetails } from "../../../hooks/api/queries";
import { useParams } from "react-router-dom";
import { useCloseOtpAuthorizationPrompt } from "../../../hooks/useCloseOtpAuthorizationPrompt";
import { useDispatch, useSelector } from "react-redux";
import { setDeliveryOtp } from "../../../store/modules/storeCart";
import { setVerifiedOTPCode } from "../../../store/modules/apiErrorHandler";
import { encodeToBase64 } from "../../../helpers";
const DeliveryFee = ({
  setDeliveryFee,
  deliveryFee,
  currency_symbol,
  closeModal,
}) => {
  const [deliveryVal, setDeliveryVal] = useState(deliveryFee);
  const { store_id } = useParams();
  const verified_otp_code = useSelector(
    (state) => state.apiErrorReducer?.verifiedOTPCode,
  );
  const dispatch = useDispatch();
  const { closeOtpAuthorizationPrompt } = useCloseOtpAuthorizationPrompt();
  const { refetch: fetchOrderActionDetails, isLoading: isLoadingAuthAction } =
    useGetOrderActionDetails({
      store_id: store_id,
      action: "add_delivery_fee_to_order",
      enabled: false,
      onSuccess: () => {
        dispatch(setDeliveryOtp(encodeToBase64(verified_otp_code)));
        dispatch(setVerifiedOTPCode(null));
        setDeliveryFee(deliveryVal);
        closeModal();
        closeOtpAuthorizationPrompt();
      },
    });

  return (
    <>
      <div
        className="p-3"
        style={{ display: "flex", flexDirection: "column", gap: "25px" }}
      >
        <h4>Apply Delivery</h4>

        <div
          style={{
            border: "1px solid #ddd",
            padding: "8px",
            borderRadius: "4px",
          }}
        >
          <primaryComponents.InputField
            label={"Amount"}
            classNames="white"
            placeholder={`Enter delivery fee e.g. ${currency_symbol}1000`}
            isRequired={false}
            value={deliveryVal}
            type="number"
            min="0"
            name="deliveryFee"
            floatError={false}
            onChange={({ target: { value } }) => {
              setDeliveryVal(value);
            }}
          />
        </div>

        <primaryComponents.Button
          classNames={"btn btn--primary cover"}
          onClick={() => {
            fetchOrderActionDetails();
          }}
          isLoading={isLoadingAuthAction}
          isDisabled={isLoadingAuthAction}
        >
          Apply
        </primaryComponents.Button>
      </div>
    </>
  );
};
export default DeliveryFee;
