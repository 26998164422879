import { useOutletContext } from "react-router-dom";
import Icon from "../../../../../../../../../assets/icons";
import primaryComponents from "../../../../../../../../../components/primaryComponents";
import SortCategories from "../../../SortCategories";
import styles from "./styles.module.scss";
import { useSortCategories } from "./useSortCategories";

const SortStorefrontCategories = () => {
  const {
    isFetchingMenuCategories,
    sortMenuCategories,
    setSortMenuCategories,
  } = useOutletContext();
  const {
    isLoadingSortCategories,
    isEditing,
    setIsEditing,
    mutateSortCategories,
  } = useSortCategories();

  return (
    <div className={styles.container}>
      <div className={styles.sortHeading}>
        <div className={styles.sortItem}>
          <img src={Icon.sort} alt="" />
          <div>
            <h5>Sort Storefront Categories</h5>
            <p>
              Arrange categories by dragging and dropping them into your
              preferred order.
            </p>
          </div>
        </div>
        <primaryComponents.Button
          classNames="btn btn--primary"
          onClick={() => {
            if (isEditing) {
              mutateSortCategories({
                storefront_category_ids: sortMenuCategories.map(
                  (item) => `${item?.id}`,
                ),
              });
            } else {
              setIsEditing(true);
            }
          }}
          loadingText={"Saving..."}
          isLoading={isLoadingSortCategories}
          isDisabled={isLoadingSortCategories}
        >
          <span>{isEditing ? "Save" : "Edit"}</span>
        </primaryComponents.Button>
      </div>
      {isEditing && (
        <SortCategories
          isFetchingMenuCategories={isFetchingMenuCategories}
          sortMenuCategories={sortMenuCategories}
          setSortMenuCategories={setSortMenuCategories}
        />
      )}
    </div>
  );
};

export default SortStorefrontCategories;
