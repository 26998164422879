import Images from "../../../../../../../../../assets/images";
import TemplateIcon from "../../../../../../../../../assets/svgIcons/TemplateIcon";
import styles from "./styles.module.scss";
import primaryComponents from "../../../../../../../../../components/primaryComponents";
import secondaryComponents from "../../../../../../../../../components/secondaryComponents";
import { LuEye } from "react-icons/lu";
import { RiEditLine } from "react-icons/ri";
import { FaCheckCircle } from "react-icons/fa";
import { useOutletContext } from "react-router-dom";
import Branding from "../Branding";
import SortStorefrontCategories from "../SortStorefrontCategories";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTemplate } from "./useTemplate";
import SocialMedia from "../SocialMedia";

const Templates = () => {
  const { menuProperties } = useOutletContext();
  const {
    menuId,
    showSideModal,
    isLoadingApplyTemplate,
    navigateToStorefrontSettings,
    setShowSideModal,
    mutateApplyTemplate,
  } = useTemplate();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.heading}>
          <div>
            <TemplateIcon />
          </div>
          <p>
            Choose your preferred templates and customize them to meet your
            business vision.
          </p>
        </div>
        <hr />
        <div className={styles.templateContainer}>
          <div className={styles.template}>
            <div className={styles.templateTopContainer}>
              <div className={styles.left}>
                <h5>Restaurant</h5>
                {menuProperties?.template_in_use === "template-2" && (
                  <div className={styles.inUse}>Currently in use</div>
                )}
              </div>
              <div className={styles.right}>
                {menuProperties?.template_in_use === "template-2" ? (
                  <div className={styles.applied}>
                    <FaCheckCircle color="#7647EE" />
                    <p>Applied</p>
                  </div>
                ) : (
                  <primaryComponents.Button
                    classNames={"btn btn--primary smallBtn"}
                    style={{ width: 116 }}
                    onClick={() => {
                      mutateApplyTemplate({
                        storefront_id: menuId,
                        template_in_use: "template-2",
                      });
                    }}
                    isDisabled={isLoadingApplyTemplate}
                  >
                    {isLoadingApplyTemplate ? "Applying..." : "Apply"}
                  </primaryComponents.Button>
                )}
                <primaryComponents.Button
                  classNames={"btn btn--outline smallBtn"}
                  style={{ width: 32, height: 30 }}
                  onClick={() => setShowSideModal(true)}
                >
                  <RiEditLine size={20} />
                </primaryComponents.Button>
                <primaryComponents.Button
                  classNames={"btn btn--outline smallBtn"}
                  style={{ width: 32, height: 30 }}
                  onClick={() =>
                    window.open(
                      `${window.location.origin}/storefront/${
                        menuProperties?.slug_url?.split("/storefront/")[1]
                      }`,
                      "_blank",
                    )
                  }
                >
                  <LuEye size={20} />
                </primaryComponents.Button>
              </div>
            </div>
            <div>
              <img src={Images.template1} alt="" />
            </div>
          </div>
          <div className={styles.template}>
            <div className={styles.templateTopContainer}>
              <div className={styles.left}>
                <h5>E-commerce</h5>
                {menuProperties?.template_in_use === "template-1" && (
                  <div className={styles.inUse}>Currently in use</div>
                )}
              </div>
              <div className={styles.right}>
                {menuProperties?.template_in_use === "template-1" ? (
                  <div className={styles.applied}>
                    <FaCheckCircle color="#7647EE" />
                    <p>Applied</p>
                  </div>
                ) : (
                  <primaryComponents.Button
                    classNames={"btn btn--primary smallBtn"}
                    style={{ width: 116 }}
                    onClick={() => {
                      if (!menuProperties?.template) {
                        navigateToStorefrontSettings(menuId);
                      } else {
                        mutateApplyTemplate({
                          storefront_id: menuId,
                          template_in_use: "template-1",
                        });
                      }
                    }}
                    isDisabled={isLoadingApplyTemplate}
                  >
                    {isLoadingApplyTemplate ? "Applying..." : "Apply"}
                  </primaryComponents.Button>
                )}
                <primaryComponents.Button
                  classNames={"btn btn--outline smallBtn"}
                  style={{ width: 32, height: 30 }}
                  onClick={() => navigateToStorefrontSettings(menuId)}
                >
                  <RiEditLine size={20} />
                </primaryComponents.Button>
                <primaryComponents.Button
                  classNames={"btn btn--outline smallBtn"}
                  style={{ width: 32, height: 30 }}
                  onClick={() => {
                    if (!menuProperties?.template) {
                      navigateToStorefrontSettings(menuId);
                    } else {
                      window.open(
                        `${process.env.REACT_APP_LUMI_STOREFRONT_APP_BASE_URL}/NG?sfid=${menuId}`,
                        "_blank",
                      );
                    }
                  }}
                >
                  <LuEye size={20} />
                </primaryComponents.Button>
              </div>
            </div>
            <div>
              <img src={Images.template2} alt="" />
            </div>
          </div>
        </div>
      </div>
      <secondaryComponents.SideModal
        isActive={showSideModal}
        closeModal={() => setShowSideModal(false)}
        width={938}
      >
        <div className={styles.modalContainer}>
          <div className={styles.modalHeading}>
            <primaryComponents.Button
              classNames="btn btn--outline smallBtn"
              style={{ width: 48, height: 48 }}
              onClick={() => setShowSideModal(false)}
            >
              <FontAwesomeIcon icon="angle-left" style={{ fontSize: "18px" }} />
            </primaryComponents.Button>
            <div>
              <h4>Edit Restaurant</h4>
              <p>
                Customize your template to align with your business goals &
                brand.
              </p>
            </div>
          </div>
          <div className={styles.modalContent}>
            <Branding />
            <SortStorefrontCategories />
            <SocialMedia />
          </div>
          <div className={styles.modalButtonContainer}>
            <primaryComponents.Button
              classNames={"btn btn--primary"}
              onClick={() =>
                window.open(
                  `${window.location.origin}/storefront/${
                    menuProperties?.slug_url?.split("/storefront/")[1]
                  }`,
                  "_blank",
                )
              }
            >
              Preview
            </primaryComponents.Button>
          </div>
        </div>
      </secondaryComponents.SideModal>
    </>
  );
};

export default Templates;
