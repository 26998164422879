import { DAYS_OF_WEEK, MONTHS_OF_YEAR } from "./constants";
import { v4 as uuidv4 } from "uuid";

export const invertDateFormat = (date) => {
  if (date !== "") {
    let dArr = date.split("-");
    return dArr[2] + "-" + dArr[1] + "-" + dArr[0];
  }
  return date;
};

export const findObject = (ArrayOfObject, value) => {
  // eslint-disable-next-line array-callback-return
  let object = ArrayOfObject.find((item, index) => {
    if (item.my_route === value.replace(/\s/g, "")) {
      return ArrayOfObject[index];
    }
  });
  return object;
};

export const getUrlQuerysection = () => {
  let queries = window.location.search;
  return queries;
};

// this format numbers into kilo, Million, Billion ...
export const nFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
};

export const generateRandomNumber = () => {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return "_" + Math.random().toString(36).substr(2, 9);
};
export const getRandomIntInclusive = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min; // max & min both included
};

export const decodeToken = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(""),
  );

  return JSON.parse(jsonPayload);
};

export const logoutHandler = () => {
  // handles logout
  localStorage.removeItem("refreshToken");
  window.location.replace(`${window.location.origin}/login`);
};

// export const formatCurrency = (value, currencyCode, decimal = 2) => {
//   if (isNaN(value)) return "0.00";
//   return new Intl.NumberFormat("en-US", {
//     style: "currency",
//     currency: currencyCode,
//     minimumFractionDigits: decimal,
//     currencyDisplay: "symbol",
//   }).format(value);
// };

export const formatCurrency = (
  currencyParams = { decimal: 2, currencyCode: "NGN" },
) => {
  if (isNaN(currencyParams?.value)) return "0.00";
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyParams?.currencyCode || "NGN",
    minimumFractionDigits: currencyParams?.decimal || 2,
    currencyDisplay: "symbol",
  }).format(currencyParams?.value);
};

export const last7Days = () => {
  return "06".split("").map(function (n) {
    var d = new Date();
    d.setDate(d.getDate() - n);

    return (function (day, month, year) {
      return [
        day < 10 ? "0" + day : day,
        month < 10 ? "0" + month : month,
        year,
      ].join("-");
    })(d.getFullYear(), d.getMonth() + 1, d.getDate());
  });
};

export const last30Days = () => {
  return "0,29".split(",").map(function (n) {
    var d = new Date();
    d.setDate(d.getDate() - n);

    return (function (day, month, year) {
      return [
        day < 10 ? "0" + day : day,
        month < 10 ? "0" + month : month,
        year,
      ].join("-");
    })(d.getFullYear(), d.getMonth() + 1, d.getDate());
  });
};

export const last3Months = () => {
  return "0,90".split(",").map(function (n) {
    var d = new Date();
    d.setDate(d.getDate() - n);

    return (function (day, month, year) {
      return [
        day < 10 ? "0" + day : day,
        month < 10 ? "0" + month : month,
        year,
      ].join("-");
    })(d.getFullYear(), d.getMonth() + 1, d.getDate());
  });
};

export const lastYear = () => {
  return "0,364".split(",").map(function (n) {
    var d = new Date();
    d.setDate(d.getDate() - n);

    return (function (day, month, year) {
      return [
        day < 10 ? "0" + day : day,
        month < 10 ? "0" + month : month,
        year,
      ].join("-");
    })(d.getFullYear(), d.getMonth() + 1, d.getDate());
  });
};

export const getStartAndDate = (value) => {
  if (value === "last 7 days") {
    let date = last7Days();
    return {
      startDate: date[1],
      endDate: date[0],
    };
  }

  if (value === "last 30 days") {
    let date = last30Days();
    return {
      startDate: date[1],
      endDate: date[0],
    };
  }

  if (value === "last 3 months") {
    let date = last3Months();
    return {
      startDate: date[1],
      endDate: date[0],
    };
  }

  if (value === "1 year") {
    let date = lastYear();
    return {
      startDate: date[1],
      endDate: date[0],
    };
  }

  return {
    startDate: "",
    endDate: "",
  };
};

export const getDaysBetweenDate = (startDate, endDate) => {
  let date1 = new Date(startDate?.replace("-", "/"));
  let date2 = new Date(endDate?.replace("-", "/"));

  // To calculate the time difference of two dates
  let differenceInTime = date2.getTime() - date1.getTime();

  // To calculate the no. of days between two dates
  let differenceInDays = differenceInTime / (1000 * 3600 * 24);

  return differenceInDays;
};

export const getDateMargin = (startDate, endDate) => {
  let daysBetween = getDaysBetweenDate(startDate, endDate);

  if (daysBetween === 6) return "last 7 days";

  if (daysBetween > 6 && daysBetween <= 29) return "last 30 days";

  if (daysBetween > 29 && daysBetween <= 89) return "last 3 months";

  if (daysBetween > 89 && daysBetween <= 365) return "1 year";

  return "";
};

export const convertToTilteCase = (words) => {
  return words?.replace(/\w\S*/g, function (txt) {
    return txt?.charAt(0)?.toUpperCase() + txt?.substr(1)?.toLowerCase();
  });
};

export const mapUserRole = {
  BUSINESS_MANAGER: "Business Manager",
  ADMIN: "Admin",
  OWNER: "Owner",
  DEVELOPER: "Developer",
  CUSTOMER_SUPPORT: "Customer Support",
  CASHIER: "Cashier",
};

export const mask = (str, maskChar, unmaskedLength, maskFromStart = true) => {
  const maskStart = maskFromStart ? 0 : Math.max(0, unmaskedLength);
  const maskEnd = maskFromStart
    ? Math.max(0, str.length - unmaskedLength)
    : str.length;
  return str
    .split("")
    .map((char, index) => {
      if (index >= maskStart && index < maskEnd) {
        return maskChar;
      } else {
        return char;
      }
    })
    .join("");
};

export const formatDateToString = (date) => {
  const dateObj = new Date(date);
  let day = dateObj.getDay();
  const month = dateObj.getMonth();
  const year = dateObj.getFullYear();
  day = day - 1;
  day = day > DAYS_OF_WEEK.length ? day % DAYS_OF_WEEK : day;
  return `${DAYS_OF_WEEK[day]}, ${MONTHS_OF_YEAR[month]} ${year}`;
};

export const formatDateYYYYMMDD = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const setToDateMonthYearInWords = (value) => {
  let date = new Date(value);
  let month = date?.getMonth();
  let year = date?.getFullYear();
  let day = date?.getDate();
  day =
    day === 1 || day === 21 || day === 31
      ? `${day}st`
      : day === 2 || day === 22
        ? `${day}nd`
        : day === 3 || day === 23
          ? `${day}rd`
          : `${day}th`;

  return `${day} ${MONTHS_OF_YEAR[month]}, ${year}`;
};
export const truncateString = (str, length) => {
  if (str?.length > length) {
    return str?.substring(0, length) + "...";
  }
  return str;
};

export const subAccountColours = [
  { background: "#E8F7EE", color: "#19AE57" },
  { background: "#FFEEE9", color: "#FD561F" },
  { background: "#F2EDFE", color: "#7647EE" },
  { background: "#FEF7EB", color: "#F7AD3C" },
];

export const isMoreThan30DaysAgo = (date) => {
  //                   days  hours min  sec  ms
  const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
  const timestampThirtyDaysAgo = new Date().getTime() - thirtyDaysInMs;

  if (timestampThirtyDaysAgo > date) {
    return true;
  } else {
    return false;
  }
};

export const serializeToPercent = (value) => {
  const _intValue = parseFloat(value);
  return _intValue * 100 + "%";
};

export const formatDate = (date, is_backwards = true, seperator = "-") => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  if (is_backwards) return [year, month, day].join(seperator);
  return [day, month, year].join(seperator);
};

export const deepCopyFunction = (inObject) => {
  let outObject, value, key;

  if (typeof inObject !== "object" || inObject === null) {
    return inObject; // Return the value if inObject is not an object
  }

  // Create an array or object to hold the values
  outObject = Array.isArray(inObject) ? [] : {};

  for (key in inObject) {
    value = inObject[key];

    // Recursively (deep) copy for nested objects, including arrays
    outObject[key] = deepCopyFunction(value);
  }

  return outObject;
};

export function joinObj(arrayItems, attr) {
  var out = [];

  for (var i = 0; i < arrayItems?.length; i++) {
    out.push(arrayItems[i][attr]);
  }

  return out.join(", ");
}

export const getPlatform = () => {
  return window.location.pathname.includes("/mobile") ? "/mobile" : "/app";
};

export const reArrangeBusinessList = (
  business_list,
  currency_code,
  key = "id",
) => {
  let newList = [];
  business_list.forEach((item) => {
    let newObject = {
      tag: `${item.tag} --- Available Balance: ${formatCurrency({
        value: item.balance,
        currencyCode: currency_code,
      })}`,
      id: item?.[key],
      balance: item.balance,
    };
    newList.push(newObject);
  });
  return newList;
};

export const get422errorMessage = (errors) => {
  for (const key in errors) {
    if (typeof errors[key]?.[0] !== "string") {
      return get422errorMessage(errors?.[key]);
    } else
      return `${key?.replace("_", " ")?.toLocaleUpperCase()}: ${
        errors?.[key]?.[0]
      }`;
  }
};

export const getDateAfter = (period) => {
  const today = new Date();
  const newDate = new Date(
    new Date().setDate(today.getDate() + period),
  )?.toLocaleString("en-us");
  return setToDateMonthYearInWords(newDate);
};

export const calculateCartItem = (
  newCartItems,
  vat = 0,
  service_charge = 0,
) => {
  const totalAmountItem = newCartItems
    ?.reduce((accumulator, item) => {
      return Number(accumulator) + Number(item.total_price);
    }, 0)
    .toFixed(2);
  const vatAmount = ((Number(vat) * totalAmountItem) / 100).toFixed(2);
  const serviceChargeAmount = (
    (Number(service_charge) * totalAmountItem) /
    100
  ).toFixed(2);

  return {
    data: newCartItems,
    total_items: newCartItems?.length,
    total_amount:
      Number(totalAmountItem) + Number(vatAmount) + Number(serviceChargeAmount),
    total_items_amount: Number(totalAmountItem),
    vat_amount: Number(vatAmount),
    vat: Number(vat),
    service_charge: Number(service_charge),
    service_charge_amount: Number(serviceChargeAmount),
  };
};

// this function removes null, undefined and empty string values from an object
export function cleanObject(obj, keys = []) {
  for (let propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === "" ||
      obj[propName] === "None"
    ) {
      delete obj[propName];
    }
  }
  // delete key name specified
  keys?.forEach((key) => {
    delete obj[key];
  });
  return obj;
}

export const getDefaultStoresId = (stores) => {
  let newArray = [];
  stores?.forEach((store) => {
    newArray.push(store?.id);
  });
  return newArray;
};

export const reLabelDefault = (stores) => {
  let newArray = [];
  stores?.forEach((store) => {
    newArray.push({ value: store?.id, label: store?.name });
  });
  return newArray;
};

export const reArrangeVariantStoreProperties = (variantStoreProperties) => {
  const newVariantStoreProperties = [];
  variantStoreProperties?.forEach((variantStoreProperty) => {
    const newStoreProperty = {
      ...variantStoreProperty,
      cost_price: `${variantStoreProperty?.cost_price || ""}`,
      selling_price: `${variantStoreProperty?.selling_price || ""}`,
      minimum_stock_quantity:
        variantStoreProperty?.minimum_stock_quantity === null ||
        variantStoreProperty?.minimum_stock_quantity === undefined
          ? undefined
          : `${variantStoreProperty?.minimum_stock_quantity}`,
      stock_quantity:
        variantStoreProperty?.stock_quantity === null ||
        variantStoreProperty?.stock_quantity === undefined
          ? undefined
          : `${variantStoreProperty?.stock_quantity}`,
      expiry_date: variantStoreProperty?.expiry_date || "",
      discount_rate: `${variantStoreProperty?.discount_rate || ""}`,
      supplier_id: `${variantStoreProperty?.supplier_id || ""}`,
    };
    newVariantStoreProperties.push(newStoreProperty);
  });
  return newVariantStoreProperties;
};

export const reArrangeVariant = (variants) => {
  const newVariants = [];

  variants?.forEach((variant) => {
    const newVariant = {
      ...variant,
      cost_price: `${variant?.cost_price || ""}`,
      selling_price: `${variant?.selling_price || ""}`,
      minimum_stock_quantity:
        variant?.minimum_stock_quantity === null ||
        variant?.minimum_stock_quantity === undefined
          ? undefined
          : `${variant?.minimum_stock_quantity}`,
      stock_quantity:
        variant?.stock_quantity === null ||
        variant?.stock_quantity === undefined
          ? undefined
          : `${variant?.stock_quantity}`,
      store_properties: reArrangeVariantStoreProperties(
        variant?.store_variant_properties,
      ),
    };
    delete newVariant.store_variant_properties;

    newVariants.push(newVariant);
  });
  return newVariants;
};

export const getVariantsProperties = (productDetails) => {
  return productDetails?.variants?.length > 0
    ? reArrangeVariant(productDetails?.variants)
    : [];
};

export const reArrangeStoreProperties = (storeProperties) => {
  const newStoreProperties = [];
  storeProperties?.forEach((storeProperty) => {
    const newStoreProperty = {
      ...storeProperty,
      cost_price: `${storeProperty?.cost_price || ""}`,
      selling_price: `${storeProperty?.selling_price || ""}`,
      minimum_stock_quantity:
        storeProperty?.minimum_stock_quantity === null ||
        storeProperty?.minimum_stock_quantity === undefined
          ? undefined
          : `${storeProperty?.minimum_stock_quantity}`,
      stock_quantity:
        storeProperty?.stock_quantity === null ||
        storeProperty?.stock_quantity === undefined
          ? undefined
          : `${storeProperty?.stock_quantity}`,
      expiry_date: storeProperty?.expiry_date || "",
      discount_rate: `${storeProperty?.discount_rate || ""}`,
      supplier_id: `${storeProperty?.supplier_id || ""}`,
      ledger_account_id: `${storeProperty?.ledger_account_id || ""}`,
    };

    newStoreProperties.push(newStoreProperty);
  });
  return newStoreProperties;
};

export const getDefaultPricingProperties = (pricingTypeId = 1) => {
  return {
    price: "",
    discount_rate: "",
    pricing_type_id: pricingTypeId,
    uuid: uuidv4(),
  };
};

export const getDefaultStoreProperties = (pricingTypeId) => {
  return {
    cost_price: "",
    stock_quantity: 0,
    restock_date: formatDateYYYYMMDD(Date.now()),
    expiry_date: "",
    minimum_stock_quantity: 0,
    store_id: null,
    store_name: null,
    barcode: "",
    item_level_tracking: false,
    items_number: [],
    pricings: [{ ...getDefaultPricingProperties(pricingTypeId) }],
  };
};

export const getDefaultVariantProperties = () => {
  return {
    option: "",
    value: "",
    barcode: "",
    image_url: "",
    uuid: uuidv4(),
    store_properties: [],
  };
};

// this function helps to update a store property on product creation without variants
export const updateStorePropertiesHelper = ({
  stores,
  useSamePricing,
  storeProperties,
  storeList,
}) => {
  const newStoreProperties = deepCopyFunction(storeProperties);
  // delete store properties that have been remove
  newStoreProperties?.forEach((item, index) => {
    if (!stores.find((storeItem) => storeItem?.value === item?.store_id))
      newStoreProperties.splice(index, 1);
    else if (useSamePricing) {
      newStoreProperties[index] = {
        ...newStoreProperties[0],
        store_id: newStoreProperties[index].store_id,
        store_name: newStoreProperties[index].store_name,
      };
    }
  });

  // add store properties for newly selected stores
  stores?.forEach((storeItem) => {
    if (
      !newStoreProperties.find((item) => item?.store_id === storeItem?.value)
    ) {
      const newProperties =
        useSamePricing && storeProperties?.length > 0
          ? { ...storeProperties?.[0] }
          : { ...getDefaultStoreProperties() };
      newProperties.store_id = storeItem?.value;
      newProperties.store_name = storeList?.find(
        (store) => store?.id === storeItem?.value,
      )?.name;
      newStoreProperties.push(newProperties);
    }
  });

  return newStoreProperties;
};

// this function adds new pricing option to store properties on product creation
export const addNewPricingForStorePropertyHelper = ({
  storeProperties,
  storeIndex,
}) => {
  const newStoreProperties = deepCopyFunction(storeProperties);
  newStoreProperties[storeIndex].pricings.push(getDefaultPricingProperties());
  return newStoreProperties;
};

// this function removes a pricing option from store properties on product creation
export const removePricingForStorePropertyHelper = ({
  storeProperties,
  storeIndex,
  pricingIndex,
}) => {
  const newStoreProperties = deepCopyFunction(storeProperties);
  newStoreProperties?.[storeIndex]?.pricings?.splice(pricingIndex, 1);
  return newStoreProperties;
};

// helps to create new variants store properties
export const createVariantPropertiesHelper = ({ storeList, stores }) => {
  const newVariant = getDefaultVariantProperties();
  const newStoreProperties = [];
  stores?.forEach((storeItem) => {
    const newProperties = {
      ...getDefaultStoreProperties(),
      store_id: storeItem?.value,
      useSameVariantPricing: false,
      store_name: storeList?.find((store) => store?.id === storeItem?.value)
        ?.name,
    };
    newStoreProperties.push(newProperties);
  });
  newVariant.store_properties = newStoreProperties;
  return newVariant;
};

// helps to update new variants store properties
export const updateVariantStorePropertiesHelper = ({
  storeList,
  stores,
  variantProperties,
}) => {
  // go through variant properties and update store properties
  const newVariantProperties = deepCopyFunction(variantProperties);
  // console.log(newVariantProperties);
  newVariantProperties?.forEach((variantItem, variantIndex) => {
    variantItem?.store_properties?.forEach(
      (storeProperty, storePropertyIndex) => {
        // delete store property for deleted stores
        if (
          !stores.find(
            (storeItem) => storeItem?.value === storeProperty?.store_id,
          )
        )
          variantItem?.store_properties?.splice(storePropertyIndex, 1);
        else if (variantItem?.useSameVariantPricing) {
          newVariantProperties[variantIndex].store_properties[
            storePropertyIndex
          ] = {
            ...variantItem?.store_properties[0],
            store_id: storeProperty.store_id,
            store_name: storeProperty.store_name,
          };
        }
      },
    );

    // add new store property for newly selected stores
    stores?.forEach((storeItem) => {
      if (
        !variantItem?.store_properties?.find(
          (item) => item?.store_id === storeItem?.value,
        )
      ) {
        const newProperties =
          variantItem?.useSameVariantPricing &&
          variantItem?.store_properties?.length > 0
            ? { ...variantItem?.store_properties?.[0] }
            : { ...getDefaultStoreProperties() };
        newProperties.store_id = storeItem?.value;
        newProperties.store_name = storeList?.find(
          (store) => store?.id === storeItem?.value,
        )?.name;
        variantItem?.store_properties?.push(newProperties);
      }
    });
  });
  return newVariantProperties;
};

// helps to add new variants store properties
export const addNewVariantOptionHelper = ({ stores, storeList }) => {
  const newVariant = { ...getDefaultVariantProperties() };
  stores?.forEach((storeItem) => {
    const newProperties = {
      ...getDefaultStoreProperties(),
      useSameVariantPricing: false,
      store_id: storeItem?.value,
      store_name: storeList?.find((store) => store?.id === storeItem?.value)
        ?.name,
    };
    newVariant?.store_properties?.push(newProperties);
  });

  return newVariant;
};

// helps to remove variants store properties
export const removeVariantPropertyHelper = ({
  variantIndex,
  variantProperties,
}) => {
  const newVariantProperties = [...variantProperties];
  newVariantProperties.splice(variantIndex, 1);
  return newVariantProperties;
};

export const addNewPricingPropertyForVariantStorePropertyHelper = ({
  storeIndex,
  variantIndex,
  variantProperties,
}) => {
  const newVariantProperties = deepCopyFunction(variantProperties);
  newVariantProperties[variantIndex].store_properties[storeIndex].pricings.push(
    getDefaultPricingProperties(),
  );
  return newVariantProperties;
};

export const removePricingPropertyForVariantStorePropertyHelper = ({
  pricingIndex,
  storeIndex,
  variantIndex,
  variantProperties,
}) => {
  const newVariantProperties = deepCopyFunction(variantProperties);
  newVariantProperties[variantIndex].store_properties[
    storeIndex
  ].pricings.splice(pricingIndex, 1);
  return newVariantProperties;
};

export const findPricingViaIdHelper = (pricingTypeId, pricings = []) => {
  const pricingDetails = pricings?.find(
    (pricingItem) => `${pricingItem?.pricing_type?.id}` === `${pricingTypeId}`,
  );
  return pricingDetails;
};

export const getPriceWithoutDiscout = (product) => {
  return product.is_service
    ? product.store_service_properties[0]?.pricings?.find(
        (item) => item.pricing_type.id === product?.pricing_type?.id,
      )?.price
    : product.store_product_properties[0]?.pricings?.find(
        (item) => item.pricing_type.id === product?.pricing_type?.id,
      )?.price || 0;
};

export const getVariantPriceWithoutDiscout = (product) => {
  return (
    product.store_variant_properties[0]?.pricings?.find(
      (item) => item.pricing_type.id === product?.pricing_type?.id,
    )?.price || 0
  );
};

export const getPriceWithDiscout = (product) => {
  const price =
    getPriceWithoutDiscout(product) -
    ((product.is_service
      ? product.store_service_properties[0]?.pricings?.find(
          (item) => item.pricing_type.id === product?.pricing_type?.id,
        )?.discount_rate
      : product.store_product_properties[0]?.pricings?.find(
          (item) => item.pricing_type.id === product?.pricing_type?.id,
        )?.discount_rate) /
      100) *
      getPriceWithoutDiscout(product);

  return price || 0;
};

export const getVariantPriceWithDiscout = (product) => {
  const price =
    getVariantPriceWithoutDiscout(product) -
    (product.store_variant_properties[0]?.pricings?.find(
      (item) => item.pricing_type.id === 1,
    )?.discount_rate /
      100) *
      getVariantPriceWithoutDiscout(product);

  return price || 0;
};

export const handleStorefrontStatusIndicator = (value) => {
  if (value === "COMPLETED") {
    return <h4 style={{ color: "#19AE57" }}>Paid</h4>;
  } else if (value === "IN_PROGRESS") {
    return <h4 style={{ color: "#F7AD3C" }}>In Progress</h4>;
  } else if (value === "PLACED") {
    return <h4 style={{ color: "#F7AD3C" }}>Awaiting Payment</h4>;
  } else if (value === "PENDING") {
    return <h4 style={{ color: "#F7AD3C" }}>Pending</h4>;
  } else {
    return <h4 style={{ color: "#E02020" }}>Cancelled</h4>;
  }
};

export const handleRenderStatusIndicator = (value) => {
  if (value === "COMPLETED") {
    return { color: "#19AE57", backgroundColor: "#E5FFF0" };
  } else if (value === "IN_PROGRESS") {
    return { color: "#F7AD3C", backgroundColor: "#FEF7EB" };
  } else if (value === "PLACED") {
    return { color: "#F7AD3C", backgroundColor: "#FEF7EB" };
  } else if (value === "PENDING") {
    return { color: "#F7AD3C", backgroundColor: "#FEF7EB" };
  } else {
    return { color: "#E02020", backgroundColor: "#FFEEE9" };
  }
};

export const getDateInUTCFormat = ({ date = "", time = "" }) => {
  var d = new Date(date),
    month = "" + d.getMonth(),
    day = "" + d.getDate(),
    year = d.getFullYear();

  const timeSplit = time?.split(":");

  // Create a Date object
  const newDate = new Date(year, month, day, timeSplit?.[0], timeSplit?.[1]);

  // Convert to ISO 8601 format and manually append microseconds
  let isoString = newDate.toISOString();

  return isoString;
};

export const removeSpecialCharacter = (inputValue) => {
  const stringWithoutUnderscores = inputValue.replace(/_/g, " ");

  const properCaseString = stringWithoutUnderscores.replace(/\b\w/g, (char) =>
    char.toUpperCase(),
  );

  return properCaseString;
};

export const formatTimeFromTimestamp = (timestamp) => {
  const date = new Date(timestamp);

  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  const seconds = date.getUTCSeconds().toString().padStart(2, "0");

  const period = hours >= 12 ? "PM" : "AM";

  hours = hours % 12 || 12;

  const formattedHours = hours.toString().padStart(2, "0");

  return `${formattedHours}:${minutes}:${seconds} ${period}`;
};

export const capitalizeLetter = (str) => {
  if (!str) return str; // Check if the string is empty
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const defaultDateDisplay = (value) => {
  return value ? new Date(value).toLocaleString("en-us") : "-";
};

export const formatNumberWithCommas = (number = 0) => {
  if (!number) return 0;
  number = `${number}`;
  const [integerPart, decimalPart] = number?.split(".");

  // Add commas to the integer part
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Return the formatted number with decimals (if any)
  return decimalPart || number?.includes(".")
    ? `${formattedInteger}.${decimalPart || ""}`
    : formattedInteger;
};

export const removeCommas = (numberWithCommas = 0) => {
  if (!numberWithCommas && numberWithCommas !== 0) return numberWithCommas;
  return `${numberWithCommas}`?.replace(/,/g, "");
};

export const encodeToBase64 = (input) => {
  return btoa(unescape(encodeURIComponent(input)));
};
