import { useDispatch } from "react-redux";
import { setOtpAuthorizationError } from "../store/modules/apiErrorHandler";

export const useCloseOtpAuthorizationPrompt = () => {
  const dispatch = useDispatch();

  const closeOtpAuthorizationPrompt = () => {
    dispatch(
      setOtpAuthorizationError({
        message: null,
        object_id: null,
        object_type: null,
        action: null,
        otp: null,
      }),
    );
  };

  return { closeOtpAuthorizationPrompt };
};
