import { useMutation, useQueryClient } from "react-query";
import { updateStorefrontTemplateInUse } from "../../../../../../../../../services/inventory";
import { useContext, useState } from "react";
import { ToastContext } from "../../../../../../../../../hooks/context";
import { useParams } from "react-router-dom";
import { useGetExternalAuthToken } from "../../../../../../../../../hooks/api/mutations/useSettings";

export const useTemplate = () => {
  const { menuId } = useParams();
  const queryClient = useQueryClient();
  const triggerToast = useContext(ToastContext);

  const [showSideModal, setShowSideModal] = useState(false);

  const getExternalAuthToken = useGetExternalAuthToken();

  const { isLoading: isLoadingApplyTemplate, mutate: mutateApplyTemplate } =
    useMutation({
      mutationFn: (data) => updateStorefrontTemplateInUse(data),
      onSuccess: () => {
        triggerToast("Storefront updated successfully", "success");
        queryClient.invalidateQueries(["getMenusDetails"]);
        // queryClient.invalidateQueries(["getMenus"]);
      },
    });

  const navigateToStorefrontSettings = (menuId) => {
    getExternalAuthToken.mutate(
      {},
      {
        onSuccess: (res) => {
          const refreshToken = res?.data?.data?.refresh;
          const accessToken = res?.data?.data?.access;

          window.open(
            `${process.env.REACT_APP_LUMI_STOREFRONT_APP_BASE_URL}/auth?rt=${refreshToken}&at=${accessToken}&storefront=${menuId}`,
            "_blank",
          );
        },
      },
    );
  };

  return {
    menuId,
    showSideModal,
    isLoadingApplyTemplate,
    navigateToStorefrontSettings,
    setShowSideModal,
    mutateApplyTemplate,
  };
};
